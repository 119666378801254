import { Button, IconMessage16, UIHeading2, UIHeading4, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface ICalltrackingWarningProps {
  isChatEnabled: boolean;
  phones: string[];
  openChat?(): void;
}

export const CalltrackingWarning: React.FC<React.PropsWithChildren<ICalltrackingWarningProps>> = ({
  isChatEnabled,
  phones,
  openChat,
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['message-block']}>
        <div className={styles['header']}>
          <UIHeading4>Это временные телефоны</UIHeading4>
        </div>
        <div className={styles['message']}>
          <UIText1 color="gray60_100">
            {isChatEnabled
              ? 'Настоящие номера защищены от спама, поэтому смс не дойдут; написать автору объявления получится только в чате'
              : 'Настоящий защищён от спама. Если автор объявления заметит пропущенный, он перезвонит с другого номера.'}
          </UIText1>
        </div>
        {isChatEnabled && (
          <Button beforeIcon={<IconMessage16 color="white_100" />} size="XS" onClick={openChat}>
            Написать в чат
          </Button>
        )}
      </div>
      <div className={styles['phones-block']}>
        {phones.map((phone, index) => {
          return (
            <a key={index} href={`tel:${phone}`} className={styles['phone']}>
              <UIHeading2>{phone}</UIHeading2>
            </a>
          );
        })}
      </div>
    </div>
  );
};
