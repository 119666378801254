import { IconCar16, IconWalk16 } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { UndergroundItem } from '../../components/GeoRedesigned/UndergroundItem';
import { UndergroundList } from '../../components/GeoRedesigned/UndergroundList';
import { UndergroundIcon } from '../../components/UndergroundIcon';
import { regionIdSelector } from '../../selectors/offerData/geo/regionIdSelector';
import { undergroundsSelector } from '../../selectors/offerData/geo/undergroundsSelector';
import { isDefined } from '../../utils';
import { getConstructionInfo } from '../../utils/formatters';

export const UndergroundsContainer = () => {
  const regionId = useSelector(regionIdSelector);
  const undergrounds = useSelector(undergroundsSelector);

  if (!undergrounds) {
    return null;
  }

  return (
    <UndergroundList>
      {undergrounds.map(underground => {
        const { underConstruction, lineColor, name, url, lineType } = underground;
        const constructionInfo = getConstructionInfo(underground);
        const travelTime = isDefined(underground.travelTime) ? `${underground.travelTime || '<1'} мин.` : null;
        const travelType = underground.travelType === 'walk' ? <IconWalk16 /> : <IconCar16 />;

        return (
          <UndergroundItem
            key={`underground_${name}`}
            icon={<UndergroundIcon color={`#${lineColor}`} regionId={regionId} lineType={lineType} />}
            underConstruction={!!underConstruction}
            undergroundName={name}
            undergroundUrl={url}
            constructionInfo={constructionInfo}
            travelType={travelType}
            travelTime={travelTime}
          />
        );
      })}
    </UndergroundList>
  );
};
