import { ca } from '@cian/analytics';

export function trackMapInViewportClick() {
  ca('event', {
    action: 'show_map',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackGeoAddressNodeClick() {
  ca('event', {
    action: 'click_address_map_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackGeoUndergroundClick() {
  ca('event', {
    action: 'click_metro_map_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackTabFloorPlanClick() {
  ca('event', {
    action: 'click_tab_on_floor',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}
