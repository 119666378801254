import { Button, IconShareAndroid16, InformationTooltip } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
import { IPageData } from '../../store';
import { IOffer } from '../../types/offerData';
import { getGeoLabel } from '../../utils/meta/partials/getGeoLabel';
import { IconOk, IconVk } from '../sharing/icons';
import {
  trackClickOnSharingButton as trackSharingButtonClick,
  trackClickOnSharingMainButton as trackSharingMainButtonClick,
} from '../sharing/tracking';

export interface ISharingNewProps {
  offer: IOffer;
  pageData: IPageData;
  title: string;
}

export function SharingNew({ offer, pageData, title }: ISharingNewProps) {
  const handleClickOnSharingMainButton = React.useCallback(() => {
    trackSharingMainButtonClick();
  }, []);

  const offerUrl = React.useMemo(
    () => `https://${pageData.subdomain}.cian.ru/${pageData.dealType}/${pageData.offerType}/${pageData.offerId}`,
    [pageData],
  );

  const handleCopyLink = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    trackSharingButtonClick('copy_link');

    await navigator.clipboard.writeText(offerUrl);
  };

  const handleSocialLink = (event: React.MouseEvent<HTMLDivElement>, socialType: string) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    let url: string | undefined;

    const utmMarked = `?utm_medium=social_repost&utm_campaign=card_social_repost&utm_source=${socialType}`;
    const description = getGeoLabel(offer);
    const image = offer && offer.photos && offer.photos.length > 0 ? offer.photos[0].fullUrl : '';

    trackSharingButtonClick(socialType);

    switch (socialType) {
      case 'vk':
        url = `https://vk.com/share.php?${new URLSearchParams({
          url: offerUrl + utmMarked,
          title,
          description,
          image,
          noparse: 'true',
        })}`;
        break;

      case 'ok':
        url = `https://connect.ok.ru/offer?${new URLSearchParams({
          url: offerUrl + utmMarked,
          title,
        })}`;
        break;

      default:
        return;
    }

    const popupWidth = 800;
    const popupHeight = 600;
    const popupTopPosition = (window.outerHeight - popupHeight) / 2;
    const popupLeftPosition = (window.outerWidth - popupWidth) / 2;

    const popupParams = `width=${popupWidth}, height=${popupHeight}, scrollbars=0, top=${popupTopPosition}, left=${popupLeftPosition}`;

    window.open(url, '', popupParams);
  };

  return (
    <div className={styles['container']}>
      <InformationTooltip
        arrow
        placement="right-start"
        content={
          <div className={styles['popup']}>
            <div className={styles['social_row']}>
              <div className={styles['vk']} onClick={e => handleSocialLink(e, 'vk')}>
                <IconVk />
              </div>
              <div className={styles['ok']} onClick={e => handleSocialLink(e, 'ok')}>
                <IconOk />
              </div>
            </div>
            <div className={styles['text']}>{offerUrl}</div>
            <div className={styles['copy_link']} onClick={handleCopyLink}>
              Скопировать ссылку
            </div>
          </div>
        }
      >
        <Button
          theme="stroke_secondary"
          beforeIcon={<IconShareAndroid16 color="gray_icons_100" />}
          onClick={handleClickOnSharingMainButton}
          size="XS"
          title="Поделиться"
        />
      </InformationTooltip>
    </div>
  );
}
