import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingPriceCanChangePlate } from 'shared/components/NewbuildingPriceCanChangePlate';
import { selectAsideCompactMode } from 'shared/selectors/aside';
import { isNewbuildingFromDeveloperSelector } from 'shared/selectors/offerData/offer';

import { trackTooltipOpen } from './tracking';

export const NewbuildingPriceCanChangePlateContainer: React.FC = () => {
  const isCompactModeEnabled = useSelector(selectAsideCompactMode);
  const isNewbuildingFromDeveloper = useSelector(isNewbuildingFromDeveloperSelector);

  if (!isNewbuildingFromDeveloper || isCompactModeEnabled) {
    return null;
  }

  return <NewbuildingPriceCanChangePlate onTooltipOpen={trackTooltipOpen} />;
};
