import * as React from 'react';

import * as styles from './AgentSchedule.css';

interface IProps {
  title: React.ReactNode | null;
  description: React.ReactNode;
}

export function AgentSchedule({ title, description }: IProps) {
  return (
    <div>
      {title && <div className={styles['title']}>{title}</div>}
      <div>{description}</div>
    </div>
  );
}
