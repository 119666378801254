import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface IFlatViewOrderInfoProps {
  description: string;
}

export function FlatViewOrderInfo({ description }: IFlatViewOrderInfoProps) {
  return (
    <div className={styles['notification']}>
      <div className={styles['calendar']} />
      <UIText2>{description}</UIText2>
    </div>
  );
}
