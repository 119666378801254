import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CommentsButtonNew } from '../../components/CommentsButtonNew';
import { trackOpenCommentsBlock as trackCommentsBlockOpen } from '../../components/comments/button/tracking';
import { getCommentBlock } from '../../selectors';
import { changeEdditing, closeComments, openComments } from '../../store/comments';

export function CommentsButtonContainer() {
  const dispatch = useDispatch();
  const { isOpen, commentText } = useSelector(getCommentBlock);

  const clickHandle = React.useCallback(() => {
    if (isOpen) {
      if (commentText) {
        return;
      } else {
        dispatch(closeComments());
      }
    } else {
      dispatch(changeEdditing(true));
      dispatch(openComments());
      trackCommentsBlockOpen();
    }
  }, [isOpen, commentText]);

  return <CommentsButtonNew commented={isOpen} onClick={clickHandle} />;
}
