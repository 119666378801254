import { Button, CustomTooltip, IconShareAndroid16 } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { SharingPopup } from 'shared/components/NavigationNew';
import { trackShareClick } from 'shared/components/navigation/tracking';
import { getPageData, offerSelector } from 'shared/selectors';
import { shareTitleSelector } from 'shared/selectors/shareTitleSelector';
import { getGeoLabel } from 'shared/utils/meta/partials/getGeoLabel';

export const SharingButtonContainer: React.FC = () => {
  const [isOpenPopup, setOpenPopup] = React.useState(false);

  const offer = useSelector(offerSelector);
  const pageData = useSelector(getPageData);
  const title = useSelector(shareTitleSelector);

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleSharingButton = () => {
    setOpenPopup(true);

    trackShareClick();
  };

  const handleSocialLink = (socialType: string) => {
    let url: string | undefined;

    const link = `https://${pageData.subdomain}.cian.ru/${pageData.dealType}/${pageData.offerType}/${pageData.offerId}`;
    const utmMarked = `?utm_medium=social_repost&utm_campaign=card_social_repost&utm_source=${socialType}`;

    const description = getGeoLabel(offer);
    const image = offer && offer.photos && offer.photos.length > 0 ? offer.photos[0].fullUrl : '';

    switch (socialType) {
      case 'vk':
        url = [
          `https://vk.com/share.php?url=${encodeURIComponent(link + utmMarked)}`,
          `title=${encodeURIComponent(title)}`,
          `description=${encodeURIComponent(description)}`,
          `image=${encodeURIComponent(image)}`,
          `noparse=true`,
        ].join('&');
        break;

      case 'ok':
        url = [
          `https://connect.ok.ru/offer?url=${encodeURIComponent(link + utmMarked)}`,
          `title=${encodeURIComponent(title)}`,
        ].join('&');
        break;

      default:
        return;
    }

    window.open(url, '');
  };

  return (
    <>
      <Button
        theme="fill_secondary"
        size="XS"
        ref={buttonRef}
        beforeIcon={<IconShareAndroid16 color="primary_100" />}
        onClick={handleSharingButton}
      />

      <CustomTooltip
        content={<SharingPopup pageData={pageData} onClick={handleSocialLink} />}
        anchorRef={buttonRef}
        size="S"
        theme="white"
        maxWidth={400}
        onClose={() => setOpenPopup(false)}
        open={isOpenPopup}
        placement="bottom-start"
        arrow
        closeButton
        flip
        outside
        portal
        preventOverflow
      />
    </>
  );
};
