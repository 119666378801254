import * as React from 'react';
import { useSelector } from 'react-redux';

import { useMapsFitToViewport } from './hooks/useMapsFitToViewport';
import { MapWrapper } from '../../components/MapWrapper';
import { VisibilityController } from '../../components/VisibilityController';
import { OfferMapTabsContainer } from '../../components/map_tabs/container';
import { MAP_CONTAINER_ID } from '../../constants/map';
import { selectGeoCoordinates } from '../../selectors';
import { MapLoadable } from '../MapContainer';
import { PanoramaBannerContainer } from '../PanoramaBannerContainer';
import { PanoramaContainer } from '../PanoramaContainer';

export const MapsSectionContainer = () => {
  const pinCoordinates = useSelector(selectGeoCoordinates);

  const mapRef = React.useRef<YMaps.Map>();
  /* istanbul ignore next */
  const setMapRef = React.useCallback((map: YMaps.Map) => (mapRef.current = map), []);

  useMapsFitToViewport({ mapRef });

  /* istanbul ignore next */
  if (!pinCoordinates) {
    return null;
  }

  return (
    <div id={MAP_CONTAINER_ID}>
      <OfferMapTabsContainer />
      <MapWrapper>
        <VisibilityController isVisible={true}>
          <MapLoadable mapRef={setMapRef} />
        </VisibilityController>
      </MapWrapper>
      <PanoramaBannerContainer>
        <PanoramaContainer />
      </PanoramaBannerContainer>
    </div>
  );
};
