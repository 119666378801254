import { ca } from '@cian/analytics';

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getCustomUrl } from '../../utils/tracking/getCustomUrl';

export function trackGoToPhotosClick() {
  ca('event', {
    action: 'to_photo_navi_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackGoToServicePlaceOverMapClick() {
  ca('event', {
    action: 'to_service_place_over_map_navi_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}
export function trackGoToServicePlaceOverEvaluationClick() {
  ca('event', {
    action: 'to_service_place_over_evaluation_navi_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackGoToServicePlaceUnderMapClick() {
  ca('event', {
    action: 'to_service_place_under_map_navi_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackGoToDescriptionClick() {
  ca('event', {
    action: 'to_description_navi_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackGoToMapClick() {
  ca('event', {
    action: 'to_map_navi_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackGoToSimilarOffersClick() {
  ca('eventSite', {
    action: 'to_similar_offers_navi_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackGoToValuationClick() {
  ca('event', {
    action: 'to_valuation_navi_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackGoToAuthorClick() {
  ca('event', {
    action: 'to_contacts__navi_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackFavoriteClick(offer: IOfferSchema, agent: IAgentSchema | null, checked: boolean) {
  ca('eventSite', {
    action: checked ? 'add_from_card_navi_block' : 'remove_from_card_navi_block',
    category: 'favorite',
    label: getCustomUrl(offer, agent),
    name: 'oldevent',
    useLastProducts: true,
  });
}

export function trackShareClick() {
  ca('event', {
    action: 'share_open_navi_block',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}
