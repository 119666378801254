import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingPriceInfo } from 'shared/components/NewbuildingPriceInfo';
import { PriceDiscount } from 'shared/components/PriceDiscount';
import { PriceElectronicTrading } from 'shared/components/PriceElectronicTrading/PriceElectronicTrading';
import { selectAsideCompactMode } from 'shared/selectors/aside';
import { getOfferDiscount, isNewbuildingFromDeveloperSelector } from 'shared/selectors/offerData';
import { isCoworkingSelector } from 'shared/selectors/offerData/offer/isCoworkingSelector';
import { isPriceElectronicTradingSelector } from 'shared/selectors/price';

import { PriceInfo } from '../../components/PriceInfo/PriceInfo';
import { PriceDropdownContainer } from '../../components/price_dropdown/container';
import { selectPriceAside } from '../../selectors/price/selectPriceAside';
import { withNoBreakSpaces } from '../../utils/formatters';
import { CoworkingAdditionalInfo } from '../CoworkingAdditionalInfo/CoworkingAdditionalInfo';
import { PriceHistoryContainer } from '../PriceHistory/PriceHistory';
import { PriceLabelsContainer } from '../PriceLabels';
import { PriceRelevance } from '../PriceRelevance';

export const PriceContainer = () => {
  const price = useSelector(selectPriceAside);
  const isCoworking = useSelector(isCoworkingSelector);
  const isPriceAuction = useSelector(isPriceElectronicTradingSelector);
  const discount = useSelector(getOfferDiscount);
  const isCompactModeEnabled = useSelector(selectAsideCompactMode);
  const isNewbuilding = useSelector(isNewbuildingFromDeveloperSelector);

  if (isPriceAuction) {
    return <PriceElectronicTrading />;
  }

  if (!price) {
    return null;
  }

  const mainPrice = discount ? discount.newPrice : price;

  if (isNewbuilding) {
    return (
      <NewbuildingPriceInfo
        price={withNoBreakSpaces(mainPrice)}
        priceHistory={<PriceHistoryContainer />}
        priceRelevance={<PriceRelevance />}
        labels={<PriceLabelsContainer />}
        discount={discount ? <PriceDiscount oldPrice={price} percent={discount.percent} /> : null}
        isCompactModeEnabled={isCompactModeEnabled}
      />
    );
  }

  return (
    <PriceInfo
      price={withNoBreakSpaces(mainPrice)}
      priceHistory={<PriceHistoryContainer />}
      priceInOtherCurrencies={!isCoworking ? <PriceDropdownContainer /> : null}
      coworkingAdditionalInfo={isCoworking ? <CoworkingAdditionalInfo /> : null}
    />
  );
};
