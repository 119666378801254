import { Label, TooltipDesktop, UIText1, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './FloatingLabel.css';

export interface IFloatingLabelProps {
  labelText: string;
  tooltipTitle: string;
  tooltipText: string;
  color: string;
  backgroundColor: string;
  icon?: React.ReactElement;
  extendTooltipContent?: React.ReactElement;
  link?: string;
  onOpen?: () => void;
}

export function FloatingLabel({
  labelText,
  tooltipText,
  tooltipTitle,
  color,
  backgroundColor,
  icon,
  extendTooltipContent,
  link,
  onOpen,
}: IFloatingLabelProps) {
  const content = (
    <div className={styles['container']}>
      <UIText1 fontWeight="bold">{tooltipTitle}</UIText1>
      <div className={styles['text']}>
        <UIText2>{tooltipText}</UIText2>
      </div>
      {link && (
        <UIText2>
          <a className={styles['link']} href={link} rel="noreferrer noopener" target="_blank">
            Как это работает
          </a>
        </UIText2>
      )}
      {extendTooltipContent && <div>{extendTooltipContent}</div>}
    </div>
  );

  return (
    <TooltipDesktop title={content} theme="white" placement="bottom-start" onOpen={onOpen}>
      <span className={styles['label']}>
        <Label color={color} background={backgroundColor} icon={icon}>
          {labelText}
        </Label>
      </span>
    </TooltipDesktop>
  );
}
