import * as React from 'react';

import * as styles from './index.css';

export interface IRulerControlsProps {
  onRulerToggle(): void;
  isSelected: boolean;
}

export interface IRulerControlsState {
  isActive: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class RulerControls extends React.Component<IRulerControlsProps, IRulerControlsState> {
  public render() {
    return (
      <div
        className={styles[this.props.isSelected ? 'container-active' : 'container']}
        onClick={() => this.props.onRulerToggle()}
      >
        <div className={styles['icon']} />
      </div>
    );
  }
}
