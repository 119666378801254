import { IconClock16, IconPhone16, IconSupport16, IconWarningSign16 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './phonesOpenedInfo.css';

interface IPhonesOpenedInfoProps {
  phones?: React.ReactElement;
  schedule: string | null;
  availability: string | null;
  calltracking: string | null;
  warningMessage: string | null;
  feedback: React.ReactElement;
  id?: string;
}
export const PhonesOpenedInfo = ({
  phones,
  schedule,
  availability,
  calltracking,
  feedback,
  warningMessage,
  id = 'asidePhonesOpenedInfo',
}: IPhonesOpenedInfoProps) => {
  return (
    <div className={styles['container']} id={id}>
      {phones && (
        <div data-testid="phones-opened-value" className={styles['phones']}>
          {phones}
        </div>
      )}
      <div>
        {availability && (
          <div className={styles['info']}>
            <div className={styles['icon']}>
              <IconClock16 />
            </div>
            <span>{availability}</span>
          </div>
        )}
        {schedule && (
          <div data-testid="phones-opened-scheduled" className={styles['info']}>
            <div className={styles['icon']}>
              <IconClock16 />
            </div>
            <span>{schedule}</span>
          </div>
        )}
        {warningMessage && (
          <div className={styles['info']}>
            <div className={styles['icon']}>
              <IconWarningSign16 />
            </div>
            <span>{warningMessage}</span>
          </div>
        )}
        {calltracking && (
          <div data-testid="phones-opened-calltracking" className={styles['info']}>
            <div className={styles['icon']}>
              <IconPhone16 />
            </div>
            <span>{calltracking}</span>
          </div>
        )}
        {feedback && (
          <div data-testid="phones-opened-feedback" className={styles['info']}>
            <div className={styles['icon']}>
              <IconSupport16 />
            </div>
            <span>{feedback}</span>
          </div>
        )}
      </div>
    </div>
  );
};
