import * as React from 'react';
import { useSelector } from 'react-redux';

import { PriceRelevance as PriceRelevanceComponent } from 'shared/components/PriceRelevance';
import { selectIsNewbuildingPushToCallEnabled } from 'shared/selectors/abExperiments/selectIsNewbuildingPushToCallEnabled';
import { selectPriceRelevance } from 'shared/selectors/selectPriceRelevance';

export const PriceRelevance = () => {
  const relevance = useSelector(selectPriceRelevance);
  const isPushToCallEnabled = useSelector(selectIsNewbuildingPushToCallEnabled);

  if (!isPushToCallEnabled || !relevance || relevance.type !== 'actual') {
    return null;
  }

  return <PriceRelevanceComponent label={relevance.label} />;
};
