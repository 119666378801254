import { ContactLabels } from '@cian/contact-card-component';
import { IconDocumentsVerified16 } from '@cian/ui-kit';
import * as React from 'react';

const AgencyLabelsComponent: React.FC = () => {
  return (
    <ContactLabels
      labels={[
        {
          icon: <IconDocumentsVerified16 color="primary_100" />,
          title: 'Документы проверены',
        },
      ]}
    />
  );
};

export const AgencyLabels = React.memo(AgencyLabelsComponent);
