import { EPageTypes, EPlatformTypes } from '@cian/frontend-dynamic-calltracking-component';
import { DynamicPhone, DynamicPhoneLink } from '@cian/frontend-newbuilding-call-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { DynamicPhoneLinkWrapper } from 'shared/components/DynamicPhoneLinkWrapper';
import { PhonesPreloader } from 'shared/components/PhonesPreloader/PhonesPreloader';
import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { getPhones } from 'shared/selectors';
import { selectIsNewbuildingPushToCallEnabled } from 'shared/selectors/abExperiments/selectIsNewbuildingPushToCallEnabled';
import { getDynamicCalltrackingSiteBlockId } from 'shared/selectors/getDynamicCalltrackingSiteBlockId';
import { getOfferId } from 'shared/selectors/newObject';
import { formatNumber, getPhoneLink } from 'shared/utils/phone';

export const NewbuildingDynamicPhone = ({ placeType }: { placeType: string }) => {
  const { httpApi, logger } = useApplicationContext();
  const phones = useSelector(getPhones);
  const siteBlockId = useSelector(getDynamicCalltrackingSiteBlockId);
  const offerId = useSelector(getOfferId);
  const isPushToCallEnabled = useSelector(selectIsNewbuildingPushToCallEnabled);

  const formattedPhones = React.useMemo(
    () =>
      phones.map(phone => ({
        number: formatNumber(phone),
        link: getPhoneLink(phone),
      })),
    [phones],
  );

  const notFormattedPhone = React.useMemo(() => `${phones[0].countryCode}${phones[0].number}`, [phones]);

  if (isPushToCallEnabled) {
    return (
      <DynamicPhoneLinkWrapper>
        <DynamicPhoneLink
          loader={<PhonesPreloader />}
          formattedPhone={formattedPhones[0].number}
          notFormattedPhone={notFormattedPhone}
          siteBlockId={siteBlockId}
          httpApi={httpApi}
          logger={logger}
          placeType={placeType}
          pageType={EPageTypes.OfferCard}
          platformType={EPlatformTypes.WebDesktop}
          announcementId={offerId}
        />
      </DynamicPhoneLinkWrapper>
    );
  }

  return (
    <DynamicPhone
      loader={<PhonesPreloader />}
      formattedPhone={formattedPhones[0].number}
      siteBlockId={siteBlockId}
      httpApi={httpApi}
      logger={logger}
      placeType={placeType}
      pageType={EPageTypes.OfferCard}
      platformType={EPlatformTypes.WebDesktop}
      announcementId={offerId}
    />
  );
};
