import { makeContextModule } from 'shared/utils/makeContextModule';

export type TPhonesOpenedContext = {
  isPhonesOpened: boolean;
  setPhonesOpened: (isPhonesOpened: boolean) => void;
};

export const { Context: PhonesOpenedContext, useContext: usePhonesOpenedContext } =
  makeContextModule<TPhonesOpenedContext>({
    contextName: 'PhonesOpenedContext',
  });
