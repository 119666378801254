import { connect } from 'react-redux';

import { ILegendProps, Legend } from './index';
import { toggle } from '../../../store/popups/legend';
import { getLegendData, ILegendItem } from '../../../utils/legend';
import { getOfferType } from '../../../utils/offer_type';

import type { DispatchReduxAction, IState } from '../../../store';

type TStoreProps = Pick<ILegendProps, 'legendData'>;
type TDispatchProps = Pick<ILegendProps, 'openModal'>;

function mapStateToProps(state: IState): TStoreProps {
  let legendData: ILegendItem[] = [];
  const offerType = getOfferType(state.offerData.offer);

  if (state.mapSimilar?.data?.points) {
    legendData = getLegendData(state.mapSimilar.data.points, offerType);
  }

  return { legendData };
}

function mapDispatchToProps(dispatch: DispatchReduxAction): TDispatchProps {
  return {
    openModal: () => dispatch(toggle(true)),
  };
}

export const LegendContainer = connect<TStoreProps, TDispatchProps, {}>(mapStateToProps, mapDispatchToProps)(Legend);
