import * as React from 'react';

export const KindergardenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" width="14" height="16">
    <path
      fill="currentColor"
      d="M12.54 7.097c.803 0 1.46.592 1.46 1.315s-.657 1.315-1.478 1.315h-2.409c.019.098.019.18.019.28v1.347c0 .148-.019.312-.037.477l2.573 1.512c.602.608.602 1.512 0 2.12-.658.657-1.79.723-2.52.131l-1.222-1.1a4.471 4.471 0 0 1-4.034 0l-1.223 1.1c-.657.526-1.661.526-2.337 0C.602 15.02.53 14 1.168 13.343l2.591-1.545a3.917 3.917 0 0 1-.037-.477V9.973c0-.098.019-.197.019-.28H1.46C.656 9.694 0 9.104 0 8.38c0-.723.657-1.315 1.46-1.315h2.154c.128 0 .237.017.365.05-.913-.757-1.442-1.809-1.442-2.926 0-.197.018-.411.055-.608a2.087 2.087 0 0 1-.894-.576c-.712-.788-.566-1.955.31-2.596.876-.641 2.172-.51 2.884.279a4.769 4.769 0 0 1 4.07 0c.237-.263.548-.46.895-.575 1.077-.345 2.245.148 2.646 1.117.384.97-.164 2.038-1.223 2.384.238 1.347-.31 2.712-1.46 3.599a1.42 1.42 0 0 1 .584-.115h2.136zM9.71 1.081l.147.165c.328.246.602.542.821.87l.456.478a.936.936 0 0 0 .128-1.398c-.383-.41-1.077-.46-1.551-.115zM5.97 5.815c-.11-.099-.274-.099-.365 0-.092.099-.092.247.018.329.73.657 1.916.657 2.647 0 .11-.099.11-.23 0-.329a.262.262 0 0 0-.365 0c-.201.164-.439.28-.712.329V5.19c.474-.312.456-.69.456-.69 0-.197-.164-.345-.383-.361h-.657a.37.37 0 0 0-.384.361c.019.28.183.542.457.69v.954a1.542 1.542 0 0 1-.712-.329zM2.738 2.61l.438-.477c.237-.328.511-.624.821-.87l.165-.165c-.457-.362-1.15-.296-1.552.115a.911.911 0 0 0 .128 1.397zM4.813 4c.241 0 .437-.18.437-.4 0-.22-.196-.4-.438-.4-.241 0-.437.18-.437.4 0 .22.196.4.438.4zm4.375 0c.241 0 .437-.18.437-.4 0-.22-.196-.4-.438-.4-.241 0-.437.18-.437.4 0 .22.196.4.438.4z"
    />
  </svg>
);
