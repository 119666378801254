import cx from 'clsx';
import * as React from 'react';

import * as styles from './index.css';
import { isArrayWithItems } from '../../../utils';
import { ILegendItem } from '../../../utils/legend';

export interface ILegendProps {
  legendData: ILegendItem[];

  openModal(): void;
}

export class Legend extends React.PureComponent<ILegendProps, {}> {
  public render() {
    const { legendData } = this.props;

    if (!isArrayWithItems(legendData)) {
      return null;
    }

    return (
      <ul className={styles['container']}>
        {legendData.slice(0, 3).map(item => (
          <li
            key={item.color}
            className={styles['item']}
            style={{
              borderColor: item.color,
            }}
          >
            {item.title}
          </li>
        ))}
        {legendData.length > 3 && <li className={cx(styles['item'], styles['more'])} onClick={this.props.openModal} />}
      </ul>
    );
  }
}
