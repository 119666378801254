import { IconTrust12 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

export function NewbuildingBuilderLabel() {
  return (
    <div className={styles['container']}>
      <div className={styles['icon']}>
        <IconTrust12 color="primary_100" />
      </div>
      <div className={styles['text']}>Надежный застройщик</div>
    </div>
  );
}
