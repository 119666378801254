import { Button, IconCompareAdd16, IconCompareAdded16 } from '@cian/ui-kit';
import * as React from 'react';

interface IComparisonControlNewProps {
  added: boolean;
  disabled: boolean;
  onClick(): void;
}

export function ComparisonControlNew({ added, disabled, onClick }: IComparisonControlNewProps) {
  return (
    <Button
      theme="stroke_secondary"
      beforeIcon={
        added ? (
          <IconCompareAdded16 color="gray_icons_100" />
        ) : (
          <IconCompareAdd16 color={disabled ? 'gray60_100' : 'gray_icons_100'} />
        )
      }
      onClick={onClick}
      disabled={disabled}
      size="XS"
    >
      {added ? 'Добавлено в сравнение' : 'Сравнить'}
    </Button>
  );
}
