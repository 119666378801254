import * as React from 'react';

import { usePageLoaded } from './usePageLoaded';

export function useAdsDisabled(callback: () => void, deps: React.DependencyList) {
  return usePageLoaded(() => {
    if (!window._cianCanRunAds) {
      callback();
    }
  }, deps);
}
