const ymapsPromise: { [key: string]: Promise<YMaps.IYMaps> } = {};

const SCRIPT_URL = 'https://enterprise.api-maps.yandex.ru/2.1/';

export async function loadYMapsApi(jsApiKey: string, suggestApiKey: string, modules?: string[]): Promise<YMaps.IYMaps> {
  if (!ymapsPromise[jsApiKey]) {
    ymapsPromise[jsApiKey] = new Promise((resolve, reject) => {
      if (window.ymaps) {
        resolve(window.ymaps);

        return;
      }

      const e = window.document.createElement('script');
      e.src = `${SCRIPT_URL}?${[
        `apikey=${encodeURIComponent(jsApiKey)}`,
        `suggest_apikey=${encodeURIComponent(suggestApiKey)}`,
        'lang=ru-RU',
        'load=package.system',
        'coordorder=longlat',
      ].join('&')}`;
      e.async = true;
      e.defer = true;
      e.onload = () => {
        resolve(window.ymaps);
      };
      e.onerror = () => {
        reject(new Error('Unable to load Yandex Maps API'));
      };
      window.document.body.appendChild(e);
    });
  }

  return ymapsPromise[jsApiKey].then(
    ymaps =>
      new Promise<YMaps.IYMaps>((resolve, reject) => {
        ymaps.ready({
          require: modules,
          successCallback: () => {
            resolve(ymaps);
          },
          errorCallback: error => {
            reject(error);
          },
        });
      }),
  );
}
