import { ArticleParagraph1, IconFunctionalInformationCircle16, TooltipDesktop } from '@cian/ui-kit';
import * as React from 'react';

import { NBSP } from 'shared/constants';

import * as styles from './NewbuildingPriceCanChangePlate.css';
import icon from './assets/rubleIcon.svg';

interface INewbuildingPriceCanChangePlateProps {
  onTooltipOpen: () => void;
}

export const NewbuildingPriceCanChangePlate: React.FC<INewbuildingPriceCanChangePlateProps> = ({ onTooltipOpen }) => {
  return (
    <div className={styles['plate']} data-testid="PriceCanChangePlate">
      <img className={styles['rur-image']} src={icon} alt="Значок рубля" />
      <ArticleParagraph1>Цена может измениться</ArticleParagraph1>
      <span className={styles['info-icon']} data-testid="PriceCanChangePlateIcon">
        <TooltipDesktop
          onOpen={onTooltipOpen}
          theme="white"
          title={
            <>
              Вам могут предложить:
              <ul className={styles['tooltip-list']} data-testid="PriceCanChangeTooltipList">
                <li>полную оплату одним платежом</li>
                <li>
                  рассрочку с{NBSP}платежами раз в{NBSP}месяц или квартал
                </li>
                <li>ипотеку на{NBSP}условиях банка</li>
              </ul>
              Доступные условия уточняйте у{NBSP}застройщика.
            </>
          }
          placement="bottom-end"
        >
          <IconFunctionalInformationCircle16 color="primary_100" cursor="pointer" />
        </TooltipDesktop>
      </span>
    </div>
  );
};
