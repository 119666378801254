/* eslint-disable max-lines, @typescript-eslint/no-non-null-assertion */

import { DynamicCalltrackingButton, EPageTypes, EPlatformTypes } from '@cian/frontend-dynamic-calltracking-component';
import { Button, ModalWindow } from '@cian/ui-kit';
import loadable from '@loadable/component';
import * as PropTypes from 'prop-types';
import * as React from 'react';

import { trackSendMessageClick } from 'shared/containers/ChatPopupContainer/tracking';
import { OnlineStatusContainer } from 'shared/containers/OnlineStatusContainer';
import {
  IAgentAvailabilitySchema,
  IAgentSchema,
  IKPSchema,
  IOfferSchema,
  IPhoneSchema,
  IUserSchema,
} from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './index.css';
import { trackCloseWarning, trackShowPhoneClick, trackShowWarning } from './tracking';
import { AgentScheduleContainer } from '../../containers/AgentScheduleContainer';
import { CalltrackingBadgeContainer } from '../../containers/CalltrackingBadge';
import { DealRentNoticeContainer } from '../../containers/DealRentNoticeContainer';
import { FlatViewOrderInfoContainer } from '../../containers/FlatViewOrderInfo';
import { IOfferChatsState } from '../../reducers/chat';
import { OfferStatus } from '../../store/offer';
import { IPhone } from '../../store/similar';
import { IApplicationContext } from '../../types/applicationContext';
import { IRosreestrInfo, IValidPhone } from '../../types/offerData';
import { getFormattedPhoneNumber } from '../../utils/formatters';
import { getWorkTime } from '../../utils/getWorkTime';
import { IParent } from '../../utils/offer_helpers';
import { formatNumber } from '../../utils/phone';
import { showPhonesLimitAuth } from '../../utils/showPhonesLimitAuth';
import { SimpleBotDetector } from '../../utils/simpleBotDetector';
import { CalltrackingWarning } from '../CalltrackingWarning';
import { PopupOwnOfferContainer } from '../popups/own_offer/container';

export interface IOfferContactsAsideStateProps {
  abGroup: number;
  agent: IAgentSchema | null;
  isPhonesVisible: boolean;
  offer: IOfferSchema;
  offerChat: IOfferChatsState;
  user: IUserSchema | null;
  agentAvailability: IAgentAvailabilitySchema | null;
  parent: IParent | undefined;
  bookedOfferId: number;
  isCommercial: boolean | null;
  kp: IKPSchema | null;
  siteBlockId: number | null;
  isAuthenticated: boolean;
  newbuildingName: string;
  dynamicCalltrackingPlaceType: string;
  isDealRent: boolean;
  isNewBuilding: boolean;
  entryToShowEnabled: boolean;
  isPik: boolean;
  shouldRenderContactButtons: boolean;
  shouldRenderCallbackButton: boolean;
  showFlatViewNotification?: boolean;
  isDealRentFormEnabled: boolean;
  isCalltrackingEnabled: boolean;
  callButtonText: string;
  /**
   * @todo Удалить блок с экспериментом
   * @description Данный функционал появился в задаче CD-129223, будет удалён в задаче CD-129224
   * Удалить, если эксперимент будет не удачным
   */
  disablePhoneButton: boolean;
  rosreestrInfo: IRosreestrInfo | null;
}

export interface IOfferContactsAsideDispatchProps {
  setPhoneCollapse(): void;
  openDealRentForm(): void;
  openNotAvailablePopup(): void;
  openChat(type?: 'open_phone' | 'open_message'): void;
  requestAuthenticationForChat(type?: 'open_phone' | 'open_message'): void;
}

type IOfferContactsAsideProps = IOfferContactsAsideStateProps & IOfferContactsAsideDispatchProps;

interface IOfferContactsAsideState {
  isOwnOfferPopupOpen: boolean;
  isPhonesOpened: boolean;
  isFlatViewOrderPopupShow: boolean;
  openWarning: boolean;
}

const preparePhones = (phones: IPhone[] | null) => {
  return phones?.map(getFormattedPhoneNumber) || [];
};

export const NewbuildingContactsContainerLoadable = loadable(
  () => import('../../containers/NewbuildingContactsContainer'),
);

export class OfferContactsAside extends React.Component<IOfferContactsAsideProps, IOfferContactsAsideState> {
  public context: IApplicationContext;
  private botDetector = new SimpleBotDetector();

  public state: IOfferContactsAsideState = {
    isOwnOfferPopupOpen: false,
    isPhonesOpened: false,
    isFlatViewOrderPopupShow: false,
    openWarning: false,
  };

  public static contextTypes = {
    httpApi: PropTypes.object,
    logger: PropTypes.object,
  };

  public componentDidMount() {
    this.botDetector.init();
  }

  public render() {
    const {
      offer,
      entryToShowEnabled,
      isNewBuilding,
      shouldRenderContactButtons,
      shouldRenderCallbackButton,
      offerChat: { isChatsEnabled },
    } = this.props;
    const { phones } = offer;
    const { isOwnOfferPopupOpen, openWarning } = this.state;

    if (offer.status !== OfferStatus.Published || entryToShowEnabled) {
      return null;
    }

    return (
      <div data-name="OfferContactsAside" className={styles['container']}>
        {shouldRenderContactButtons && this.renderPrintPhones()}

        {isNewBuilding ? (
          <div className={styles['contacts']}>
            <NewbuildingContactsContainerLoadable
              contactsButton={this.renderPhones()}
              callbackPlaceType="floating_block"
            />
          </div>
        ) : (
          shouldRenderContactButtons && this.renderPhones()
        )}

        {shouldRenderCallbackButton && this.renderWorkTimeInfo()}

        <FlatViewOrderInfoContainer />

        {shouldRenderContactButtons && this.renderSendMessageButton()}

        <OnlineStatusContainer />

        <PopupOwnOfferContainer
          isOpen={isOwnOfferPopupOpen}
          onClose={() => this.setState({ isOwnOfferPopupOpen: false })}
        />

        <ModalWindow
          open={openWarning}
          content={
            <CalltrackingWarning
              isChatEnabled={isChatsEnabled && !offer.dealRentVersion}
              phones={preparePhones(phones)}
              openChat={() => {
                this.setState({ openWarning: false });
                this.openChat();
              }}
            />
          }
          escape
          fixed={false}
          width={516}
          onClose={() => {
            this.setState({ openWarning: false });
            trackCloseWarning();
          }}
          outside
        />
      </div>
    );
  }

  private renderWorkTimeInfo() {
    const {
      offer: { workTimeInfo },
      isPik,
    } = this.props;

    const workTime = getWorkTime(workTimeInfo, isPik ? 'Принимаем звонки' : 'Офис работает');

    return workTime && <div className={styles['callback_time']}>{workTime}</div>;
  }

  private renderPrintPhones() {
    const { isPhonesVisible, offer } = this.props;
    const { phones } = offer;

    if (!phones || phones.some(phone => !phone.countryCode || !phone.number)) {
      return null;
    }

    if (!isPhonesVisible) {
      return null;
    }

    return (
      <div className={styles['print_phones']}>
        {phones.map((phone, index) => (
          <a key={index} href={`tel:${phone.countryCode}${phone.number}`} className={styles['phone']}>{`${
            phone.countryCode
          } ${phone.number!.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2-$3-$4')}`}</a>
        ))}
      </div>
    );
  }

  private renderPhones() {
    const { offer, isNewBuilding } = this.props;
    const { phones } = offer;
    const { isPhonesOpened } = this.state;

    if (!phones || phones.some(phone => !phone.countryCode || !phone.number)) {
      this.context.logger.error(`Offer phones is null`, { cianId: offer.cianId });

      return null;
    }

    return (
      <div className={styles['phones']}>
        {this.renderCalltrackingPhoneButton(phones)}
        {isPhonesOpened && !isNewBuilding && <AgentScheduleContainer />}
      </div>
    );
  }

  private renderCalltrackingPhoneButton(phones: Array<IPhoneSchema>): JSX.Element {
    const { siteBlockId } = this.props;

    return siteBlockId ? this.renderDynamicCalltrackingPhone(phones[0]) : this.renderStaticCalltrackingPhones(phones);
  }

  private renderStaticCalltrackingPhones = (phones: IPhoneSchema[], onButtonClick?: VoidFunction) => {
    const { isDealRentFormEnabled, isCalltrackingEnabled, disablePhoneButton, offer, agent, callButtonText } =
      this.props;

    if (this.state.isPhonesOpened) {
      return (
        <div>
          {phones.map((phone, index) => {
            if (phone && phone.number) {
              return (
                <a key={index} href={`tel:${phone.countryCode}${phone.number}`} className={styles['phone']}>
                  {phone.countryCode} {phone.number.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2-$3-$4')}
                </a>
              );
            }

            return null;
          })}
          {this.props.isDealRent ? <DealRentNoticeContainer /> : <CalltrackingBadgeContainer />}
        </div>
      );
    }

    const buttonContent = isDealRentFormEnabled ? 'Назначить просмотр' : callButtonText;

    const handleButtonClick: VoidFunction = () => {
      /**
       * @todo Удалить блок с экспериментом
       * @description Данный функционал появился в задаче CD-129223, будет удалён в задаче CD-129224
       * Удалить, если эксперимент будет не удачным
       */
      if (disablePhoneButton) {
        showPhonesLimitAuth(offer, agent);
      } else {
        onButtonClick?.();

        if (isCalltrackingEnabled) {
          this.showCalltrackingWarning();
        } else {
          this.onShowPhoneClick();
        }
      }
    };

    return (
      <Button theme="fill_primary" size="M" onClick={handleButtonClick} fullWidth>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {buttonContent as any}
      </Button>
    );
  };

  private renderDynamicCalltrackingPhone = (phone: IPhoneSchema, onButtonClick?: VoidFunction) => {
    const {
      offer,
      siteBlockId,
      dynamicCalltrackingPlaceType,
      isCalltrackingEnabled,
      disablePhoneButton,
      agent,
      callButtonText,
    } = this.props;
    const { isPhonesOpened } = this.state;

    const handleClick = (): void => {
      /**
       * @todo Удалить блок с экспериментом
       * @description Данный функционал появился в задаче CD-129223, будет удалён в задаче CD-129224
       * Удалить, если эксперимент будет не удачным
       */
      if (disablePhoneButton) {
        showPhonesLimitAuth(offer, agent);
      } else {
        onButtonClick?.();

        if (isCalltrackingEnabled) {
          this.showCalltrackingWarning();
        } else {
          this.onShowPhoneClick();
        }
      }
    };

    return (
      <div>
        <DynamicCalltrackingButton
          onClick={handleClick}
          className={styles['show-phone_newbuilding']}
          openPhoneClassName={styles['phone']}
          isOpen={isPhonesOpened}
          phone={formatNumber(phone as IValidPhone) || ''}
          announcementId={offer.id}
          blockId={siteBlockId}
          placeType={dynamicCalltrackingPlaceType}
          platformType={EPlatformTypes.WebDesktop}
          pageType={EPageTypes.OfferCard}
          disableInnerBehavior={disablePhoneButton}
        >
          {callButtonText}
        </DynamicCalltrackingButton>

        {isPhonesOpened && <CalltrackingBadgeContainer />}
      </div>
    );
  };

  private showCalltrackingWarning = () => {
    this.setState({
      openWarning: true,
    });
    trackShowWarning();
    this.trackShowPhoneClick();
  };

  private trackShowPhoneClick = () => {
    const { abGroup, offer, agent, agentAvailability, user, kp, siteBlockId, rosreestrInfo } = this.props;
    const isAgentNotAvailable = agentAvailability && !agentAvailability.available;

    trackShowPhoneClick({
      offer,
      agent,
      user,
      abGroup,
      kpId: kp && kp.id,
      bot: !this.botDetector.isHuman(),
      isAgentNotAvailable,
      siteBlockId,
      rosreestrInfo,
    });
  };

  private onShowPhoneClick = () => {
    const { isDealRentFormEnabled, openDealRentForm, offerChat, agentAvailability } = this.props;
    const isAgentNotAvailable = agentAvailability && !agentAvailability.available;
    const { isChatsEnabled } = offerChat;

    if (isDealRentFormEnabled) {
      openDealRentForm();

      return;
    }

    this.setState({ isPhonesOpened: true });
    this.props.setPhoneCollapse();

    if (isChatsEnabled && isAgentNotAvailable) {
      this.props.openNotAvailablePopup();
    }

    this.trackShowPhoneClick();
  };

  private renderSendMessageButton = () => {
    const { offer, agentAvailability } = this.props;
    const { isChatsEnabled } = this.props.offerChat;
    const isAgentNotAvailable = agentAvailability && !agentAvailability.available;

    return isChatsEnabled && !offer.dealRentVersion ? (
      <div className={styles['button_start_chat-container']}>
        <Button
          theme="fill_secondary"
          size="M"
          onClick={() => this.openChat(isAgentNotAvailable ? 'open_message' : undefined)}
          fullWidth
        >
          Написать
        </Button>
      </div>
    ) : null;
  };

  private openChat = (type?: 'open_phone' | 'open_message') => {
    const { offer, agent, user, openChat, requestAuthenticationForChat } = this.props;

    trackSendMessageClick({ offer, agent });

    if (!user || !user.isAuthenticated) {
      requestAuthenticationForChat(type);

      return;
    }

    if (user && user.realtyUserId === offer.userId) {
      this.setState({ isOwnOfferPopupOpen: true });

      return;
    }

    openChat(type);
  };
}
