import { isWeekend as checkIsWeekend, eachHourOfInterval, setHours, setMinutes, format } from 'date-fns';

export const createEntry = (date: Date) => ({
  value: date.toISOString(),
  label: format(date, 'HH:mm'),
});

export const getTimeIntervals = (dateString: string) => {
  const date = new Date(dateString);
  const isWeekend = checkIsWeekend(new Date(dateString));

  const startHour = 11;
  const endHour = isWeekend ? 15 : 20;

  return eachHourOfInterval({
    start: setHours(date, startHour),
    end: setHours(date, endHour),
  }).reduce((result, current) => [...result, createEntry(current), createEntry(setMinutes(current, 30))], []);
};
