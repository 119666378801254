import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  NewbuildingPremiumBuilderLabel,
  NewbuildingPremiumBuilderLogo,
  NewbuildingPremiumBuilderLayout,
} from 'shared/components/AuthorAsideNew';
import { CompactBuilderLogo } from 'shared/components/AuthorAsideNew/NewbuildingPremiumBuilderInfo/parts/CompactBuilderLogo/CompactBuilderLogo';
import { RatingContainer } from 'shared/containers/AuthorAsideContainerNew/parts/RatingContainer';
import { selectAsideCompactMode } from 'shared/selectors/aside';
import { selectIsNewbuildingPremiumRate } from 'shared/selectors/newbuildingPremiumRate';
import { selectCompanyReliable } from 'shared/selectors/newbuildingPremiumRate/selectCompanyReliable';

import { usePremiumBuilderProps } from './hooks';

export const NewbuildingPremiumBuilderInfo: React.FC = () => {
  const isPremiumNewbuilding = useSelector(selectIsNewbuildingPremiumRate);
  const isCompactModeEnabled = useSelector(selectAsideCompactMode);
  const isCompanyReliable = useSelector(selectCompanyReliable);

  const { builderProps, builderLabel, builderStats } = usePremiumBuilderProps();

  if (!isPremiumNewbuilding || !builderProps || !builderStats.length) {
    return null;
  }

  return (
    <NewbuildingPremiumBuilderLayout
      logo={
        isCompactModeEnabled ? (
          <CompactBuilderLogo isReliable={isCompanyReliable} {...builderProps} rating={<RatingContainer />} />
        ) : (
          <NewbuildingPremiumBuilderLogo {...builderProps} />
        )
      }
      label={builderLabel && !isCompactModeEnabled ? <NewbuildingPremiumBuilderLabel /> : null}
      stats={builderStats}
      isCompact={isCompactModeEnabled}
      rating={isCompactModeEnabled ? null : <RatingContainer />}
    />
  );
};
