import * as React from 'react';

export const RatingIcon = (props: React.HTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" {...props}>
    {/* tslint:disable-next-line:max-line-length */}
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M6.921.253L8.675 3.69l3.923.552a.465.465 0 0 1 .379.309.443.443 0 0 1-.119.465L10.02 7.689l.67 3.78a.447.447 0 0 1-.186.443.483.483 0 0 1-.495.035L6.5 10.165l-3.508 1.783a.483.483 0 0 1-.494-.035.448.448 0 0 1-.187-.444l.67-3.776L.14 5.019a.443.443 0 0 1-.118-.465.465.465 0 0 1 .379-.309l3.923-.555L6.079.253A.471.471 0 0 1 6.499 0c.18 0 .343.098.422.253z"
    />
  </svg>
);
