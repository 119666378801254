import { TooltipDesktop } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackAddOfferToComparison, trackDeleteOfferFromComparison } from './tracking';
import { addOfferToComparison, deleteOfferFromComparison } from '../../actions/comparison';
import { ComparisonControl, ComparisonControlNew } from '../../components/ComparisonControl';
import { ComparisonControlTooltip } from '../../components/ComparisonControlTooltip';
import { getOffer } from '../../selectors/newObject';
import { getAgent } from '../../selectors/newObject/getAgent';
import { getOffersComparisonStatus } from '../../selectors/offersComparison';
import { EStatus } from '../../types/offersComparisonStatus';
import { getCustomUrl } from '../../utils/tracking/getCustomUrl';

interface IComparisonControlContainerProps {
  isNewButton?: boolean;
}

export function ComparisonControlContainer({ isNewButton }: IComparisonControlContainerProps) {
  const dispatch = useDispatch();
  const comparisonStatus = useSelector(getOffersComparisonStatus);
  const offer = useSelector(getOffer);
  const agent = useSelector(getAgent);

  const isAdded = React.useMemo(() => {
    return (comparisonStatus && comparisonStatus.status) === EStatus.Added;
  }, [comparisonStatus]);

  const isUnavailable = React.useMemo(
    () => comparisonStatus?.status === EStatus.Unavailable,

    [comparisonStatus],
  );

  const Control = isNewButton ? ComparisonControlNew : ComparisonControl;

  const isComparisonDescriptionAvailable = React.useMemo(() => {
    return comparisonStatus?.description?.length;
  }, [comparisonStatus]);

  const handleClick = React.useCallback(() => {
    const offerId = offer.id;
    if (isAdded) {
      dispatch(deleteOfferFromComparison({ offerId }));
      trackDeleteOfferFromComparison({ label: getCustomUrl(offer, agent) });
    } else {
      dispatch(addOfferToComparison({ offerId }));
      trackAddOfferToComparison({ label: getCustomUrl(offer, agent) });
    }
  }, [isAdded, offer]);

  if (!comparisonStatus) {
    return null;
  }

  return !isComparisonDescriptionAvailable ? (
    <Control onClick={handleClick} added={isAdded} disabled={isUnavailable} />
  ) : (
    <TooltipDesktop
      theme="white"
      placement="bottom"
      arrow={true}
      title={<ComparisonControlTooltip text={comparisonStatus.description} />}
    >
      <div>
        <Control onClick={handleClick} added={isAdded} disabled={isUnavailable} />
      </div>
    </TooltipDesktop>
  );
}
