import { IconArrowBoldDown12, IconArrowBoldUp12, IconDynamicChart16 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

interface IPriceHistoryButtonProps {
  trend?: 'inc' | 'dec' | null;
}

export function PriceHistoryButton({ trend }: IPriceHistoryButtonProps) {
  const TrendIcon = trend === 'inc' ? IconArrowBoldUp12 : IconArrowBoldDown12;

  return (
    <button type="button" className={styles['button']} title="История цены">
      <span className={styles['main']}>
        <IconDynamicChart16 />
      </span>
      {trend && (
        <span className={cx(styles['trend'], trend === 'inc' && styles['inc'])}>
          <TrendIcon color="current_color" />
        </span>
      )}
    </button>
  );
}
