/* eslint-disable */
//tslint:disable

import { IInfrastructureItemSchema } from './InfrastructureItemSchema';

export interface ICategoryItemsSchema {
  /** Категория **/
  category: ECategory;
  /** Список объектов **/
  items: IInfrastructureItemSchema[];
  /** Отображаемое название категории **/
  title: string;
}

export enum ECategory {
  /** Кафе **/
  Cafe = 'cafe',
  /** Поликлиники **/
  Clinic = 'clinic',
  /** Продуктовые магазины **/
  Grocery = 'grocery',
  /** Детские сады **/
  Nursery = 'nursery',
  /** Школы **/
  School = 'school',
}
