import { ca } from '@cian/analytics';

import { ECategory } from '../../../../repositories/infrastructure-caching/entities/infrastructure/CategoryItemsSchema';

const LABEL_MAP = {
  [ECategory.Cafe]: 'Cafe',
  [ECategory.Clinic]: 'Clinic',
  [ECategory.School]: 'School',
  [ECategory.Nursery]: 'Kindergarten',
  [ECategory.Grocery]: 'Grocery',
};

export function trackInfrastructureOpen() {
  ca('eventSite', {
    action: 'Open',
    category: 'Infrastructure',
    label: 'Tab',
    name: 'oldevent',
    useLastProducts: true,
  });
}

export function trackInfrastructureSelectCategory(category: ECategory) {
  ca('eventSite', {
    action: 'Select',
    category: 'Infrastructure',
    label: LABEL_MAP[category],
    name: 'oldevent',
    useLastProducts: true,
  });
}

export function trackInfrastructureDisableCategory(category: ECategory) {
  ca('eventSite', {
    action: 'Disable',
    category: 'Infrastructure',
    label: LABEL_MAP[category],
    name: 'oldevent',
    useLastProducts: true,
  });
}

export function trackInfrastructurePinClick() {
  ca('eventSite', {
    action: 'Click',
    category: 'Infrastructure',
    label: 'Map_pin',
    name: 'oldevent',
    useLastProducts: true,
  });
}

export function trackInfrastructurePinLinkClick(category: ECategory) {
  ca('eventSite', {
    action: 'ClickDetailed',
    category: 'Infrastructure',
    label: LABEL_MAP[category],
    name: 'oldevent',
    useLastProducts: true,
  });
}
