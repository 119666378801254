import { Button, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './NewbuildingBrokerBlock.css';

interface INewbuildingBrokerBlock {
  loading: boolean;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  rewardTariffsLink: string;
  buttonText: string;
}

export const NewbuildingBrokerBlock: React.FC<INewbuildingBrokerBlock> = ({
  loading,
  onClick,
  rewardTariffsLink,
  buttonText,
}) => {
  return (
    <div className={styles['container']} data-testid="NewbuildingBrokerBlock">
      <div className={styles['item']}>
        <UIText2 whiteSpace="nowrap" color="black_100">
          Заработайте комиссию от застройщика
        </UIText2>
        <div className={styles['divider']} />
        <UIText2 whiteSpace="nowrap" color="black_100">
          от 0,7%
        </UIText2>
      </div>
      <a
        className={styles['link']}
        href={rewardTariffsLink}
        data-testid="NewbuildingBrokerLink"
        target="_blank"
        rel="noopener noreferrer"
      >
        Сколько можно зарабатывать
      </a>
      <div className={styles['button']} data-testid="NewbuildingBrokerButton">
        <Button loading={loading} onClick={onClick} size="M" theme="fill_success_primary" type="button" fullWidth>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
