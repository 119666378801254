import { ContactCard, ContactAvatar, TContactCardTheme } from '@cian/contact-card-component';
import { UiKitContextProvider } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './AgencyBrandingAsideCard.css';

interface IAgencyCard {
  agencyAvatar: string | null;
  onAgencyNameClick(): void;
  agencyName: string;
  agencyLink: string;
  title: string;
  agencyLabels: React.ReactNode;
  agencyFeaturesElement: React.ReactNode;
  agentElement: React.ReactNode;
  superAgentElement: React.ReactNode;
  theme: TContactCardTheme;
}

const AgencyBrandingAsideCardComponent: React.FC<React.PropsWithChildren<IAgencyCard>> = ({
  agencyAvatar,
  onAgencyNameClick,
  agencyName,
  agencyLink,
  agencyLabels,
  agencyFeaturesElement,
  agentElement,
  title,
  theme,
  superAgentElement,
}) => {
  return (
    <div data-testid="AgencyBrandingAsideCard" className={styles['body']}>
      <UiKitContextProvider useStaticDeviceType deviceType="desktop">
        <ContactCard
          title={title}
          onNameClick={onAgencyNameClick}
          avatar={<ContactAvatar type="agency" src={agencyAvatar} />}
          name={agencyName}
          href={agencyLink}
          labels={agencyLabels}
          features={agencyFeaturesElement}
          subcontact={agentElement}
          theme={theme}
          qualityInfo={superAgentElement}
        />
      </UiKitContextProvider>
    </div>
  );
};

export const AgencyBrandingAsideCard = React.memo(AgencyBrandingAsideCardComponent);
