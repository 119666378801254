/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { GroceryStoreIcon } from './grocery-store';
import { KindergardenIcon } from './kindergarden';
import { PolyclinicsIcon } from './polyclinics';
import { SchoolIcon } from './school';
import { ECategory } from '../../../../repositories/infrastructure-caching/entities/infrastructure/CategoryItemsSchema';

export const getIconComponent = (category: ECategory): React.FC<React.SVGProps<SVGElement>> | undefined => {
  const icons: any = {
    [ECategory.School]: SchoolIcon,
    [ECategory.Nursery]: KindergardenIcon,
    [ECategory.Clinic]: PolyclinicsIcon,
    [ECategory.Grocery]: GroceryStoreIcon,
  };

  return icons[category];
};

export { SchoolIcon, KindergardenIcon, PolyclinicsIcon, GroceryStoreIcon };
