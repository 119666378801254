import cx from 'clsx';
import * as React from 'react';

import { CloseIcon } from './icons/close';
import { PriorityIcon } from './icons/priority';
import { RatingIcon } from './icons/rating';
import * as styles from './styles.css';
import { getIconComponent } from '../icons';
import { trackInfrastructurePinLinkClick } from '../tracking/tracking';
import { IInfrastructureItem } from '../types';

interface IActiveItemPopupProps {
  item: IInfrastructureItem | null;
  setItem(item: IInfrastructureItem | null, pinned?: boolean): void;
  onClose(): void;
  pinned?: boolean;
}

export class ActiveItemPopup extends React.Component<IActiveItemPopupProps> {
  public render() {
    const { item } = this.props;

    if (!item) {
      return null;
    }

    const Icon = getIconComponent(item.category);
    const { address, distance, ratingDescription, priorityDescription } = item;

    return (
      <div
        className={styles['popup']}
        onMouseEnter={() => {
          /*
           * Пин отключается по mouseleave, если его перекрывает это окно
           * Эта функкия предотвращает скрытие папапа
           * item должен быть замкнут в переменной
           *
           * Стоит заменить этот костыль на перемещение карты по выбору пина
           */
          if (!this.props.pinned) {
            this.props.setItem(item, true);
          }
        }}
      >
        <div className={styles['main']}>
          {typeof Icon !== 'undefined' && <Icon className={styles['icon']} />}
          {this.renderTitle(item)}
          {(address || distance) && (
            <div className={styles['address']}>
              {address}
              {address && distance && ' ∙ '}
              {distance}
            </div>
          )}
        </div>
        {(priorityDescription || ratingDescription) && (
          <div className={styles['labels']}>
            {priorityDescription && (
              <div className={styles['label']}>
                <div className={cx(styles['label-icon'], styles['priority-icon'])}>
                  <PriorityIcon />
                </div>
                {priorityDescription}
              </div>
            )}
            {ratingDescription && (
              <div className={styles['label']}>
                <div className={cx(styles['label-icon'], styles['rating-icon'])}>
                  <RatingIcon />
                </div>
                {ratingDescription}
              </div>
            )}
          </div>
        )}
        <div className={styles['close']} onClick={this.props.onClose}>
          <CloseIcon />
        </div>
      </div>
    );
  }

  private renderTitle(item: IInfrastructureItem) {
    const { title, url } = item;

    if (!title) {
      return null;
    }

    if (!url) {
      return <div className={styles['title']}>{title}</div>;
    }

    return (
      <a
        href={url}
        className={styles['title']}
        rel="noreferrer noopener"
        target="_blank"
        onClick={() => {
          trackInfrastructurePinLinkClick(item.category);
        }}
      >
        {title}
      </a>
    );
  }
}
