/* eslint-disable @typescript-eslint/no-explicit-any */
import { omit } from 'ramda';
import * as React from 'react';

import { getInfrastructureIconLayout } from './placemark';
import { IInfrastructureItem } from '../types';

interface IInfrastructurePlacemarksProps {
  map: YMaps.Map;
  items: IInfrastructureItem[];
  onActive(item: IInfrastructureItem | null, forced?: boolean): void;
  activeItem: IInfrastructureItem | null;
  activeItemPinned?: boolean;
}

interface IInfrastructurePlacemarksState {
  items: IInfrastructureItem[];
  placemarks: YMaps.Placemark[];
}

const mapItemsToPlacemarks = (items: IInfrastructureItem[]) => {
  return items.reduce((placemarks, item) => {
    const { coordinates } = item;

    return [
      ...placemarks,
      new window.ymaps.Placemark(
        [coordinates.lon, coordinates.lat],
        {
          id: item.cianId,
        },
        {
          iconLayout: getInfrastructureIconLayout(window.ymaps, item.category),
          iconShape: {
            coordinates: [0, 0] as [number, number],
            radius: 12,
            type: 'Circle' as const,
          },
          zIndex: 1,
        },
      ),
    ];
  }, []);
};

type TPlacemarkMap = {
  [id: string]: YMaps.Placemark | null;
};

export class InfrastructurePlacemarks extends React.Component<
  IInfrastructurePlacemarksProps,
  IInfrastructurePlacemarksState
> {
  private rendered: TPlacemarkMap = {};

  public constructor(props: IInfrastructurePlacemarksProps) {
    super(props);

    this.state = {
      placemarks: mapItemsToPlacemarks(props.items),
      items: props.items,
    };
  }

  public static getDerivedStateFromProps(props: IInfrastructurePlacemarksProps, state: IInfrastructurePlacemarksState) {
    const { items } = props;

    if (items !== state.items) {
      return {
        placemarks: mapItemsToPlacemarks(items),
        items,
      };
    }

    return null;
  }

  public componentDidMount() {
    this.processPlacemarks();
  }

  public componentDidUpdate(prevProps: IInfrastructurePlacemarksProps, prevState: IInfrastructurePlacemarksState) {
    const prevPlacemarks = prevState.placemarks;

    if (this.state.placemarks !== prevPlacemarks) {
      this.processPlacemarks();
    }

    if (this.props.activeItem !== prevProps.activeItem) {
      this.setActivePlacemark(prevProps.activeItem);
    }
  }

  public componentWillUnmount() {
    this.deletePlacemarks(this.rendered, true);
  }

  public render() {
    return null;
  }

  private processPlacemarks = () => {
    const { items, placemarks } = this.state;
    const {
      toRender,
      toIgnore,
    }: {
      toRender: TPlacemarkMap;
      toIgnore: TPlacemarkMap;
    } = placemarks.reduce(
      (result, placemark) => {
        const { rendered } = this;
        const id = placemark.properties.get<string>('id');
        const isRendered = !!rendered[id];

        if (isRendered) {
          return {
            ...result,
            toIgnore: {
              ...result.toIgnore,
              [id]: placemark,
            },
          };
        }

        return {
          ...result,
          toRender: {
            ...result.toRender,
            [id]: placemark,
          },
        };
      },
      {
        toRender: {},
        toIgnore: {},
      },
    );

    const toDelete: TPlacemarkMap = Object.keys(this.rendered).reduce((toDelete, id) => {
      if (toIgnore[id]) {
        return toDelete;
      }

      return {
        ...toDelete,
        [id]: this.rendered[id] as YMaps.Placemark,
      };
    }, {} as TPlacemarkMap);

    Object.keys(toRender).forEach(id => {
      const placemark = toRender[id] as YMaps.Placemark;
      const item = items.find(({ cianId }) => cianId === id);

      if (!item) {
        return;
      }

      this.props.map.geoObjects.add(placemark);

      placemark.events.add('click', () => {
        const isPinned = !!(item === this.props.activeItem && this.props.activeItemPinned);

        this.props.onActive(item, !isPinned);
      });

      placemark.events.add('mouseenter', () => {
        this.props.onActive(item);
      });

      placemark.events.add('mouseleave', () => {
        this.props.onActive(null);
      });

      this.rendered = {
        ...this.rendered,
        [id]: placemark,
      };
    });

    this.deletePlacemarks(toDelete);
  };

  private deletePlacemarks(placemarks: TPlacemarkMap, forced?: boolean) {
    Object.keys(placemarks).forEach(async id => {
      const placemark = placemarks[id] as YMaps.Placemark;
      const item = this.state.items.find(({ cianId }) => cianId === id);

      if (this.props.activeItem === item) {
        this.props.onActive(null);
      }

      this.rendered = omit([id], this.rendered);

      if (!forced) {
        await placemark.options.get<any>('iconLayout').hide();
      }

      this.props.map.geoObjects.remove(placemark);
    });
  }

  private setActivePlacemark(prevActive?: IInfrastructureItem | null) {
    const prevId = prevActive && prevActive.cianId;
    const prevActivePlacemark = prevId && this.rendered[prevId];

    if (prevActivePlacemark) {
      prevActivePlacemark.options.get<any>('iconLayout').setActive(false);
    }

    const id = this.props.activeItem && this.props.activeItem.cianId;
    const activePlacemark = id && this.rendered[id];

    if (activePlacemark) {
      activePlacemark.options.get<any>('iconLayout').setActive(true);
    }
  }
}
