import { ErrorLogComponent } from '@cian/error-log-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { MainNewTitle } from '../..//components/MainNewTitle';
import { MainNew } from '../../components/MainNew';
import { CommentContainer } from '../../components/comments/comment/container';
import { getCommentBlock, selectIsOfferPublished } from '../../selectors';
import { selectBuilderInfo, selectIsNewbuildingPremiumRate } from '../../selectors/newbuildingPremiumRate';
import { StretchBannerContainer } from '../Adfox';
import { useApplicationContext } from '../ApplicationContext';
import { GeoContainer } from '../GeoContainer/GeoContainer';
import { NewbuildingBuilderStretchBannerContainer } from '../NewbuildingBuilderStretchBannerContainer';
import { ToolbarContainer } from '../ToolbarContainer';

export function MainNewContainer() {
  const { logger } = useApplicationContext();
  const isOfferPublished = useSelector(selectIsOfferPublished);
  const { isOpen } = useSelector(getCommentBlock);
  const builderInfo = useSelector(selectBuilderInfo);
  const isNewbuildingPremiumRate = useSelector(selectIsNewbuildingPremiumRate);

  return (
    <MainNew
      title={<MainNewTitle />}
      geo={<GeoContainer />}
      toolbar={isOfferPublished && <ToolbarContainer />}
      comment={
        isOpen && (
          <ErrorLogComponent logger={logger}>
            <CommentContainer />
          </ErrorLogComponent>
        )
      }
      stretchBanner={
        isNewbuildingPremiumRate && builderInfo ? (
          <NewbuildingBuilderStretchBannerContainer builderInfo={builderInfo} />
        ) : (
          <StretchBannerContainer />
        )
      }
    />
  );
}
