import { useBooleanState } from '@cian/react-utils';
import { Button, IconClose16, IconDislike16, IconLike16 } from '@cian/ui-kit';
import * as React from 'react';

import { ComplaintModal } from 'shared/components/complaint';

import { trackFeedbackOptionClick } from '../../../tracking';
import * as styles from '../styles/styles.css';

interface IQuestionBlockProps {
  onYesButtonClick(): void;
  onDidNotPickUpPhoneButtonClick(): void;
}

export function QuestionBlock(props: IQuestionBlockProps) {
  const { onYesButtonClick, onDidNotPickUpPhoneButtonClick } = props;
  const { setFalse: closeModalComplain, setTrue: openModalComplain, state: stateComplain } = useBooleanState();

  const handleNoButtonClick = () => {
    trackFeedbackOptionClick('not_confirmed');

    openModalComplain();
  };

  const handleYesButtonClick = () => {
    trackFeedbackOptionClick('confirmed');

    onYesButtonClick();
  };

  const handleDidNotPickUpPhoneButtonClick = () => {
    trackFeedbackOptionClick('no_answer');

    onDidNotPickUpPhoneButtonClick();
  };

  return (
    <div className={styles['question_block']}>
      Подтвердилась ли информация, указанная в объявлении?
      <div className={styles['actions']}>
        <div className={styles['action_button']}>
          <Button
            beforeIcon={<IconDislike16 color="current_color" />}
            size="XS"
            theme="fill_secondary"
            onClick={handleNoButtonClick}
          >
            Нет
          </Button>
        </div>

        <div className={styles['action_button']}>
          <Button
            beforeIcon={<IconLike16 color="current_color" />}
            size="XS"
            theme="fill_secondary"
            onClick={handleYesButtonClick}
          >
            Да
          </Button>
        </div>

        <div className={styles['action_button']}>
          <Button
            size="XS"
            theme="fill_secondary"
            onClick={handleDidNotPickUpPhoneButtonClick}
            beforeIcon={<IconClose16 color="current_color" />}
          >
            Не взяли трубку
          </Button>
        </div>
      </div>
      <ComplaintModal openModal={stateComplain} onCloseModal={closeModalComplain} />
    </div>
  );
}
