/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';

import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { ActiveItemPopup } from './active-item-popup';
import { InfrastructurePlacemarks } from './placemarks';
import * as styles from './styles.css';
import { InfrastructureTabs } from './tabs';
import {
  trackInfrastructureOpen,
  trackInfrastructureSelectCategory,
  trackInfrastructurePinClick,
  trackInfrastructureDisableCategory,
} from './tracking';
import { IInfrastructureItem } from './types';
import { ECategory } from '../../../repositories/infrastructure-caching/entities/infrastructure/CategoryItemsSchema';
import {
  IGetInfrastructureForMapRequest,
  EDealType,
  EOfferType,
} from '../../../repositories/infrastructure-caching/v1/get-infrastructure-for-map';

export interface IInfrastructureMapExtensionProps {
  map: YMaps.Map;
  objectManager: YMaps.ObjectManager;
  offer: IOfferSchema;

  bbox?: string | null;
  /** Объекты инфраструктуры на карте */
  items: IInfrastructureItem[];
  /** Выбранныые категории объектов инфраструктуры */
  categories: ECategory[];

  fetchInfrastructureMap(params: IGetInfrastructureForMapRequest): Promise<any>;
  setCategories(categories: ECategory[]): void;
}

export interface IInfrastructureMapExtensionState {
  activeItem: IInfrastructureItem | null;
  activeItemPinned?: boolean;
}

export class InfrastructureMapExtension extends React.Component<
  IInfrastructureMapExtensionProps,
  IInfrastructureMapExtensionState
> {
  public state: IInfrastructureMapExtensionState = {
    activeItem: null,
  };

  private setBoundsTimeout: number;

  public componentDidMount() {
    const { bbox } = this.props;

    if (bbox) {
      this.setBoundsTimeout = window.setTimeout(() => {
        this.setBounds(bbox);
      }, 0);
    } else {
      this.ensureInfrastructure();
    }

    trackInfrastructureOpen();
  }

  public componentDidUpdate(prevProps: IInfrastructureMapExtensionProps) {
    const { bbox } = this.props;

    if (bbox && !prevProps.bbox) {
      this.setBounds(bbox);
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('load', this.fetchInfrastructure);

    clearTimeout(this.setBoundsTimeout);
  }

  public render() {
    const { categories, items } = this.props;

    return (
      <>
        <InfrastructurePlacemarks
          map={this.props.map}
          items={items}
          onActive={this.handleSetActiveItem}
          activeItem={this.state.activeItem}
          activeItemPinned={this.state.activeItemPinned}
        />
        <InfrastructureTabs
          className={styles['tabs']}
          selected={categories}
          onSelect={this.handleInfrastructureSelect}
        />
        <ActiveItemPopup
          item={this.state.activeItem}
          setItem={this.handleSetActiveItem}
          onClose={this.handleCloseActiveItemPopup}
          pinned={this.state.activeItemPinned}
        />
      </>
    );
  }

  private handleSetActiveItem = (activeItem: IInfrastructureItem | null, pinned?: boolean) => {
    if (this.state.activeItemPinned && pinned === undefined) {
      return;
    }

    this.setState({
      activeItem,
      activeItemPinned: pinned,
    });

    if (pinned) {
      trackInfrastructurePinClick();
    }
  };

  private handleCloseActiveItemPopup = () => {
    this.setState({
      activeItem: null,
      activeItemPinned: false,
    });
  };

  private handleInfrastructureSelect = (type: ECategory) => {
    const { setCategories, categories } = this.props;

    const shouldSelect = !categories.includes(type);

    let newCategories: ECategory[];

    if (shouldSelect) {
      trackInfrastructureSelectCategory(type);

      newCategories = categories.concat(type);
    } else {
      trackInfrastructureDisableCategory(type);

      newCategories = categories.filter(t => t !== type);
    }

    setCategories(newCategories);
  };

  private setBounds(bbox: string) {
    const coords = bbox.split(',').map(Number);

    this.props.map.setBounds([
      [coords[0], coords[1]],
      [coords[2], coords[3]],
    ]);
  }

  private ensureInfrastructure = () => {
    // Нужно дождаться загрузки CSS
    if (document.readyState === 'complete') {
      this.fetchInfrastructure();
    } else {
      window.addEventListener('load', this.fetchInfrastructure);
    }
  };

  private fetchInfrastructure = () => {
    const size = this.props.map.container.getSize();

    this.props.fetchInfrastructureMap({
      aspectRatio: size.join(':'),
      dealType: this.props.offer.dealType as EDealType,
      offerType: this.props.offer.offerType as EOfferType,
      realtyOfferId: this.props.offer.id,
    });
  };
}
