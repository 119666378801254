import { ca } from '@cian/analytics';

export function trackTooltipLoginClick() {
  ca('event', {
    action: 'click',
    category: 'SyncFavorites_popup',
    label: 'login',
    name: 'oldevent',
  });
}

export function trackTooltipRegisterClick() {
  ca('event', {
    action: 'click',
    category: 'SyncFavorites_popup',
    label: 'registration',
    name: 'oldevent',
  });
}
