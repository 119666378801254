/* eslint-disable @typescript-eslint/no-explicit-any */

import { memoizeWith } from 'ramda';
import * as React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { InfrastructurePlacemarkCircle } from './circle';
import { ECategory } from '../../../../../repositories/infrastructure-caching/entities/infrastructure/CategoryItemsSchema';
import { getIconComponent } from '../../icons';

const getTemplate = memoizeWith(JSON.stringify, (category: ECategory) => {
  const Icon = getIconComponent(category);

  if (!Icon) {
    return undefined;
  }

  return renderToStaticMarkup(
    <InfrastructurePlacemarkCircle>
      <Icon />
    </InfrastructurePlacemarkCircle>,
  );
});

export function getInfrastructureIconLayout(ymaps: YMaps.IYMaps, category: ECategory) {
  const template = getTemplate(category);

  if (!template) {
    return;
  }

  let _this: any;
  let rejectHiding: (() => void) | null;

  const layout: any = ymaps.templateLayoutFactory.createClass(
    template,
    {
      build() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        _this = this;

        layout.superclass.build.call(_this);

        const element = _this.getParentElement().querySelector('div');

        if (!_this.inited) {
          element.classList.add('inited');

          _this.inited = true;
        }
      },
      clear() {
        if (rejectHiding) {
          rejectHiding();
        }

        layout.superclass.clear.call(this);
      },
    },
    {
      hide() {
        return new Promise<void>((resolve, reject) => {
          const animationDuration = 200;

          const timeoutId = setTimeout(() => {
            rejectHiding = null;

            resolve();
          }, animationDuration);

          rejectHiding = () => {
            clearTimeout(timeoutId);

            reject();
          };

          const element = _this.getParentElement().querySelector('div');
          element.classList.add('hidden');
        });
      },
      setActive(active: boolean) {
        const element = _this.getParentElement().querySelector('div');
        element.classList.toggle('active', active);
      },
    },
  );

  return layout;
}
