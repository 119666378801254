import { Button, IconPanorama36016 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './PanoramaBanner.css';
import { TPanoramaContainerProps } from '../../containers/PanoramaContainer';

export interface IPanoramaBannerProps {
  panoramaUrl: string;
  children: React.ReactElement<TPanoramaContainerProps>;
}

export const PanoramaBanner: React.FC<React.PropsWithChildren<IPanoramaBannerProps>> = ({ panoramaUrl, children }) => {
  const [isActive, setIsActive] = React.useState(false);

  const handleButtonClick = React.useCallback(() => {
    setIsActive(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setIsActive(false);
  }, []);

  return (
    <>
      <div className={styles['container']}>
        <Button
          beforeIcon={<IconPanorama36016 color="primary_100" />}
          theme="fill_white_primary"
          onClick={handleButtonClick}
        >
          Посмотреть двор
        </Button>
      </div>
      {isActive ? React.cloneElement<TPanoramaContainerProps>(children, { panoramaUrl, onClose: handleClose }) : null}
    </>
  );
};
