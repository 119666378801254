import cx from 'clsx';
import * as React from 'react';

import * as styles from './Phones.css';

interface IPhone {
  link: string;
  number: string;
}

export interface IPhonesProps {
  phones: IPhone[];
  size?: 'xxs' | 'xs' | 's' | 'm';
  inRow?: boolean;
}

export function Phones({ phones, size = 'm', inRow }: IPhonesProps) {
  return (
    <div className={cx(styles['phones'], inRow && styles['row'])}>
      {phones.map((phone, index) => (
        <div className={cx(styles['phone'], styles[`phone-size-${size}`])} key={index}>
          <a href={phone.link} className={styles['phone-link']}>
            {phone.number}
          </a>
        </div>
      ))}
    </div>
  );
}
