import { IconClock16, UIText3 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './QuickReplyStatus.css';

export const QuickReplyStatus: React.FC = () => {
  return (
    <span className={styles['status']}>
      <IconClock16 color="primary_100" />
      <UIText3 color="black_60">Быстро отвечает на сообщения</UIText3>
    </span>
  );
};
