import * as React from 'react';

import { NoTourSupport } from './NoTourSupport';

export interface ITourProps {
  frameRef?: React.Ref<HTMLIFrameElement>;
  tourUrl: string;
}

export function Tour({ frameRef, tourUrl }: ITourProps) {
  const isIE = /MSIE|Trident/.test(window.navigator.userAgent);

  if (isIE) {
    return <NoTourSupport />;
  }

  return (
    <iframe
      data-name="TourIFrame"
      data-testid="TourIFrame"
      ref={frameRef}
      frameBorder={0}
      src={tourUrl}
      width="100%"
      height="100%"
      allowFullScreen
      sandbox="allow-scripts allow-same-origin allow-popups allow-downloads"
    />
  );
}
