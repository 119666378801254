import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OfferMap } from '../../components/map/map';
import { IState } from '../../store';
import { fetchSimilar, ISimilarOffersData, IViewedData, markViewed } from '../../store/map_similar';
import { changeTab, IMapTabs, TMapTab } from '../../store/map_tabs';
import { IOfferData } from '../../types/offerData';
import { getPageEBC } from '../../utils/tracking/get_page_ebc';
import { getUserEBC } from '../../utils/tracking/get_user_ebc';
import { useApplicationContext } from '../ApplicationContext';

export interface IMapContainer {
  mapRef?(map: YMaps.Map): void;
}

export const MapContainer: React.FC<React.PropsWithChildren<IMapContainer>> = ({ mapRef }) => {
  const dispatch = useDispatch();
  const { config } = useApplicationContext();
  const ymapsApiKey = config.getStrict<string>('frontendOfferCard.yandexMaps.apiKey');
  const suggestApiKey = config.getStrict<string>('frontendOfferCard.yandexMaps.suggestApiKey');
  const { activeTab } = useSelector<IState, IMapTabs>(state => state.mapTabs);
  const abGroup = useSelector<IState, number>(state => state.abGroup);
  const offerData = useSelector<IState, IOfferData>(state => state.offerData);
  const mapSimilar = useSelector<IState, ISimilarOffersData>(state => state.mapSimilar);
  const { ids: viewedSimilarIds } = useSelector<IState, IViewedData>(state => state.viewedSimilar);

  const ebcUser = React.useMemo(() => getUserEBC(offerData.user, abGroup), [abGroup, offerData.user]);
  const ebcPage = React.useMemo(
    () => getPageEBC(offerData.offer.id, offerData.breadcrumbs),
    [offerData.breadcrumbs, offerData.offer.id],
  );

  const handleTabChange = React.useCallback(
    (tab: TMapTab) => {
      dispatch(changeTab(tab));
    },
    [dispatch],
  );

  const handleSimilarFetch = React.useCallback(
    (bounds: [[number, number], [number, number]]) => {
      dispatch(fetchSimilar(bounds));
    },
    [dispatch],
  );

  const handleMarkViewed = React.useCallback(
    (id: string) => {
      dispatch(markViewed(id));
    },
    [dispatch],
  );

  return (
    <OfferMap
      getMapInstance={mapRef}
      activeTab={activeTab}
      agent={offerData.agent}
      offer={offerData.offer}
      page={ebcPage}
      similarObjects={mapSimilar}
      user={ebcUser}
      viewedIds={viewedSimilarIds}
      ymapsApiKey={ymapsApiKey}
      suggestApiKey={suggestApiKey}
      changeTab={handleTabChange}
      fetchSimilar={handleSimilarFetch}
      markViewed={handleMarkViewed}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default MapContainer;
