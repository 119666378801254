import { pathOr } from 'ramda';

import { IOfferRecommendation } from 'shared/types/microfrontends/newbuildingCallbackWidget';

import { getNewbuildingName } from './newObject';
import {
  ISimilarOfferRecommendation,
  isSimilarOfferRecommendation,
  mapSimilarOfferToRecommendation,
} from '../mappers/callbackRecommendations';
import { IState } from '../store';
import { ISimilarOffer } from '../store/similar';

export function getCallbackRecommendations(state: IState): IOfferRecommendation[] {
  const recommendation = pathOr<ISimilarOffer[]>([], ['similarOffers', 'itemBased', 'list'], state);
  const originalName = getNewbuildingName(state);

  const recommendationsFromBuilder = recommendation.filter(
    (item: ISimilarOffer): item is ISimilarOfferRecommendation =>
      isSimilarOfferRecommendation(item) && Boolean(item.isFromBuilder),
  );

  const recommendationsByNewbuilding = recommendationsFromBuilder.reduce((map, item) => {
    if (!map.has(item.newbuildingName)) {
      map.set(item.newbuildingName, item);
    }

    return map;
  }, new Map<string, ISimilarOfferRecommendation>());

  recommendationsByNewbuilding.delete(originalName);

  return Array.from(recommendationsByNewbuilding.values()).slice(0, 3).map(mapSimilarOfferToRecommendation);
}
