import * as React from 'react';
import { useDispatch } from 'react-redux';

import { openBestPlaceReportModal } from 'shared/actions/bestPlaceReportModal';

import { BestPlaceReportAside } from '../../components/BestPlaceReportAside';
import { useTrackBestPlaceReport } from '../../hooks/useTrackBestPlaceReport';

export const BestPlaceReportAsideContainer: React.FC = () => {
  const dispatch = useDispatch();
  const trackBestPlaceReportButtonClick = useTrackBestPlaceReport();

  const handleBuyReport = React.useCallback(() => {
    trackBestPlaceReportButtonClick('click_buy_report');
    dispatch(openBestPlaceReportModal());
  }, [dispatch, trackBestPlaceReportButtonClick]);

  return <BestPlaceReportAside handleBuyReport={handleBuyReport} />;
};

BestPlaceReportAsideContainer.displayName = 'BestPlaceReportAsideContainer';
