import * as React from 'react';
import { useSelector } from 'react-redux';

import { Tour, TourSlide } from 'shared/components/TourSlide';
import { getExternalTour } from 'shared/selectors/newObject/getExternalTour';
import { getExternalTourBannerImageUrl } from 'shared/selectors/newObject/getExternalTourBannerImageUrl';
import { virtualTourBannerImageUrlSelector } from 'shared/selectors/newObject/getTourBannerImageUrl';

import { track3DTourBlockClick } from './tracking';
import { virtualTourKeySelector } from '../../selectors/newObject/getTour';
import { useApplicationContext } from '../ApplicationContext';

export function TourSlideContainer() {
  const { config } = useApplicationContext();

  const [open, setOpen] = React.useState(false);
  const tourFrameRef = React.useRef<HTMLIFrameElement>(null);

  const isExternalTourEnabled = !!config.get<boolean>('external_tour_enabled.Enabled');
  const tourKey = useSelector(virtualTourKeySelector);
  const tourBannerImageUrl = useSelector(virtualTourBannerImageUrlSelector);
  const externalTourKey = useSelector(getExternalTour);
  const externalTourBannerImageUrl = useSelector(getExternalTourBannerImageUrl);

  const tourLink = React.useMemo(() => {
    return isExternalTourEnabled ? tourKey || externalTourKey : tourKey;
  }, [tourKey, externalTourKey, isExternalTourEnabled]);

  const tourBannerImg = React.useMemo(() => {
    return isExternalTourEnabled ? tourBannerImageUrl || externalTourBannerImageUrl : tourBannerImageUrl;
  }, [externalTourBannerImageUrl, tourBannerImageUrl, isExternalTourEnabled]);

  const handleTourShow = React.useCallback(() => {
    setOpen(true);
    track3DTourBlockClick();
  }, []);

  if (!tourLink || !tourBannerImg) {
    return null;
  }

  return (
    <>
      {!open && (
        <TourSlide tourBannerImageUrl={tourBannerImg} onClick={handleTourShow} isExternal={Boolean(externalTourKey)} />
      )}
      {open && <Tour frameRef={tourFrameRef} tourUrl={tourLink} />}
    </>
  );
}
