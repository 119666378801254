/* eslint-disable @typescript-eslint/no-non-null-assertion, react/jsx-no-target-blank */
import { UIText2, Button, Checkbox, Input, ModalWindow } from '@cian/ui-kit';
import * as React from 'react';

import { IUserSchema, IUserSubscriptionSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import * as styles from './index.css';
import { TStatusFetch } from '../../store/subscription_price_changes_popup/';
import { isEmail, isFakeEmail } from '../../utils/validate';

export interface ISubscriptionPriceChangesPopupProps {
  error: string;
  isEmailConfirmed: boolean;
  isOpen: boolean;
  offerId: number;
  statusFetch: TStatusFetch;
  subscription: IUserSubscriptionSchema | null;
  user: IUserSchema | null;
  pushSubscribed: boolean;
}

export interface ISubscriptionPriceChangesPopupDispatchProps {
  submitSubscriptionPriceChanges(
    email: string,
    offerId: number,
    isSubscribeNews: boolean,
    isPushSubscribe: boolean,
  ): void;
  submitUnsubscriptionPriceChanges(offerId: number): void;
  subscriptionPriceChangesPopupClose(): void;
}

export type IProps = ISubscriptionPriceChangesPopupProps & ISubscriptionPriceChangesPopupDispatchProps;

export interface ISubscriptionPriceChangesPopupState {
  email: string;
  emailError: string;
  globalError: string;
  isSubscribeNews: boolean;
  isSubscribed: boolean;
  isPushSubscribe: boolean;
}

export class SubscriptionPriceChangesPopup extends React.Component<IProps, ISubscriptionPriceChangesPopupState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      email: '',
      emailError: '',
      globalError: '',
      isPushSubscribe: true,
      isSubscribeNews: true,
      isSubscribed: !!(props.subscription && props.subscription.isSubscribed),
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ISubscriptionPriceChangesPopupProps) {
    if (nextProps.error) {
      this.setState({
        globalError: nextProps.error,
      });
    }

    if (nextProps.isOpen) {
      document.body.style.overflow = 'hidden';

      if (nextProps.user && nextProps.user.isAuthenticated && !this.state.email) {
        this.setState({
          email: nextProps.user.email && !isFakeEmail(nextProps.user.email) ? nextProps.user.email : '',
        });
      }
    } else {
      document.body.style.overflow = '';

      this.setState({
        email: '',
        emailError: '',
        globalError: '',
        isSubscribed: !!(nextProps.subscription && nextProps.subscription.isSubscribed),
      });
    }
  }

  public render() {
    return (
      <ModalWindow
        title={this.state.isSubscribed ? 'Отмена подписки на изменение цены' : 'Подписка на изменение цены'}
        open={this.props.isOpen}
        onClose={this.props.subscriptionPriceChangesPopupClose}
        content={this.state.isSubscribed ? this.renderUnsubscribeContent() : this.renderSubscribeContent()}
      />
    );
  }

  private renderSubscribeContent = () => (
    <div className={styles['content']}>
      {!this.props.isEmailConfirmed && this.state.isSubscribeNews && this.props.statusFetch === 'ok' ? (
        <div className={styles['description']}>
          Активируйте вашу подписку с помощью ссылки из письма, отправленного на адрес
        </div>
      ) : (
        <div className={styles['description']}>Мы пришлем вам письмо, если цена объекта изменится</div>
      )}
      {this.props.statusFetch === 'ok' ? this.renderSuccessResponse() : this.renderForm()}
    </div>
  );

  private renderUnsubscribeContent = () => (
    <div className={styles['content']}>
      <div className={styles['description']}>
        {/* tslint:disable-next-line:no-non-null-assertion */}
        На <strong>{this.props.subscription!.email || 'вашу почту'}</strong> больше не будут приходить письма
      </div>
      {this.props.statusFetch === 'ok' ? this.renderUnsubscribeSuccessResponse() : this.renderUnsubscribeForm()}
    </div>
  );

  private renderUnsubscribeForm = () => (
    <form className={styles['form']} onSubmit={this.handleUnsubscribe}>
      <div className={styles['form_top']}>
        <Button type="submit" theme="fill_primary" size="XS">
          Отписаться
        </Button>
      </div>
    </form>
  );

  private renderUnsubscribeSuccessResponse = () => (
    <div className={styles['success']}>Вы отписались от уведомлений об изменении цены</div>
  );

  private renderSuccessResponse = () => (
    <div className={styles['success']}>
      {this.state.isSubscribeNews && this.props.statusFetch === 'ok' ? (
        <strong>{this.state.email}</strong>
      ) : (
        <div>
          На <strong>{this.state.email}</strong> оформлена подписка на изменение цены
        </div>
      )}
    </div>
  );

  private renderForm = () => (
    <form className={styles['form']} onSubmit={this.handleSubmit}>
      <div className={styles['form_top']}>
        <div>
          <Input
            invalid={!!this.state.emailError}
            onChange={this.changeEmail}
            value={this.state.email}
            placeholder="Ваш email"
            size="XS"
          />
          {Boolean(this.state.emailError || this.state.globalError) && (
            <div className={styles['error']}>
              <UIText2 color="error_100">{this.state.emailError || this.state.globalError}</UIText2>
            </div>
          )}
        </div>
        <div>
          <Button type="submit" theme="fill_primary" size="XS" loading={this.props.statusFetch === 'isFetching'}>
            Подписаться
          </Button>
        </div>
      </div>
      <div className={styles['form_bottom']}>
        <div className={styles['form_bottom_checkbock']}>
          <Checkbox
            label="Хочу получать новости Cian.ru"
            checked={this.state.isSubscribeNews}
            onChange={this.changeSubscribeNews}
          />
        </div>
        {this.props.pushSubscribed && (
          <div className={styles['form_bottom_checkbock']}>
            <Checkbox
              label="Включить push-уведомления"
              checked={this.state.isPushSubscribe}
              onChange={(_: React.ChangeEvent<HTMLInputElement>, value: boolean) =>
                this.setState({ isPushSubscribe: value })
              }
            />
          </div>
        )}
        {this.props.user && !this.props.user.isAuthenticated && (
          <div>
            При подписке вы принимаете условия&nbsp;
            <a href="//www.cian.ru/help/about/rules/" target="_blank">
              Пользовательского соглашения
            </a>{' '}
            и{' '}
            <a href="//www.cian.ru/help/about/36526/" target="_blank">
              Политики конфиденциальности
            </a>
          </div>
        )}
      </div>
    </form>
  );

  private changeEmail = (_: React.ChangeEvent<HTMLInputElement>, email: string) => {
    this.setState({
      email,
      emailError: '',
      globalError: '',
    });
  };

  private changeSubscribeNews = (_: React.ChangeEvent<HTMLInputElement>, isSubscribeNews: boolean) => {
    this.setState({
      isSubscribeNews,
    });
  };

  private handleUnsubscribe = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.props.submitUnsubscriptionPriceChanges(this.props.offerId);
  };

  private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const email = this.state.email.trim();

    if (!email) {
      this.setState({
        emailError: 'Укажите почту',
      });

      return;
    }

    if (!isEmail(email)) {
      this.setState({
        emailError: 'Неправильный формат почты',
      });

      return;
    }

    this.props.submitSubscriptionPriceChanges(
      email,
      this.props.offerId,
      this.state.isSubscribeNews,
      this.state.isPushSubscribe,
    );
  };
}
