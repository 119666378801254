/* eslint-disable react/jsx-no-target-blank */
import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

export interface ILabel {
  invalid: boolean;
}

export const Label: React.FC<React.PropsWithChildren<ILabel>> = ({ invalid, children }) => (
  <label className={styles['wrapper']}>
    {children}
    <UIText2 color={invalid ? 'error_100' : undefined}>
      Отправляя анкету, я даю согласие на обработку моих персональных данных в соответствии с{' '}
      <a className={styles['link']} href="https://www.cian.ru/help/about/36526/" target="_blank">
        политикой конфиденциальности
      </a>
    </UIText2>
  </label>
);
