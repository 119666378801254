import * as React from 'react';
import { useSelector } from 'react-redux';

import { FlatViewOrderInfo } from '../../components/FlatViewOrderInfo';
import { getFlatViewOrderDescription, getIsFlatViewOrderBooked } from '../../selectors';

export function FlatViewOrderInfoContainer() {
  const isVisible = useSelector(getIsFlatViewOrderBooked);
  const description = useSelector(getFlatViewOrderDescription);

  if (!isVisible) {
    return null;
  }

  return <FlatViewOrderInfo description={description} />;
}
