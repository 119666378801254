import { IconCianLogo16, Label, Tooltip, UIHeading4, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './UniqueForCianBadge.css';

export const UniqueForCianBadge: React.FC = () => (
  <Tooltip
    placement="bottom"
    theme="white"
    title={
      <div className={styles['content']}>
        <UIHeading4>Только на Циан</UIHeading4>
        <UIText2>
          За последние сутки мы не нашли этот объект
          <br />в открытом доступе на других агрегаторах
        </UIText2>
      </div>
    }
  >
    <Label background="primary_10" color="primary_100" icon={<IconCianLogo16 color="primary_100" />}>
      Только на Циан
    </Label>
  </Tooltip>
);

UniqueForCianBadge.displayName = 'UniqueForCianBadge';
