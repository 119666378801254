import * as React from 'react';

import * as styles from './styles.css';
import { Tab } from './tab';
import { TABS } from '../../../../constants/mapTabs';
import { ECategory } from '../../../../repositories/infrastructure-caching/entities/infrastructure/CategoryItemsSchema';
import { getIconComponent } from '../icons';

export interface IInfrastructureTabsProps {
  selected: ECategory[];
  onSelect(type: ECategory): void;
  className?: string;
}

export class InfrastructureTabs extends React.Component<IInfrastructureTabsProps> {
  private tabs = TABS.map(tab => ({
    ...tab,
    toggle: () => {
      this.props.onSelect(tab.category);
    },
  }));

  public render() {
    return (
      <div className={this.props.className}>
        {this.tabs.map(tab => {
          const { category } = tab;
          const Icon = getIconComponent(category);

          return (
            <Tab
              key={category}
              onClick={tab.toggle}
              className={styles['tab']}
              active={this.props.selected.includes(category)}
            >
              {Icon && <Icon className={styles['icon']} />}
              {tab.title}
            </Tab>
          );
        })}
      </div>
    );
  }
}
