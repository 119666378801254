import { ContactFeatures, TContactCardTheme } from '@cian/contact-card-component';
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { SuperAgentLevelViewContainer } from 'shared/containers/SuperAgentLevelViewContainer';
import { useFormattedPublisherType } from 'shared/hooks/selectors/useAgentSelector';
import { EBrandingLevel } from 'shared/types/offerData';

import { AgencyBrandingAsideCard, AgencyLabels } from '../../../components/AgencyBrandingAsideCard';
import { selectAgencyFeatures, selectAgencyName, selectBrandingLevel } from '../../../selectors';
import { getMasterOrSelfAvatar, getMasterOrSelfLink } from '../../../selectors/agentSelectors';
import { selectIsSubAgent } from '../../../selectors/offerData/agent';
import { selectIsAgencyBrandingAvailable } from '../../../selectors/offerData/selectIsAgencyBrandingAvailable';
import { trackAgencyBrandingCardClick } from '../../../utils/analytics';
import { selectHasVerifiedDocuments } from '../../ApprovalContainer/selectors/selectHasVerifiedDocuments';
import { AgencyBrandingAgentCardContainer } from '../AgencyBrandingAgentCard';

export const AgencyBrandingAsideCardContainer = () => {
  const isSubAgent = useSelector(selectIsSubAgent, shallowEqual);
  const brandingLevel = useSelector(selectBrandingLevel);
  const agencyFeatures = useSelector(selectAgencyFeatures, shallowEqual);
  const agencyName = useSelector(selectAgencyName);
  const agencyLink = useSelector(getMasterOrSelfLink);
  const agencyAvatar = useSelector(getMasterOrSelfAvatar);
  const hasVerifiedDocuments = useSelector(selectHasVerifiedDocuments);
  const isBrandingAvailable = useSelector(selectIsAgencyBrandingAvailable);

  const handleAgencyNameClick = React.useCallback(() => {
    trackAgencyBrandingCardClick('name');
  }, []);

  const title = useFormattedPublisherType('Агентство недвижимости');

  if (!isBrandingAvailable) {
    return null;
  }

  const theme: TContactCardTheme = brandingLevel === EBrandingLevel.ThirdLevel ? 'premium' : 'default';

  return (
    <AgencyBrandingAsideCard
      theme={theme}
      agencyAvatar={agencyAvatar}
      onAgencyNameClick={handleAgencyNameClick}
      agencyName={agencyName}
      agencyLink={agencyLink || ''}
      agencyLabels={hasVerifiedDocuments && <AgencyLabels />}
      agencyFeaturesElement={!!agencyFeatures.length && <ContactFeatures features={agencyFeatures} />}
      agentElement={isSubAgent && <AgencyBrandingAgentCardContainer />}
      superAgentElement={!isSubAgent && <SuperAgentLevelViewContainer />}
      title={title}
    />
  );
};
