import { IconArrowUpRight16, UIHeading1, UIHeading5 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

export interface IAuthorFactProps {
  value: number | string;
  text: string;
  testId: string;
  link?: string | null;
  onClick?(): void;
}

export const AuthorFact = (props: IAuthorFactProps) => {
  const { testId, text, value, link, onClick } = props;

  const content = (text: string, hasArrow: boolean) => (
    <div className={styles['container']} data-testid="AuthorFactContent">
      <UIHeading1 as="h4" color="current_color">
        {value}
      </UIHeading1>

      <div className={styles['text']}>
        <UIHeading5 as="h5" color="current_color">
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: text }}
          ></span>

          {hasArrow && (
            <div className={styles['arrow']}>
              <IconArrowUpRight16 color="primary_100" />
            </div>
          )}
        </UIHeading5>
      </div>
    </div>
  );

  return (
    <>
      {link && (
        <a
          data-testid={testId}
          className={styles['link']}
          href={link}
          target="blank"
          rel="noreferrer"
          onClick={onClick}
        >
          {content(text, true)}
        </a>
      )}

      {!link && <div data-testid={testId}>{content(text, false)}</div>}
    </>
  );
};
