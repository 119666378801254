import { pathOr } from 'ramda';

import { IPhoneSchema } from '../repositories/offer-card/entities/offer/PhoneSchema';
import { IState } from '../store';

export const getUserPhone = (state: IState): string | null => {
  return pathOr(null, ['offerData', 'user', 'phones', 0, 'phone'], state);
};

export const getAgentPhone = (state: IState): IPhoneSchema | null => {
  const phone = pathOr(null, ['offerData', 'offer', 'phones', 0], state);

  return phone;
};
