import * as React from 'react';
import { useSelector } from 'react-redux';

import { PrintMap } from './print_map';
import { IState } from '../../store';

export const PrintMapContainer: React.FC = () => {
  const coordinates = useSelector<
    IState,
    {
      lng: number;
      lat: number;
    }
  >(state => state.offerData.offer.geo.coordinates);
  const isPrintModeEnabled = useSelector<IState, boolean>(state => state.printModal.isPrintModeEnabled);

  return <PrintMap coordinates={coordinates} isPrintModeEnabled={isPrintModeEnabled} />;
};
