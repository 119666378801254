import {
  IconMetroSpb16,
  IconMetroEkb16,
  IconMetroKzn16,
  IconMetroNovosib16,
  IconMetroSam16,
  IconMetroNN16,
  IconMetroMsk16,
} from '@cian/ui-kit';
import { IIconProps } from '@cian/ui-kit/icons/internal';
import React from 'react';

import { TLineType } from 'shared/types/geo/underground';

import { IconMapMCD116, IconMapMCD216, IconMapMCD316, IconMapMCD416, IconMapMCD516 } from '../icons';

const METRO_ICONS_BY_REGION: Partial<Record<number, React.ComponentType<IIconProps>>> = {
  // Мск и область
  '-1': IconMetroMsk16,
  1: IconMetroMsk16,
  4593: IconMetroMsk16,
  // СПб и область
  '-2': IconMetroSpb16,
  2: IconMetroSpb16,
  4588: IconMetroSpb16,
  // Екб и Свердловская область
  4612: IconMetroEkb16,
  4743: IconMetroEkb16,
  // Казать и Татарстан
  4618: IconMetroKzn16,
  4777: IconMetroKzn16,
  // Новосиб и Новосиб область
  4598: IconMetroNovosib16,
  4897: IconMetroNovosib16,
  // Самара и Самарская область
  4608: IconMetroSam16,
  4966: IconMetroSam16,
  // Нижний Новгород и Нижегородская область
  4596: IconMetroNN16,
  4885: IconMetroNN16,
};

const MCD_ICONS_BY_LINE_TYPE: Partial<Record<string, React.ComponentType<IIconProps>>> = {
  mcd1: IconMapMCD116,
  mcd2: IconMapMCD216,
  mcd3: IconMapMCD316,
  mcd4: IconMapMCD416,
  mcd5: IconMapMCD516,
};

interface IParams {
  lineType?: TLineType;
  regionId: number;
}

export const getUndergroundIcon = ({ lineType, regionId }: IParams) => {
  const mcdIcon = lineType ? MCD_ICONS_BY_LINE_TYPE[lineType] : undefined;
  const metroIcon = METRO_ICONS_BY_REGION[regionId];

  return mcdIcon || metroIcon || IconMetroMsk16;
};
