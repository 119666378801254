import { Tooltip, UIText3 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './CoworkingProfitBanner.css';
import { TooltipContent } from './internal/components';
import { ICoworkingProfitBannerProps } from './types';

export const CoworkingProfitBanner: React.FC<ICoworkingProfitBannerProps> = props => {
  const { containerClassName, isModernButton } = props;

  return (
    <div className={containerClassName}>
      <Tooltip placement="bottom" theme="white" title={<TooltipContent />}>
        <button className={cx(isModernButton ? styles['modern-button'] : styles['button'])}>
          <UIText3 color={isModernButton ? 'fill_error_primary_hover_100_' : 'white_100'}>Почему это выгодно?</UIText3>
        </button>
      </Tooltip>
    </div>
  );
};

CoworkingProfitBanner.displayName = 'CoworkingProfitBanner';
