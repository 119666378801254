import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './ComparisonNotification.css';

interface IComparisonNotificationProps {
  text: string;
  control: React.ReactNode;
  onMouseEnter(): void;
}

export const ComparisonNotification: React.FC<React.PropsWithChildren<IComparisonNotificationProps>> = ({
  text,
  control,
  onMouseEnter,
}) => {
  return (
    <div className={styles['container']} onMouseEnter={onMouseEnter}>
      <div className={styles['notification']}>
        <UIText2 whiteSpace="pre-line" color="white_100">
          {text}
        </UIText2>
        <div className={styles['control']}>{control}</div>
      </div>
    </div>
  );
};
