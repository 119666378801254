import { connect } from 'react-redux';

import { DealRentFailureModal } from './DealRentFailureModal';
import { IState } from '../../store';
import { closeFailure } from '../../store/deal-rent';

export function mapStateToProps(state: IState) {
  return {
    isOpen: state.dealRent.isFailureModal,
  };
}

export const DealRentFailureModalContainer = connect(mapStateToProps, { closeModal: closeFailure })(
  DealRentFailureModal,
);
