import * as React from 'react';
import { useSelector } from 'react-redux';

import { DealRentNotice } from '../../components/DealRentNotice';
import { isDealRent } from '../../selectors/isDealRent';

export const DealRentNoticeContainer: React.FC = () => {
  const isDealRentOffer = useSelector(isDealRent);

  if (!isDealRentOffer) {
    return null;
  }

  return <DealRentNotice />;
};
