import { Button } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './LoginMotivationTooltip.css';

export interface ILoginMotivationTooltipProps {
  isFullscreenGallery?: boolean;
  isMotivationTooltipShows: boolean;
  onHideMotivationTooltipClick(): void;
  onLoginButtonClick(): void;
  onRegisterButtonClick(): void;
}

export const LoginMotivationTooltip = ({
  isFullscreenGallery,
  isMotivationTooltipShows,
  onHideMotivationTooltipClick,
  onLoginButtonClick,
  onRegisterButtonClick,
}: ILoginMotivationTooltipProps) => {
  return (
    <div
      className={cx(
        styles['tooltip'],
        isFullscreenGallery && styles['tooltip--fullscreen'],
        isMotivationTooltipShows && styles['tooltip--visible'],
      )}
    >
      <div className={styles['tooltip-container']}>
        <button className={styles['tooltip-close']} onClick={onHideMotivationTooltipClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
            {/* tslint:disable-next-line:max-line-length */}
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M2.193.78C2.113.7 1.99.696 1.91.776L.776 1.91c-.078.077-.07.21.002.283L5.585 7 .78 11.807c-.08.08-.083.204-.003.284l1.132 1.134c.076.078.21.07.282-.002L7 8.415l4.807 4.807c.08.08.204.083.284.003l1.134-1.132c.078-.077.07-.21-.002-.283L8.415 7l4.807-4.807c.08-.08.083-.204.003-.284L12.09.775c-.077-.078-.21-.07-.283.002L7 5.585 2.193.78z"
            />
          </svg>
        </button>
        <strong className={styles['tooltip-header']}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
            {/* tslint:disable-next-line:max-line-length */}
            <path
              fill="#2e9e00"
              d="M14.073 2.8L7.8 9.073l-1.927-2.2a1.094 1.094 0 1 0-1.637 1.454l2.71 3.073c.2.218.49.364.781.364h.037c.29 0 .563-.11.763-.328l7.091-7.09a1.11 1.11 0 0 0 0-1.546 1.109 1.109 0 0 0-1.545 0z"
            />
            {/* tslint:disable-next-line:max-line-length */}
            <path
              fill="#2e9e00"
              d="M14.91 6.91c-.6 0-1.092.49-1.092 1.09 0 .255-.018.51-.054.764-.364 2.872-2.855 5.054-5.764 5.054-3.2 0-5.818-2.618-5.818-5.818C2.182 4.8 4.8 2.182 8 2.182c1.036 0 2.055.273 2.927.8a1.091 1.091 0 0 0 1.491-.382 1.091 1.091 0 0 0-.382-1.49A7.887 7.887 0 0 0 8 0a8 8 0 0 0 0 16c4 0 7.418-2.982 7.927-6.964A9.73 9.73 0 0 0 16 8c0-.6-.49-1.09-1.09-1.09z"
            />
          </svg>
          Добавлено в избранное
        </strong>
        <div className={styles['tooltip-content']}>
          <p className={styles['tooltip-text']}>Войдите для просмотра избранного с любого устройства</p>
          <div className={styles['tooltip-buttons']}>
            <Button size="XS" onClick={onLoginButtonClick}>
              Войти
            </Button>

            <div className={styles['tooltip-button_register']}>
              <Button size="XS" theme="fill_secondary" onClick={onRegisterButtonClick}>
                Зарегистрироваться
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
