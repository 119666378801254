import { connect } from 'react-redux';

import { fixedWithListenWindowScroll } from './highOrderComponents/fixedWithListenWindowScroll';
import { offerCardNavigation } from './highOrderComponents/offerCardNavigation';
import { IOfferCardNavigationStoreProps, IOfferCardNavigationDispatchProps, IProps } from './types';
import { Navigation } from '../../components/NavigationNew';
import { offerSelector, similarOffersSelector } from '../../selectors';
import { selectAsideActiveAnchor, selectAsideNavigationActive } from '../../selectors/aside';
import { selectIsBottomAgentBlockAvailable } from '../../selectors/offerData/selectIsBottomAgentBlockAvailable';
import { DispatchReduxAction, IState } from '../../store';
import { changeNavigationAnchor, toggle as toggleNavigation } from '../../store/navigation';
import { itemBasedOffersVisible } from '../../utils/similarOffersVisible';

export const mapStateToProps = (state: IState): IOfferCardNavigationStoreProps => ({
  isActive: selectAsideNavigationActive(state),
  activeAnchor: selectAsideActiveAnchor(state),
  isBottomAgentBlockAvailable: selectIsBottomAgentBlockAvailable(state),
  isSimilarOffersAvailable:
    offerSelector(state).status === 'published' && itemBasedOffersVisible(similarOffersSelector(state)),
  offer: offerSelector(state),
});

export const mapDispatchToProps = (dispatch: DispatchReduxAction): IOfferCardNavigationDispatchProps => ({
  toggleNavigation: (isActive: boolean) => dispatch(toggleNavigation(isActive)),
  changeNavigationAnchor: (anchor: string) => dispatch(changeNavigationAnchor(anchor)),
});

export const mergeProps = (
  stateProps: IOfferCardNavigationStoreProps,
  dispatchProps: IOfferCardNavigationDispatchProps,
  ownProps: IProps,
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export const NavigationContainerNew = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(fixedWithListenWindowScroll(offerCardNavigation(Navigation)));
