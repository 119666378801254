import { IconDocumentsVerified16, UIText1, UIText3 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export interface IAgentProofsTitleProps {
  title: string;
  size?: 'small' | 'medium';
}

export const AgentProofsTitle: React.FC<React.PropsWithChildren<IAgentProofsTitleProps>> = ({
  title,
  size = 'small',
}) => {
  return (
    <div className={style['agent-proofs-title']}>
      <IconDocumentsVerified16 color="primary_100" />
      {size === 'medium' && <UIText1>{title}</UIText1>}
      {size === 'small' && <UIText3>{title}</UIText3>}
    </div>
  );
};
