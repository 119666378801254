import { Button, OnboardingTooltip } from '@cian/ui-kit';
import { TButtonSize } from '@cian/ui-kit/button/types';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PopupOwnOfferContainer } from 'shared/components/popups/own_offer/container';
import { usePushToCall } from 'shared/containers/AsideContacts/hooks/usePushToCall';
import { useSimpleBotDetector } from 'shared/hooks/useSimpleBotDetector';
import { selectIsChatsEnabled } from 'shared/selectors/offerChat';
import { isNewbuildingFromDeveloperSelector } from 'shared/selectors/offerData/offer/isNewbuildingFromDeveloperSelector';
import { isPrintVersionPhoneVisibilitySelector } from 'shared/selectors/print';
import { setNotAvailablePopupOpenValue } from 'shared/store/notAvailablePopupOpen';
import { getPhoneButtonText } from 'shared/utils/getPhoneButtonText';

import { PhonesOpened } from './PhonesOpened';
import { usePhonesOpenedContext } from './providers/PhonesOpened';
import { trackShowPhoneClick } from './tracking';
import {
  getAgentAvailability,
  getPhones,
  getVillageId,
  selectIsFromSuburbanBuilder,
  selectIsOfferPublished,
  selectOffer,
  selectUser,
} from '../../selectors';
import { getDynamicCalltrackingSiteBlockId } from '../../selectors/getDynamicCalltrackingSiteBlockId';
import { isRenterFormEnabled } from '../../selectors/isRenterFormEnabled';
import { getRosreestrInfo } from '../../selectors/offerData/getRosreestrInfo';
import { selectAgent } from '../../selectors/selectAgent';
import { prefetchFormState } from '../../store/deal-rent';
import { setPhoneCollapse } from '../../store/phone_collapse';
import { useApplicationContext } from '../ApplicationContext';

interface IPhonesContainerProps {
  size?: TButtonSize;
  trackingAction?: string;
}

export const PhonesContainer: React.FC<React.PropsWithChildren<IPhonesContainerProps>> = ({
  size = 'M',
  trackingAction,
}) => {
  const { isPhonesOpened, setPhonesOpened } = usePhonesOpenedContext();
  const dispatch = useDispatch();
  const { logger } = useApplicationContext();
  const isPublished = useSelector(selectIsOfferPublished);
  const offer = useSelector(selectOffer);
  const phones = useSelector(getPhones);
  const isChatsEnabled = useSelector(selectIsChatsEnabled);
  const isDealRentFormEnabled = useSelector(isRenterFormEnabled);
  const agent = useSelector(selectAgent);
  const agentAvailability = useSelector(getAgentAvailability);
  const user = useSelector(selectUser);
  const siteBlockId = useSelector(getDynamicCalltrackingSiteBlockId);
  const rosreestrInfo = useSelector(getRosreestrInfo);
  const villageId = useSelector(getVillageId);
  const isNewbuildingFromBuilder = useSelector(isNewbuildingFromDeveloperSelector);
  const isSuburbanFromBuilder = useSelector(selectIsFromSuburbanBuilder);
  const buttonContent = getPhoneButtonText({
    isDealRent: isDealRentFormEnabled,
    isNewbuildingFromBuilder,
    isSuburbanFromBuilder,
  });
  const isPrintVersion = useSelector(isPrintVersionPhoneVisibilitySelector);
  const botDetector = useSimpleBotDetector();
  const isAgentNotAvailable = agentAvailability && !agentAvailability.available;
  const [isOwnOfferPopupOpen, setOwnOfferPopupOpen] = React.useState(false);

  const startChat = () => {
    const isOfferOwnedByUser = Boolean(user && user.isAuthenticated && user.realtyUserId === offer.userId);
    const canShowAvailability = isChatsEnabled && isAgentNotAvailable;

    if (!canShowAvailability) {
      return;
    }

    switch (true) {
      case isOfferOwnedByUser:
        return setOwnOfferPopupOpen(true);
      default:
        dispatch(setNotAvailablePopupOpenValue(true));
    }
  };

  const ownOfferPopupClose = () => setOwnOfferPopupOpen(false);

  const onShowPhoneClick = () => {
    if (isDealRentFormEnabled) {
      dispatch(prefetchFormState());

      return;
    }

    setPhonesOpened(true);
    dispatch(setPhoneCollapse());
  };

  const trackShowPhone = () => {
    const isAgentNotAvailable = agentAvailability && !agentAvailability.available;

    trackShowPhoneClick({
      trackingAction,
      offer,
      agent,
      user,
      kpId: villageId,
      bot: !botDetector.isHuman(),
      isAgentNotAvailable,
      siteBlockId,
      rosreestrInfo,
    });
  };

  const handleButtonClick: VoidFunction = () => {
    trackShowPhone();
    onShowPhoneClick();
    startChat();
  };

  const { isTooltipVisible, onTooltipClose } = usePushToCall();

  if (!isPublished) {
    return null;
  }

  if (!phones.length || phones.some(phone => !phone.countryCode || !phone.number)) {
    logger.error(`Offer phones is null`, { cianId: offer.cianId });

    return null;
  }

  return (
    <div>
      {(isPhonesOpened || isPrintVersion) && <PhonesOpened />}
      {!isPhonesOpened && !isPrintVersion && (
        <OnboardingTooltip
          content="Позвоните напрямую застройщику, без участия посредников"
          placement="left"
          theme="blue"
          open={isTooltipVisible}
          portal={false}
          onClose={onTooltipClose}
        >
          <Button data-testid="contacts-button" theme="fill_primary" size={size} onClick={handleButtonClick} fullWidth>
            {buttonContent}
          </Button>
        </OnboardingTooltip>
      )}
      <PopupOwnOfferContainer isOpen={isOwnOfferPopupOpen} onClose={ownOfferPopupClose} />
    </div>
  );
};
