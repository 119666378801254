import { Button, IconEdit16 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

interface ICommentsButtonNewProps {
  commented: boolean;
  onClick(): void;
}

export function CommentsButtonNew({ commented, onClick }: ICommentsButtonNewProps) {
  return (
    <div className={cx(styles['container'], commented && styles['container--opened'])}>
      <Button
        theme="stroke_secondary"
        beforeIcon={<IconEdit16 color="gray_icons_100" />}
        onClick={onClick}
        size="XS"
        title="Комментарий"
      />
    </div>
  );
}
