import { makeContextModule } from '@cian/react-utils';
import React, { FC, JSX, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectIsNewbuildingPremiumRate } from 'shared/selectors/newbuildingPremiumRate';
import { IPParams } from 'shared/types/adfox';

import { useAdfoxPuids } from './internal/hooks/useAdfoxPuids';

import type { TAdfoxContextData } from './types';

const contextData = makeContextModule<TAdfoxContextData>({ contextName: 'AdfoxContextData' });

const {
  Context: { Provider },
} = contextData;

export const { useContext: useAdfoxContext, withContext: withAdfoxContext, Context: AdfoxContext } = contextData;

export type TAdfoxContextProviderProps = Omit<TAdfoxContextData, 'params' | 'isHidden'> & {
  children: JSX.Element | JSX.Element[];
  pParams: IPParams;
};

export const AdfoxContextProvider: FC<TAdfoxContextProviderProps> = props => {
  const { children, pParams, size, postViewAnalyticsData, bannerPlace } = props;

  const params = useAdfoxPuids(pParams);

  const isPremiumNewbuilding = useSelector(selectIsNewbuildingPremiumRate);

  const contextValue = useMemo<TAdfoxContextData>(
    (): TAdfoxContextData => ({
      params,
      size,
      postViewAnalyticsData,
      bannerPlace,
      isHidden: isPremiumNewbuilding,
    }),
    [bannerPlace, isPremiumNewbuilding, params, postViewAnalyticsData, size],
  );

  return <Provider value={contextValue}>{children}</Provider>;
};
