import * as React from 'react';

import { trackModalOpen } from './tracking';
import { FloatingLabel } from '../../../../components/FloatingLabel';
import { PriceQualityBar } from '../../../../components/OfferValuation';
import { usePricesBlockData } from '../../utils/usePricesBlockData';

export function GoodPriceLabelNewContainer() {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const pricesData = usePricesBlockData();
  const handleTooltipOpen = React.useCallback(() => {
    /**
     * Отправляем события показа модалки только 1 раз
     */
    if (isModalOpen) {
      return;
    }
    trackModalOpen();
    setModalOpen(true);
  }, [isModalOpen]);

  if (!pricesData?.priceTagInfo) {
    return null;
  }

  const { priceTagInfo, estimationRange } = pricesData;

  return (
    <FloatingLabel
      labelText="Хорошая цена"
      tooltipTitle="Почему цена хорошая"
      tooltipText={`Хорошей мы считаем цену, которая попадает в диапазон оценки Циан. Для этой квартиры диапазон: ${estimationRange}`}
      color="primary_100"
      backgroundColor="primary_10"
      onOpen={handleTooltipOpen}
      extendTooltipContent={
        <PriceQualityBar
          offerPrice={priceTagInfo.offerPriceShort}
          estimationLowerBound={priceTagInfo.estimationLowerBoundShort}
          estimationUpperBound={priceTagInfo.estimationUpperBoundShort}
          labelPos={priceTagInfo.offerPricePercentage}
        />
      }
    />
  );
}
