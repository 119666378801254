import * as React from 'react';
import { useSelector } from 'react-redux';

import { INewbuildingPremiumBuilderStats, INewbuildingPremiumBuilderLogoProps } from 'shared/components/AuthorAsideNew';
import { selectOfferId } from 'shared/selectors';
import { selectDeveloperCardEnabled } from 'shared/selectors/newbuilding';
import { selectBuilderInfo, selectBuilderStats } from 'shared/selectors/newbuildingPremiumRate';
import { getNewbuildingsListQueryStringUrl } from 'shared/utils/formatters';
import { pluralize } from 'shared/utils/pluralize';

import { TLabel, trackBuilderLogoClick, trackBuilderStatClick } from '../tracking';

export interface IBuilderPropsResponse {
  builderProps: INewbuildingPremiumBuilderLogoProps | null;
  builderStats: INewbuildingPremiumBuilderStats[];
  builderLabel: boolean;
}

export const usePremiumBuilderProps = (): IBuilderPropsResponse => {
  const builder = useSelector(selectBuilderInfo);
  const stats = useSelector(selectBuilderStats);
  const offerId = useSelector(selectOfferId);
  const isDevCardRedesignEnabled = useSelector(selectDeveloperCardEnabled);

  const onBuilderLogoClick = React.useCallback(() => {
    trackBuilderLogoClick({ offerId, builderId: builder?.id });
  }, [builder, offerId]);

  const onBuilderFactClick = React.useCallback(
    (label: TLabel) => {
      trackBuilderStatClick({ offerId, label });
    },
    [offerId],
  );

  const builderProps = React.useMemo(() => {
    return builder
      ? ({
          logoUrl: builder?.logoUrl,
          builderName: builder?.name,
          url: builder?.url,
          onClick: onBuilderLogoClick,
        } as INewbuildingPremiumBuilderLogoProps)
      : null;
  }, [builder, onBuilderLogoClick]);

  const builderLabel = React.useMemo(() => {
    return !!builder?.isReliable;
  }, [builder]);

  const builderStats: INewbuildingPremiumBuilderStats[] = React.useMemo(() => {
    const builderStats: INewbuildingPremiumBuilderStats[] = [];

    const yearFoundation = builder?.yearFoundation;
    if (yearFoundation) {
      builderStats.push({
        testId: 'year-foundation-stat',
        title: 'Год основания',
        value: `${yearFoundation}`,
        isAvailableForCompactMode: false,
      });
    }

    if ((!isDevCardRedesignEnabled || !builder?.housesBuiltInTime) && stats && stats.newbuildingCount) {
      builderStats.push({
        testId: 'newbuilding-count-stat',
        title: 'Жилых комплексов',
        value: `${stats.newbuildingCount.value}`,
        link: getNewbuildingsListQueryStringUrl(stats.newbuildingCount.qs),
        onClick: () => onBuilderFactClick('floating_block_JK'),
        isAvailableForCompactMode: false,
      });
    }

    if (
      stats &&
      stats.housesInProgress &&
      stats.housesInProgress.housesCount &&
      stats.housesInProgress.newbuildingCount
    ) {
      builderStats.push({
        testId: 'houses-in-progress-stat',
        title: 'Домов строится',
        value: `${pluralize(stats.housesInProgress.housesCount, ['дом', 'дома', 'домов'], false)} в ${
          stats.housesInProgress.newbuildingCount
        } ЖК`,
        link: getNewbuildingsListQueryStringUrl(stats.housesInProgress.qs),
        onClick: () => onBuilderFactClick('floating_block_construction_JK'),
        isAvailableForCompactMode: true,
      });
    }

    if (stats && stats.housesDone && stats.housesDone.housesCount && stats.housesDone.newbuildingCount) {
      builderStats.push({
        testId: 'houses-done-stat',
        title: 'Сдано',
        value: `${pluralize(stats.housesDone.housesCount, ['дом', 'дома', 'домов'], false)} в ${
          stats.housesDone.newbuildingCount
        } ЖК`,
        link: getNewbuildingsListQueryStringUrl(stats.housesDone.qs),
        onClick: () => onBuilderFactClick('floating_block_ready_JK'),
        isAvailableForCompactMode: true,
      });
    }

    if (isDevCardRedesignEnabled && builder?.housesBuiltInTime) {
      builderStats.push({
        testId: 'built-in-time-stat',
        title: 'Сдано вовремя',
        value: builder?.housesBuiltInTime,
        isAvailableForCompactMode: true,
      });
    }

    return builderStats;
  }, [builder?.yearFoundation, builder?.housesBuiltInTime, isDevCardRedesignEnabled, stats, onBuilderFactClick]);

  return React.useMemo<IBuilderPropsResponse>(() => {
    return {
      builderProps,
      builderLabel,
      builderStats,
    };
  }, [builderProps, builderLabel, builderStats]);
};
