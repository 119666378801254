import * as React from 'react';
import { useSelector } from 'react-redux';

import { QuickReplyStatus } from 'shared/components/QuickReplyStatus';
import { selectOffer } from 'shared/selectors';
import { offerChatSelector } from 'shared/selectors/offerChat';
import { selectIsHighResponsibility } from 'shared/selectors/offerData/selectIsHighResponsibility';
import { selectAgent } from 'shared/selectors/selectAgent';

import { trackOnlineStatusShown } from './tracking';
import { OnlineStatus } from '../../components/OnlineStatus';

export const OnlineStatusContainer: React.FC = () => {
  const { isChatsEnabled, isOnline } = useSelector(offerChatSelector);
  const offer = useSelector(selectOffer);
  const agent = useSelector(selectAgent);
  const isHighResponsibility = useSelector(selectIsHighResponsibility);

  const onShow = React.useCallback(() => trackOnlineStatusShown(offer, agent), [offer, agent]);

  if (isChatsEnabled && isHighResponsibility && !isOnline) {
    return <QuickReplyStatus />;
  }

  if (!isChatsEnabled || !isOnline) {
    return null;
  }

  return <OnlineStatus onShow={onShow} />;
};
