import { withFormik, FormikBag } from 'formik';
import { connect } from 'react-redux';
import { object, number, string, boolean, array, StringSchema } from 'yup';

import { DealRentModal, IFormValues, IDealRentModalStateProps, IDealRentModalDispathProps } from './DealRentModal';
import { dealVersionSelector } from '../../selectors/offerData/offer/dealVersion';
import { IState } from '../../store';
import { close, saveForm, sendValidationCode, changePhoneConfirmed } from '../../store/deal-rent';

export const mapStateToProps = (state: IState): IDealRentModalStateProps => ({
  isOpen: state.dealRent.isOpen,
  error: state.dealRent.error,
  isLoading: state.dealRent.isLoading,
  initialState: state.dealRent.initialFormState,
  secondsToRetry: state.dealRent.secondsToRetry,
  phoneConfirmed: state.dealRent.phoneConfirmed,
  isSendSMSLoading: state.dealRent.isSendSMSLoading,
  freeDealRent: state.dealRentVasCost === 0,
  dealVersion: dealVersionSelector(state),
});

export const handleSubmit = (
  values: IFormValues,
  { setSubmitting, props: { saveForm } }: FormikBag<IDealRentModalStateProps & IDealRentModalDispathProps, IFormValues>,
) => {
  setSubmitting(false);
  saveForm({ ...values, phone: `+7${values.phone}` });
};

export const mapPropsToValues = ({
  initialState,
}: IDealRentModalStateProps & IDealRentModalDispathProps): IFormValues => ({
  ...initialState,
  termAccepted: false,
});

export const personsDescriptionValidator = (personsCount: number, schema: StringSchema) =>
  personsCount > 1 ? schema.required() : schema.nullable();
export const petsInfoValidator = (petsCount: number, schema: StringSchema) =>
  petsCount > 0 ? schema.required() : schema.nullable();

export const DealRentModalWithFormik = withFormik<IDealRentModalStateProps & IDealRentModalDispathProps, IFormValues>({
  mapPropsToValues,
  validationSchema: object({
    personsCount: number().min(1).required(),
    personsInfo: string().when('personsCount', personsDescriptionValidator),
    childrenCount: number().min(0).required(),
    childrenInfo: array().of(string().required()),
    petsCount: number().min(0).required(),
    petsInfo: string().when('petsCount', petsInfoValidator),
    comment: string(),
    activityScope: string().required(),
    name: string().required(),
    isAgent: boolean().required(),
    phone: string()
      .matches(/^\d( ?-?\(?\)?\d){9}$/, { message: 'Некорректный номер телефона', excludeEmptyString: false })
      .required(),
    code: string(),
    termAccepted: boolean()
      .test('termAccepted', 'Необходимо согласиться с политикой конфиденциальности', Boolean)
      .required(),
  }),
  handleSubmit,
  enableReinitialize: true,
})(DealRentModal);

export const DealRentModalContainer = connect(mapStateToProps, {
  closeModal: close,
  saveForm,
  sendValidationCode,
  changePhoneConfirmed,
})(DealRentModalWithFormik);
