import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingContactsContainerLoadable } from 'shared/components/contacts_aside';
import { DailyrentActionButtonsContainer } from 'shared/containers/AsideContacts/DailyrentActionButtonsContainer';
import { ElectronicTradingContactButtonContainer } from 'shared/containers/ElectronicTradingContactButtonContainer';
import { NewbuildingOnlineStatusContainer } from 'shared/containers/NewbuildingOnlineStatusContainer';
import { useIsElectronicTradingOffer } from 'shared/hooks/useIsElectronicTradingOffer';
import { isNewbuildingSelector, selectIsOfferPublished } from 'shared/selectors';
import { selectShouldRenderContacts } from 'shared/selectors/contacts/selectShouldRenderContacts';
import { selectorEntryToShowEnabled } from 'shared/selectors/contacts/selectorEntryToShowEnabled';
import { isRentDailySelector } from 'shared/selectors/isRentDaily';
import { isPrintVersionPhoneVisibilitySelector } from 'shared/selectors/print';

import { PhonesContainer } from './PhonesContainer';
import { SendMessageButtonContainer } from './SendMessageButton';
import { OnlineStatusContainer } from '../OnlineStatusContainer';

export const AsideContactsContainer = () => {
  const shouldRenderContactButtons = useSelector(selectShouldRenderContacts);
  const isPublished = useSelector(selectIsOfferPublished);
  const entryToShowEnabled = useSelector(selectorEntryToShowEnabled);
  const isNewbuilding = useSelector(isNewbuildingSelector);
  const isPrintVersion = useSelector(isPrintVersionPhoneVisibilitySelector);
  const isElectronicTradingOffer = useIsElectronicTradingOffer();

  const isDailyrent = useSelector(isRentDailySelector);

  if (!isPublished || entryToShowEnabled) {
    return null;
  }

  if (isDailyrent) {
    return <DailyrentActionButtonsContainer />;
  }

  return (
    <>
      {isNewbuilding && !isPrintVersion && (
        <NewbuildingContactsContainerLoadable
          contactsButton={
            isElectronicTradingOffer ? (
              <ElectronicTradingContactButtonContainer buttonFullWidth dataTestId="electronic-trading-button-aside" />
            ) : (
              <PhonesContainer />
            )
          }
          callbackPlaceType="floating_block"
        />
      )}
      {!isElectronicTradingOffer && !isNewbuilding && shouldRenderContactButtons && <PhonesContainer />}
      {isElectronicTradingOffer && !isNewbuilding && (
        <ElectronicTradingContactButtonContainer buttonFullWidth dataTestId="electronic-trading-button-aside" />
      )}
      {shouldRenderContactButtons && <SendMessageButtonContainer />}
      <OnlineStatusContainer />
      {isNewbuilding && <NewbuildingOnlineStatusContainer />}
    </>
  );
};
