import * as React from 'react';
import { connect } from 'react-redux';

import { DealRentButton, IDealRentButtonProps } from './DealRentButton';
import { isRenterFormEnabled } from '../../selectors/isRenterFormEnabled';
import { IState } from '../../store';
import { prefetchFormState } from '../../store/deal-rent';

export interface IDealRentButtonWrapperProps extends IDealRentButtonProps {
  isDealRentFormEnabled: boolean;
}

export const DealRentButtonWrapper: React.FC<React.PropsWithChildren<IDealRentButtonWrapperProps>> = ({
  isDealRentFormEnabled,
  ...props
}) => {
  if (isDealRentFormEnabled) {
    return null;
  }

  return <DealRentButton {...props} />;
};

export function mapStateToProps(state: IState) {
  const offer = state.offerData.offer;
  const isAvailable = Boolean(offer.dealRentVersion && offer.dealType === 'rent') && !state.entryToShowEnabled;

  return {
    isAvailable,
    isLoading: state.dealRent.isPrefetching,
    buttonTitle: 'Назначить просмотр',
    isDealRentFormEnabled: isRenterFormEnabled(state),
    offerId: offer.id,
  };
}

export const DealRentButtonContainer = connect(mapStateToProps, { open: prefetchFormState })(DealRentButtonWrapper);
