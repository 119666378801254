import { Button } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './BestPlaceReportAside.css';

export interface IBestPlaceReportAsideProps {
  handleBuyReport: VoidFunction;
}

export const BestPlaceReportAside: React.FC<IBestPlaceReportAsideProps> = props => {
  const { handleBuyReport } = props;

  return (
    <div className={styles['container']}>
      <div className={styles['text-wrapper']}>
        <div className={styles['icon-wrapper']}>
          <span className={styles['report-icon']} />
        </div>
        <p className={styles['title']}>Отчёт о привлекательности помещения и локации</p>
      </div>
      <Button theme="fill_secondary" size="XS" onClick={handleBuyReport}>
        Купить
      </Button>
    </div>
  );
};

BestPlaceReportAside.displayName = 'BestPlaceReportAside';
