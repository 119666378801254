import { IconHighway16 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './highwayItem.css';
import { Link } from '../../common/components/link';

interface IHighwayItemProps {
  name: string;
  url: string;
  distance: string | null;
}
export const HighwayItem = ({ name, url, distance }: IHighwayItemProps) => {
  return (
    <li className={styles['highway']}>
      <div className={styles['icon']}>
        <IconHighway16 />
      </div>
      <Link className={styles['highway_link']} href={url} target="_blank">
        {name}
      </Link>
      {distance && <span className={styles['highway_distance']}>{distance}</span>}
    </li>
  );
};
