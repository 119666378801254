import { IconMortgage16 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './MortgageWidget.css';
import { trackMortgageWidgetAnchorClick } from './tracking';
import { calcPayment, formatNumber } from '../../../packages/mortgage-calculator/shared/helpers';
import { scrollToElement } from '../../utils/navigation';

export interface IMortgageMortgageWidgetProps {
  mortgageWidgetMarkup?: string;
  price: number;
  rate: number;
}

const redirectPath =
  '/ipoteka-main?cian_source=cian&cian_medium=trap&cian_campaign=price_block&cian_content=link_under_price&cian_term=card';

export const MortgageWidget = (props: IMortgageMortgageWidgetProps) => {
  const { mortgageWidgetMarkup, price, rate } = props;
  const minPayment = calcPayment(price, price * 0.2, rate, 20 * 12);

  if (!mortgageWidgetMarkup) {
    const handleAnchorClick = (e: React.MouseEvent) => {
      e.preventDefault();

      const underMapElement = document.getElementById('servicePlaceUnderMap');

      if (underMapElement) {
        scrollToElement(underMapElement);
      } else {
        window.open(redirectPath, '_blank');
      }

      trackMortgageWidgetAnchorClick();
    };

    return (
      <div className={styles['container']}>
        <a href="#servicePlaceUnderMap" className={styles['anchor-next']} onClick={handleAnchorClick}>
          <div className={styles['anchor-icon']}>
            <IconMortgage16 color="white_100" />
          </div>
          В ипотеку от {formatNumber(minPayment)} ₽/мес
        </a>
      </div>
    );
  }

  return (
    <div className={styles['container']}>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: mortgageWidgetMarkup }} />
    </div>
  );
};
