import { ContactLabels } from '@cian/contact-card-component';
import { IconFavoriteOn16 } from '@cian/ui-kit';
import * as React from 'react';

interface IAgentReviews {
  title: string;
}

const AgentReviewsComponent: React.FC<React.PropsWithChildren<IAgentReviews>> = ({ title }) => {
  return (
    <ContactLabels
      labels={[
        {
          icon: <IconFavoriteOn16 color="warning_100" />,
          title,
        },
      ]}
    />
  );
};

export const AgentReviews = React.memo(AgentReviewsComponent);
