import { UIText1, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './NoTourSupport.css';

export const NoTourSupport = () => {
  return (
    <div className={style['container']}>
      <div className={style['icon']} />
      <UIText1 fontWeight={'bold'}>Ваш браузер не поддерживает 3D-тур</UIText1>
      <div className={style['text']}>
        <UIText2 color={'black_60'}>
          Установите последнюю версию Google Chrome или воспользуйтесь любым другим браузером
        </UIText2>
      </div>
    </div>
  );
};

NoTourSupport.displayName = 'NoTourSupport';
