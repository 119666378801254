import { IconFunctionalInformationCircle16, Tooltip, UIHeading1, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './priceElectronicTrading.css';

export const PriceElectronicTrading = () => {
  return (
    <div className={styles['actual']}>
      <div className={styles['label']}>
        <UIHeading1>Электронные торги</UIHeading1>
      </div>
      <Tooltip
        theme="white"
        placement="bottom"
        title={
          <div className={styles['description']}>
            <UIText2>Объект реализуется через проведение торгов в соответствии с&nbsp;ГК РФ.</UIText2>
            <a
              className={styles['link']}
              href="https://hc.cian.ru/hc/ru/articles/360037867052"
              target="_blank"
              rel="noopener noreferrer"
            >
              Справочная информация
            </a>
          </div>
        }
      >
        <IconFunctionalInformationCircle16 color="primary_100" />
      </Tooltip>
    </div>
  );
};
