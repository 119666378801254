import * as React from 'react';

export const SchoolIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
    <path
      fill="currentColor"
      d="M5.25 1.917V12L.45 9.861C.15 9.785 0 9.48 0 9.174V.77C0 .54.15.312.3.16c.225-.153.525-.23.75-.077l4.2 1.834zM11.7.16c.15.152.3.382.3.61v8.48c0 .306-.15.535-.45.687L6.75 12V1.917L11.025.083c.225-.076.45-.076.675.077z"
    />
  </svg>
);
