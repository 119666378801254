import { IconBullet16, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export const NewbuildingOnlineStatus = () => {
  return (
    <div className={style['container']} data-testid="NewbuildingOnlineStatus">
      <div className={style['icon']}>
        <IconBullet16 color="current_color" />
      </div>
      <UIText2>Свяжитесь с застройщиком, пока он онлайн</UIText2>
    </div>
  );
};
