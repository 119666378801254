import { IconChevronLeft16, IconChevronRight16 } from '@cian/ui-kit';
import * as React from 'react';

import { Settings } from '../../Slider';

interface IGetSettingsParams {
  totalSlides: number;
  slidesToShow?: number;
}

export function getSettings(params: IGetSettingsParams): Settings {
  const totalSlides = Math.max(params.totalSlides, 0);
  const normalizedSlidesToShow = params.slidesToShow !== undefined ? params.slidesToShow : 5;
  const slidesToShow = Math.max(normalizedSlidesToShow, 0);
  const infinite = totalSlides > slidesToShow;

  return {
    dots: false,
    infinite,
    autoplay: false,
    slidesToShow,
    slidesToScroll: slidesToShow,
    adaptiveHeight: true,
    nextArrow: (
      <button>
        <IconChevronRight16 />
      </button>
    ),
    prevArrow: (
      <button>
        <IconChevronLeft16 />
      </button>
    ),
  };
}
