import { IconUser16 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './Author.css';
//TODO: заменить иконкой из кита
import { RealtorHonest16 } from './RealtorHonest16';

interface IAuthorProps {
  id: string;
  name: string | null;
  avatarUrl: string | null;
  hasFairPlay: boolean;
  accountType: string;
}

export const Author: React.FC<React.PropsWithChildren<IAuthorProps>> = ({
  id,
  name,
  avatarUrl,
  hasFairPlay,
  accountType,
}) => {
  return (
    <div className={styles.root}>
      {avatarUrl && <img src={avatarUrl} className={styles.avatar} />}

      {!avatarUrl && (
        <span className={styles['avatar-none']}>
          <IconUser16 color="gray20_100" />
        </span>
      )}

      <div className={styles.info}>
        <div className={styles.title}>
          <span className={styles.name}>{name || id}</span>

          {hasFairPlay && (
            <span className={styles.icon}>
              <RealtorHonest16 />
            </span>
          )}
        </div>

        <div className={styles['account-type']}>{accountType}</div>
      </div>
    </div>
  );
};
