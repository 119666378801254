import * as React from 'react';

import * as styles from './DealRentNotice.css';

export function DealRentNotice() {
  return (
    <div className={styles['description']}>
      Вы позвоните в&nbsp;Циан. Мы уточним у&nbsp;вас данные и&nbsp;передадим их собственнику напрямую
    </div>
  );
}
