import * as React from 'react';

export const PolyclinicsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
    <path
      fill="currentColor"
      d="M11.25 3.75h-3v-3C8.25.3 7.95 0 7.5 0h-3c-.45 0-.75.3-.75.75v3h-3c-.45 0-.75.3-.75.75v3c0 .45.3.75.75.75h3v3c0 .45.3.75.75.75h3c.45 0 .75-.3.75-.75v-3h3c.45 0 .75-.3.75-.75v-3c0-.45-.3-.75-.75-.75z"
    />
  </svg>
);
