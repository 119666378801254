import { hasWithoutCompetitors } from '../components/similar_offers/utils/hasWithoutCompetitors';
import { ISimilarState } from '../store/similar';

export function itemBasedOffersVisible(state: ISimilarState): boolean {
  return !hasWithoutCompetitors(state) && state.itemBased.list.length > 0;
}

export function isUserBasedOffersVisible(similarOffers: ISimilarState): boolean {
  return !!similarOffers.userBased.list.length;
}

export function someSimilarOffersVisible(state: ISimilarState): boolean {
  const itemBasedVisible = itemBasedOffersVisible(state);
  const userBasedVisible = state.userBased.list.length > 0;

  return itemBasedVisible || userBasedVisible;
}
