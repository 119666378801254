import { IconMetroMsk16, IconMetroSpb16 } from '@cian/ui-kit';
import * as React from 'react';

export interface IIconsMetro {
  type?: string;
}

export const IconsMetro: React.FC<React.PropsWithChildren<IIconsMetro>> = ({ type }) => {
  switch (type) {
    case 'spb':
      return <IconMetroSpb16 color="current_color" />;

    default:
      return <IconMetroMsk16 color="current_color" />;
  }
};

IconsMetro.displayName = 'IconsMetro';
