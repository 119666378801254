import { ca } from '@cian/analytics';

export function trackTooltipOpen() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Card',
    action: 'hover',
    label: 'different_price',
    useLastProducts: true,
  });
}
