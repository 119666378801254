import { ECategory } from '../repositories/infrastructure-caching/entities/infrastructure/CategoryItemsSchema';

interface ITabConfig {
  category: ECategory;
  title: string;
}

export const TABS: ITabConfig[] = [
  {
    category: ECategory.School,
    title: 'Школы',
  },
  {
    category: ECategory.Nursery,
    title: 'Детские сады',
  },
  {
    category: ECategory.Clinic,
    title: 'Поликлиники',
  },
  {
    category: ECategory.Grocery,
    title: 'Прод. магазины',
  },
];
