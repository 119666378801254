import { Button, IconPrint16 } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { trackClick } from './tracking';
import { toggle } from '../../store/popups/print';

export function PrintButtonNewContainer() {
  const dispatch = useDispatch();

  const clickButtonHandle = () => {
    dispatch(toggle(true));
    trackClick();
  };

  return (
    <Button
      theme="stroke_secondary"
      beforeIcon={<IconPrint16 color="gray_icons_100" />}
      onClick={clickButtonHandle}
      size="XS"
      title="Печать"
    />
  );
}
