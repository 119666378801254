import { IconArrowUpRight16, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface IAuthorLinkProps {
  url: string;
  text: string;
  onClick(): void;
}

export function AuthorLink({ url, text, onClick }: IAuthorLinkProps) {
  const [hovered, setHovered] = React.useState(false);

  const changeHovered = React.useCallback(() => setHovered(!hovered), [hovered]);

  return (
    <a
      data-testid="author-link"
      className={styles['link']}
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
      onMouseOver={changeHovered}
      onMouseOut={changeHovered}
    >
      <UIText2 color="primary_100">{text}</UIText2>

      <div className={styles['arrow']}>
        <IconArrowUpRight16 color="primary_100" />
      </div>
    </a>
  );
}
