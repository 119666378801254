import { Label } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './PriceDiscount.css';

interface IPriceDiscountProps {
  oldPrice: string;
  percent: string;
}

export const PriceDiscount: React.FC<IPriceDiscountProps> = ({ oldPrice, percent }) => {
  return (
    <span className={styles['price-discount']}>
      <Label background="#ebf9e6" color="#34ac0a">
        {percent}
      </Label>
      <span className={styles['old-price']}>{oldPrice}</span>
    </span>
  );
};
