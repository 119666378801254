import { useBooleanState } from '@cian/react-utils';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingBrokerBlock } from 'shared/components/NewbuildingBrokerBlock';
import { NewbuildingBrokerFallbackModal } from 'shared/components/NewbuildingBrokerFallbackModal';
import { selectTrackingUser } from 'shared/selectors';
import { getNewbuildingId } from 'shared/selectors/newObject';
import {
  selectBrokerParams,
  selectIsBrokerAvailable,
  selectIsPartnerRewardsRegistrationStatusAvailable,
} from 'shared/selectors/newbuildingBroker';
import { TOpenWidgetResultHandler } from 'shared/types/newbuildingBroker';

import { useNewbuildingBrokerWidget, useBrokerLinks } from './hooks';
import { trackFallbackModalOpen, trackFallbackModalRetryClick, trackSellApartmentClick } from './tracking';
import { NewbuildingBrokerWidgetContainer } from '../NewbuildingBrokerWidgetContainer';

export function NewbuildingBrokerBlockContainer() {
  const { landingPageLink, rewardTariffsLink } = useBrokerLinks();
  const user = useSelector(selectTrackingUser);
  const newbuildingId = useSelector(getNewbuildingId);
  const brokerWidgetParams = useSelector(selectBrokerParams);
  const isRegistrationStatusAvailable = useSelector(selectIsPartnerRewardsRegistrationStatusAvailable);
  const isBrokerAvailable = useSelector(selectIsBrokerAvailable);
  const isBrokerBlockVisible = isRegistrationStatusAvailable || isBrokerAvailable;
  const buttonText = isBrokerAvailable ? 'Провести сделку' : 'Заработать  комиссию';

  const [loading, setLoading] = React.useState(false);
  const { state: isFallbackModalVisible, setTrue: showFallbackModal, setFalse: closeFallbackModal } = useBooleanState();

  const handleWidgetOpeningFinish = React.useCallback<TOpenWidgetResultHandler>(
    ({ isError }) => {
      if (isError) {
        trackFallbackModalOpen(user);
        showFallbackModal();
      }
    },
    [showFallbackModal, user],
  );

  const { openWidget, ...widgetParams } = useNewbuildingBrokerWidget(handleWidgetOpeningFinish);

  const openLandingOrWidget = React.useCallback(async () => {
    setLoading(true);

    if (isRegistrationStatusAvailable) {
      window.open(landingPageLink, '_blank', 'noopener');
    } else {
      if (brokerWidgetParams) {
        const { accountLegalType, hasConfirmedPhone } = brokerWidgetParams;

        await openWidget({
          accountLegalType,
          hasConfirmedPhone,
          newbuildingId,
        });
      }
    }

    setLoading(false);
  }, [brokerWidgetParams, landingPageLink, isRegistrationStatusAvailable, newbuildingId, openWidget]);

  const handleButtonClick = React.useCallback(async () => {
    trackSellApartmentClick(user);
    await openLandingOrWidget();
  }, [openLandingOrWidget, user]);

  const handleRetryClick = React.useCallback(() => {
    trackFallbackModalRetryClick(user);
    closeFallbackModal();
    openLandingOrWidget();
  }, [closeFallbackModal, openLandingOrWidget, user]);

  if (!isBrokerBlockVisible) {
    return null;
  }

  return (
    <>
      <NewbuildingBrokerBlock
        loading={loading}
        onClick={handleButtonClick}
        buttonText={buttonText}
        rewardTariffsLink={rewardTariffsLink}
      />
      <NewbuildingBrokerFallbackModal
        isOpen={isFallbackModalVisible}
        onClose={closeFallbackModal}
        onRetry={handleRetryClick}
      />
      <NewbuildingBrokerWidgetContainer {...widgetParams} />
    </>
  );
}
