import { IconPlaceholderImages24 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './NoImage.css';

interface INoImageProps {
  borderRadius?: number;
}

export function NoImage({ borderRadius = 4 }: INoImageProps) {
  return (
    <div className={styles['image']} style={{ borderRadius: `${borderRadius}px` }}>
      <IconPlaceholderImages24 width={40} height={40} color="gray_icons_100" />
    </div>
  );
}
