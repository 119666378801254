import { createMultiPin, createPin } from './pin_constructor';
import { IPointData } from '../../../store/map_similar/index';

export const IconCurrentOffer = `<svg xmlns="http://www.w3.org/2000/svg" width="62" height="69" viewBox="0 0 62 69">
  <defs>
      <path id="current-offer-svg-a" d="M20.28 45.84C8.86 44.496 0 34.783 0 23 0 10.297 10.297 0 23 0s23 10.297 23 23c0 11.782-8.86 21.495-20.28 22.84L23 54l-2.72-8.16z"/>
      <filter id="current-offer-svg-b" width="169.6%" height="159.3%" x="-34.8%" y="-14.8%" filterUnits="objectBoundingBox">
          <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="4"/>
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
      </filter>
  </defs>
  <g fill="none" fillRule="evenodd" transform="translate(8)">
      <ellipse cx="23" cy="53.5" fill="#000" fill-opacity=".15" rx="5" ry="1.5"/>
      <mask id="current-offer-svg-c" fill="#fff">
          <use xlink:href="#current-offer-svg-a"/>
      </mask>
      <use fill="#000" filter="url(#current-offer-svg-b)" xlink:href="#current-offer-svg-a"/>
      <use fill="#FFF" xlink:href="#current-offer-svg-a"/>
      <path fill="#2B87DB" d="M23 43c11.046 0 20-8.954 20-20S34.046 3 23 3 3 11.954 3 23s8.954 20 20 20z" mask="url(#current-offer-svg-c)"/>
      <path fill="#FFF" d="M28.67 23.2v-4.94h-2.744v2.42L23 18l-8 7.34L17.897 28 23 23.307 28.103 28 31 25.34z" mask="url(#current-offer-svg-c)"/>
  </g>
</svg>`;

export function getSimilarPin(point: IPointData, isViewed: boolean) {
  if (point.stat.length === 1) {
    return createPin(point.stat[0].count, point.stat[0].color, isViewed);
  } else {
    return createMultiPin(point.stat, point.content.offers_count, isViewed);
  }
}
