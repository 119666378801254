import { IconFavoritesOff16 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export interface IAgentReviewsTitleProps {
  title: string;
  link: string | null;
}

export const AgentReviewsTitle: React.FC<React.PropsWithChildren<IAgentReviewsTitleProps>> = ({ title, link }) => {
  return link ? (
    <a className={style['agent-reviews-title']} href={`${link}#reviews`}>
      <IconFavoritesOff16 color="warning_100" />
      {title}
    </a>
  ) : (
    <div className={style['agent-reviews-title']}>
      <IconFavoritesOff16 color="warning_100" />
      {title}
    </div>
  );
};
