import { FormikBag, withFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';

import { ISaveFormRequest } from '../../../repositories/deal-rent-announcements/v1/save-form';
import { getRenterFromState } from '../../../selectors/renterForm/selectors';
import { changePhoneConfirmed, sendValidationCode, updateForm } from '../../../store/renterForm';
import { withAnalytics } from '../../../utils/withAnalytics';
import { FourthStep, IFourthStepProps } from '../../components/FourthStep';

export interface IFourthStepContainerProps {
  onClickBack(): void;
  onClose(): void;
  onSaveForm(values: ISaveFormRequest): void;
}

export interface IFourthStepWithFormikContainerProps extends IFourthStepContainerProps {
  open: boolean;
  initialFormState: ISaveFormRequest;
  phoneConfirmed: boolean;
  secondsToRetry: number;
  isLoading: boolean;
  error: string | null;
  onClick(values: ISaveFormRequest): void;
  sendValidationCode(): void;
  changePhoneConfirmed(value: boolean): void;
}

const FourthStepWithAnalytics = withAnalytics<IFourthStepProps>(
  {
    category: 'SafeRent',
    label: 'renter_form_fourth',
  },
  FourthStep,
);

export const handleSubmit = (
  values: ISaveFormRequest,
  { props: { onClick } }: FormikBag<IFourthStepWithFormikContainerProps, ISaveFormRequest>,
) => {
  onClick(values);
};

export const mapPropsToValues = ({ initialFormState }: IFourthStepWithFormikContainerProps): ISaveFormRequest => ({
  ...initialFormState,
});

export const FourthStepWithFormik = withFormik<IFourthStepWithFormikContainerProps, ISaveFormRequest>({
  mapPropsToValues,
  validationSchema: object({
    smsCode: string().min(4).max(10).required(),
  }),
  handleSubmit,
  enableReinitialize: true,
})(FourthStepWithAnalytics);

export const FourthStepWithFormikContainer: React.FC<
  React.PropsWithChildren<IFourthStepWithFormikContainerProps>
> = props => {
  return <FourthStepWithFormik {...props} />;
};

export const FourthStepContainer = (props: IFourthStepContainerProps) => {
  const { initialFormState, isLoading, isSendSMSLoading, error, phoneConfirmed, secondsToRetry } =
    useSelector(getRenterFromState);

  const dispatch = useDispatch();

  const onClick = React.useCallback(
    (values: ISaveFormRequest) => {
      dispatch(updateForm({ form: values }));
      props.onSaveForm({ ...values });
    },
    [dispatch, props],
  );

  const onClickSend = React.useCallback(() => {
    dispatch(sendValidationCode({ phone: `+7${initialFormState.phone}` }));
  }, [dispatch, initialFormState]);

  React.useEffect(() => {
    if (phoneConfirmed) {
      props.onSaveForm(initialFormState);
    }
  }, [phoneConfirmed]);

  return (
    <FourthStepWithFormikContainer
      {...props}
      phoneConfirmed={phoneConfirmed}
      secondsToRetry={secondsToRetry}
      open={true}
      initialFormState={initialFormState}
      isLoading={isLoading || isSendSMSLoading}
      error={error}
      onClick={onClick}
      changePhoneConfirmed={changePhoneConfirmed}
      sendValidationCode={onClickSend}
    />
  );
};
