import { IPointStat } from '../../../store/map_similar';

const DISPLACE = 5;

export function createPin(count: number, color: string, isViewed: boolean) {
  return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="46" height="53" viewBox="0 0 46 53">
      <defs>
      ${getFillPath(getSize(count), '')}
      <path undefined="" id="b0" d="M16.268 37.805C7.07 36.481 0 28.565 0 19 0 8.507 8.507 0 19 0s19 8.507 19 19c0 9.566-7.07 17.48-16.268 18.805L19 46l-2.732-8.195z"></path>
        <filter id="a0" width="142.1%" height="134.8%" x="-21.1%" y="-8.7%" filterUnits="objectBoundingBox">
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"></feGaussianBlur>
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>
        </filter>
      </defs><g fill="none" fill-rule="evenodd" transform="translate(4)"><ellipse cx="19" cy="45.5" fill="#000" fill-opacity=".15" rx="5" ry="1.5"></ellipse><path fill="#000" filter="url(#a0)" id="b0" d="M16.268 37.805C7.07 36.481 0 28.565 0 19 0 8.507 8.507 0 19 0s19 8.507 19 19c0 9.566-7.07 17.48-16.268 18.805L19 46l-2.732-8.195z"></path><path fill="#FFF" id="b0" d="M16.268 37.805C7.07 36.481 0 28.565 0 19 0 8.507 8.507 0 19 0s19 8.507 19 19c0 9.566-7.07 17.48-16.268 18.805L19 46l-2.732-8.195z"></path></g>
      <path fill="none" stroke="${color}" ${
        isViewed ? 'opacity=".5"' : ''
      } stroke-width="4" d="M 23 19 m -15 0 a 15 15 0 1 1 30 0 a 15 15 0 1 1 -30 0"></path>
      <text x="23" y="24" ${
        isViewed ? 'opacity=".5"' : ''
      } font-family="Lato" font-size="14" font-weight="900" text-anchor="middle">
        ${count}
      </text>
  </svg>`;
}

export function createMultiPin(stats: IPointStat[], count: number, isViewed: boolean) {
  return `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="54" height="61" viewBox="0 0 54 61">
  <defs>
  ${getFillPath(getSize(count), '')}
  <filter id="a1" width="134.8%" height="129.6%" x="-17.4%" y="-7.4%" filterUnits="objectBoundingBox">
      <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
  <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"></feGaussianBlur>
  <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"></feColorMatrix>

  </filter>
  <path id="d" d="M39.774 16.457C37.156 9.751 30.633 5 23 5 15.616 5 9.271 9.446 6.495 15.806"></path>
  <path id="e" d="M18.8 40.507c1.347.322 2.754.493 4.2.493 9.941 0 18-8.059 18-18 0-2.057-.345-4.034-.98-5.875"></path>

  </defs><g fill="none" fill-rule="evenodd" transform="translate(4)"><ellipse cx="23" cy="53.5" fill="#000" fill-opacity=".15" rx="5" ry="1.5"></ellipse>
  <path fill="#000" filter="url(#a1)" id="b1" d="M20.28 45.84C8.86 44.496 0 34.783 0 23 0 10.297 10.297 0 23 0s23 10.297 23 23c0 11.782-8.86 21.495-20.28 22.84L23 54l-2.72-8.16z"></path>
  <path fill="#FFF" id="b1" d="M20.28 45.84C8.86 44.496 0 34.783 0 23 0 10.297 10.297 0 23 0s23 10.297 23 23c0 11.782-8.86 21.495-20.28 22.84L23 54l-2.72-8.16z"></path></g>

  <g ${isViewed ? 'opacity=".5"' : ''} >
    ${calcArcs(stats, count)}
    <text x="27" y="28" font-family="Lato" font-size="14" font-weight="900" text-anchor="middle">
      ${count}
    </text>
  </g>
</svg>`;
}

function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

export function getCircleDescribe(x: number, y: number, r: number) {
  return ['M', x, y, 'm', -r, 0, 'a', r, r, 0, 1, 1, r * 2, 0, 'a', r, r, 0, 1, 1, -(r * 2), 0].join(' ');
}

export function getArcDescribe(x: number, y: number, r: number, startAngle: number, endAngle: number) {
  if (startAngle === 0 && endAngle === 360) {
    return getCircleDescribe(x, y, r);
  }

  const start = polarToCartesian(x, y, r, endAngle);
  const end = polarToCartesian(x, y, r, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  return ['M', start.x, start.y, 'A', r, r, 0, largeArcFlag, 0, end.x, end.y].join(' ');
}

function getSizeGeometryForArc(size: 'small' | 'medium' | 'large'): { x: number; y: number; r: number } {
  switch (size) {
    case 'small':
      return {
        r: 15,
        x: 23,
        y: 19,
      };
    case 'medium':
      return {
        r: 18,
        x: 27,
        y: 23,
      };
    case 'large':
      return {
        r: 24,
        x: 33,
        y: 29,
      };
    default:
      return {
        r: 15,
        x: 23,
        y: 19,
      };
  }
}

function getSize(count: number) {
  if (count < 2) {
    return 'small';
  }
  if (count < 1000) {
    return 'medium';
  }
  if (count < 10000) {
    return 'large';
  }

  return 'large';
}

function calcArcs(stats: IPointStat[], totalItems: number) {
  const geometry = getSizeGeometryForArc('medium');

  return stats.reduce(
    (acc: { prevPos: number; value: string }, item, index) => {
      const arcPosition = getArcPosition(totalItems, index, item.count);

      return {
        prevPos: acc.prevPos + arcPosition,
        value:
          acc.value +
          `<path fill="none" stroke="${item.color}" stroke-width="4" d="${getArcDescribe(
            geometry.x,
            geometry.y,
            geometry.r,
            acc.prevPos,
            acc.prevPos + arcPosition - DISPLACE,
          )}"></path>`,
      };
    },
    { prevPos: 0, value: '' },
  ).value;
}

function getArcPosition(total: number, arcPosition: number, count: number) {
  return (360 * count) / total;
}

export function getFillPath(size: string, attrs?: string) {
  switch (size) {
    case 'small':
      return `<path ${attrs} id="b0" d="M16.268 37.805C7.07 36.481 0 28.565 0 19 0 8.507 8.507 0 19 0s19 8.507 19 19c0 9.566-7.07 17.48-16.268 18.805L19 46l-2.732-8.195z"/>`;

    case 'medium':
      return `<path ${attrs} id="b1" d="M20.28 45.84C8.86 44.496 0 34.783 0 23 0 10.297 10.297 0 23 0s23 10.297 23 23c0 11.782-8.86 21.495-20.28 22.84L23 54l-2.72-8.16z"/>`;

    case 'large':
      return `<path ${attrs} id="b2" d="M26.292 57.875C11.545 56.51 0 44.103 0 29 0 12.984 12.984 0 29 0s29 12.984 29 29c0 15.103-11.545 27.51-26.292 28.875L29 66l-2.708-8.125z"/>`;

    default:
      return '';
  }
}
