import { IconChevronDownSmall16, IconChevronUpSmall16, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './Shutter.css';

export interface IShutterProps {
  maxHeight: number;
  children: React.ReactNode;
  openedText?: string;
  closedText?: string;
}

type GetDefaultTogglesParams = {
  openedText: string | undefined;
  closedText: string | undefined;
};

const getDefaultToggles = ({ openedText = 'Свернуть', closedText = 'Узнать больше' }: GetDefaultTogglesParams) => {
  return {
    open: (
      <>
        <UIText2 color="primary_100">{openedText}&nbsp;&nbsp;</UIText2>
        <IconChevronUpSmall16 color="primary_100" />
      </>
    ),
    close: (
      <>
        <UIText2 color="primary_100">{closedText}&nbsp;&nbsp;</UIText2>
        <IconChevronDownSmall16 color="primary_100" />
      </>
    ),
  };
};

export function Shutter(props: IShutterProps) {
  const ref = React.createRef<HTMLDivElement>();
  const [open, setOpen] = React.useState(false);
  const [height, setHeightState] = React.useState(0);

  const { children, maxHeight, closedText, openedText } = props;
  const isOverHeight = height > maxHeight;

  const setHeight = () => {
    const elem = ref.current;
    /* istanbul ignore next */
    if (!elem) {
      return;
    }

    const { height } = elem.getBoundingClientRect();

    setHeightState(height);
  };

  const handleChange = () => {
    setOpen(!open);
  };

  const getToggleElement = (opened: boolean) => {
    const defaultToggles = getDefaultToggles({ closedText, openedText });

    return opened ? defaultToggles.open : defaultToggles.close;
  };

  React.useEffect(() => {
    setHeight();
    window.addEventListener('resize', setHeight, false);

    return () => {
      window.removeEventListener('resize', setHeight, false);
    };
  }, [maxHeight]);

  return (
    <div data-mark="Shutter" className={style['container']}>
      <div className={style['content']} style={{ maxHeight: open ? `${height}px` : `${maxHeight}px` }}>
        <div className={style['layout']} data-id="content" ref={ref}>
          {children}
        </div>
      </div>
      {isOverHeight ? (
        <span className={style['toggle']} onClick={handleChange} data-id="toggle" data-mark="ShutterToggle">
          {getToggleElement(open)}
        </span>
      ) : null}
    </div>
  );
}
