import { IconCompareAdd16, IconCompareAdded16 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

interface IComparisonControlProps {
  added: boolean;
  disabled: boolean;
  onClick(): void;
}

export function ComparisonControl({ added, disabled, onClick }: IComparisonControlProps) {
  return (
    <div>
      <button onClick={onClick} disabled={disabled} className={cx(styles['button'], disabled && styles['disabled'])}>
        {added ? (
          <IconCompareAdded16 color="primary_100" />
        ) : (
          <IconCompareAdd16 color={disabled ? 'gray60_100' : 'primary_100'} />
        )}
      </button>
    </div>
  );
}
