import * as React from 'react';

import * as styles from './index.css';

export interface IPrintMapProps {
  coordinates: { lng: number; lat: number };
  isPrintModeEnabled: boolean;
}

export function PrintMap(props: IPrintMapProps) {
  if (!props.isPrintModeEnabled) {
    return null;
  }

  const { lat, lng } = props.coordinates;

  return (
    <div className={styles['container']}>
      <img
        className={styles['image']}
        src={`https://static-maps.yandex.ru/1.x/?ll=${lng},${lat}&size=650,450&z=14&l=map&pt=${lng},${lat},round`}
      />
    </div>
  );
}
