import { UIHeading1, UIText1 } from '@cian/ui-kit';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

export type TCardSectionNewProps = {
  id?: string;
  hideForPrint?: boolean;
  withBorders?: boolean;
  withBorder?: boolean;
  withTopBorder?: boolean;
  withBottomBorder?: boolean;
  children: React.ReactNode;
  title?: string | null;
  titleChildren?: React.ReactNode;
  subtitle?: string;
} & {
  [key: `data-${string}`]: string | boolean | number | null | undefined;
};

export const CardSectionNew: React.FC<React.PropsWithChildren<TCardSectionNewProps>> = ({
  id,
  title,
  subtitle,
  withBorder,
  withTopBorder,
  withBottomBorder,
  withBorders,
  hideForPrint,
  titleChildren,
  children,
  ...dataAttributes
}) => {
  return (
    <div {...dataAttributes} className={cx(styles['section'], hideForPrint && styles['hide-for-print'])} id={id}>
      <div
        className={cx(
          styles['item'],
          withBorder && styles['with-border'],
          withTopBorder && styles['with-border'],
          withBottomBorder && styles['with-bottom-border'],
        )}
      >
        <div className={cx(styles['inner'], withBorders && styles['with-borders'])}>
          {title && (
            <div className={styles['title']}>
              <div className={styles['heading']}>
                <UIHeading1 as="h2" color="black_100">
                  {title}
                </UIHeading1>

                {titleChildren && <div>{titleChildren}</div>}
              </div>
              {subtitle && (
                <div className={styles['subtitle']}>
                  <UIText1 color="black_100">{subtitle}</UIText1>
                </div>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
