import * as React from 'react';

import { getPopupText } from './get_popup_text';
import { Text } from '../../../common/components/text';
import { EHideOfferState } from '../../../store/hide_offer';
import { IGetTopPopupParams } from '../../offer_card_page/types';
import { TopPopupContainer } from '../../top_popup/container';

export function HideOfferTopPopup(props: IGetTopPopupParams) {
  const { status } = props;

  return (
    <TopPopupContainer isError={status === EHideOfferState.ERROR}>
      <Text>{getPopupText(props)}</Text>
    </TopPopupContainer>
  );
}
