import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';
import { plural } from '@cian/utils/lib/shared/plural';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OfferStats } from '../../components/OfferMetaData/OfferStats';
import { OfferStatsPopupContainer } from '../../components/offer_stats_popup/container';
import { selectIsArchived, selectStats } from '../../selectors';
import { offerStatsPopupOpen } from '../../store/offer_stats_popup';

export const OfferStatsContainer = () => {
  const dispatch = useDispatch();
  const offerStats = useSelector(selectStats);
  const isOfferArchived = useSelector(selectIsArchived);

  const handleOfferStatsPopupOpen = React.useCallback(() => {
    dispatch(offerStatsPopupOpen());
  }, []);

  if (isOfferArchived || !offerStats) {
    return null;
  }

  return (
    <>
      <OfferStats onClick={handleOfferStatsPopupOpen}>
        {numberToPrettyString(offerStats.total)} {plural(offerStats.total, ['просмотр', 'просмотра', 'просмотров'])},{' '}
        {offerStats.daily} за сегодня
      </OfferStats>
      <OfferStatsPopupContainer />
    </>
  );
};
