import { ca } from '@cian/analytics';

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';
import { getSourceParams } from 'shared/utils/getSourceParams';

import { getCustomUrl } from '../../utils/tracking/getCustomUrl';
import { getProduct } from '../../utils/tracking/getProduct';

interface ITrackModalOpenParams {
  label?: string;
  chatId?: string;
  source?: string;
  type?: 'open_phone' | 'open_message';
}

export function trackModalOpen({ label, chatId, type, source }: ITrackModalOpenParams) {
  const sourceParams = getSourceParams();

  ca('eventSite', {
    name: 'oldevent',
    category: 'Message_popup',
    action: type || 'open',
    label,
    useLastProducts: true,
    page: {
      pageType: 'Card',
      siteType: 'desktop',
      extra: {
        productid: chatId,
        pageTypeSource: sourceParams?.source,
        listingTypeSource: sourceParams?.listingType,
        source,
      },
    },
  });
}

/* istanbul ignore next */
export function trackAgentAvailabilityMessageSent(offer: IOfferSchema, agent: IAgentSchema | null) {
  const sourceParams = getSourceParams();

  ca('event', {
    action: 'send_message_inactive',
    category: 'Message_popup',
    label: getCustomUrl(offer, agent),
    name: 'oldevent',
    products: getProduct(offer, agent),
    page: {
      pageType: 'Card',
      siteType: 'desktop',
      extra: {
        pageTypeSource: sourceParams?.source,
        listingTypeSource: sourceParams?.listingType,
      },
    },
  });
}

interface ITrackSendMessageClickParams {
  offer: IOfferSchema;
  agent: IAgentSchema | null;
  fromWorkHoursPopup?: boolean;
  fromGallery?: boolean;
}

export function trackSendMessageClick({
  offer,
  agent,
  fromWorkHoursPopup = false,
  fromGallery = false,
}: ITrackSendMessageClickParams) {
  const products = getProduct(offer, agent);
  const sourceParams = getSourceParams();

  ca('eventSite', {
    action: 'send_message',
    category: 'card',
    label: getCustomUrl(offer, agent),
    name: 'oldevent',
    products: [{ ...products, variant: products.variant ? products.variant.split('/') : [] }],
    page: {
      pageType: 'Card',
      siteType: 'desktop',
      extra: {
        owner_id: offer.userId,
        is_gallery: fromGallery || undefined,
        reason: fromWorkHoursPopup ? 'WorkHoursPopup' : undefined,
        pageTypeSource: sourceParams?.source,
        listingTypeSource: sourceParams?.listingType,
      },
    },
  });
}
