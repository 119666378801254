import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

export interface ITabProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  active?: boolean;
}

export const Tab = ({ active, children, ...props }: React.PropsWithChildren<ITabProps>) => (
  <button {...props} className={cx(props.className, styles['tab'], active && styles['active'])} type="button">
    {children}
  </button>
);
