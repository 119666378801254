/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';

import { IconColorized, IconStandard, IconPremium, IconTop3 } from './icons';
import * as styles from './index.css';
import { trackClick, TrackingAction, TTrackingAction } from './tracking';

export interface IOfferValueAddedServices {
  isColorized: boolean;
  isStandard: boolean;
  isTop3: boolean;
  isPremium: boolean;
}

export interface IOfferValueAddedServicesProps {
  valueAddedServices: IOfferValueAddedServices | null;
  /** Флаг активности ML-ранжирования */
  isMlSearchEnabled: boolean;
}

interface IOtherValueAddedServicesEnabled {
  icon: string;
  link: string;
  title: string;
}

interface IMapValueAddedServicesKeysToString {
  [key: string]: {
    title: string;
    link: string;
  };
}

const mapValueAddedServicesKeysToString: IMapValueAddedServicesKeysToString = {
  isColorized: {
    link: '/promo/adv/#color',
    title: 'Выделение цветом',
  },
  isStandard: {
    link: '/promo/adv/#standard',
    title: 'Стандарт',
  },
  isPremium: {
    link: '/promo/adv/#premium',
    title: 'Премиум',
  },
  isTop3: {
    link: '/promo/adv/#top3',
    title: 'Топ-3',
  },
};

const mapValueAddedMLSearchServicesKeysToString: IMapValueAddedServicesKeysToString = {
  isColorized: {
    link: '/promo/adv/#color',
    title: 'Выделение цветом',
  },
  isStandard: {
    link: '/promo/adv/#standard',
    title: 'Стандарт',
  },
  isPremium: {
    link: '/promo/adv/#premium',
    title: 'Премиум',
  },
  isTop3: {
    link: '/promo/adv/#top3',
    title: 'Топ',
  },
};

export const getValueAddedServicesIcon = (name: string) => {
  switch (name) {
    case 'isColorized':
      return <IconColorized />;

    case 'isTop3':
      return <IconTop3 />;

    case 'isPremium':
      return <IconPremium />;

    case 'isStandard':
      return <IconStandard />;

    default:
      return '';
  }
};

export class OfferValueAddedServices extends React.Component<IOfferValueAddedServicesProps, {}> {
  public render() {
    if (!this.props.valueAddedServices) {
      return null;
    }

    return (
      <div className={styles['container']}>
        {this.getValueAddedServices().map((valueAddedServicesItem, index) => {
          if (!valueAddedServicesItem) {
            return;
          }

          return (
            <a
              key={`valueAddedServicesItem_${index}`}
              className={styles['vas_item']}
              href={valueAddedServicesItem.link}
              target="_blank"
              onClick={() => this.handleClick(valueAddedServicesItem.icon)}
            >
              {getValueAddedServicesIcon(valueAddedServicesItem.icon)}
              {valueAddedServicesItem.title}
            </a>
          );
        })}
      </div>
    );
  }

  private handleClick = (name: string) => {
    const ACTION_NAME_MAP: { [i: string]: TTrackingAction } = {
      isColorized: TrackingAction.Color,
      isStandard: TrackingAction.Standard,
      isTop3: TrackingAction.Top3,
      isPremium: TrackingAction.Premium,
    };

    const action = ACTION_NAME_MAP[name];

    if (action) {
      trackClick(action);
    }
  };

  private getValueAddedServices = () => {
    if (!this.props.valueAddedServices) {
      return [];
    }

    const { isMlSearchEnabled } = this.props;

    const { isPremium, ...otherValueAddedServices } = this.props.valueAddedServices;
    const mainAddedServicesEnabled = [];

    // Премиум и платное всегда идут первые
    if (isPremium) {
      mainAddedServicesEnabled.push({
        icon: 'isPremium',
        link: '/promo/adv/#premium',
        title: 'Премиум',
      });
    }

    const otherValueAddedServicesEnabled = Object.keys(otherValueAddedServices).reduce(
      (acc, valueAddedServicesKey: keyof typeof otherValueAddedServices) => {
        const mapValueAddedServicesKeys = isMlSearchEnabled
          ? mapValueAddedMLSearchServicesKeysToString
          : mapValueAddedServicesKeysToString;

        if (otherValueAddedServices[valueAddedServicesKey] && mapValueAddedServicesKeys[valueAddedServicesKey]) {
          acc.push({
            icon: valueAddedServicesKey,
            link: mapValueAddedServicesKeys[valueAddedServicesKey].link,
            title: mapValueAddedServicesKeys[valueAddedServicesKey].title,
          });
        }

        return acc;
      },
      [] as IOtherValueAddedServicesEnabled[],
    );

    return mainAddedServicesEnabled.concat(otherValueAddedServicesEnabled);
  };
}
