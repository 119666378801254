import * as React from 'react';
import { useSelector } from 'react-redux';

import { AgentSchedule } from '../../components/AgentSchedule';
import { getAgentAvailability } from '../../selectors';

export function AgentScheduleContainer() {
  const agentAvailability = useSelector(getAgentAvailability);

  if (!agentAvailability || agentAvailability.available) {
    return null;
  }

  const { message, title } = agentAvailability;

  return <AgentSchedule title={title} description={message} />;
}
