import { Button, IconCall16, Tooltip } from '@cian/ui-kit';
import * as React from 'react';

import { DEFAULT_CALLBACK_CONTENT } from 'shared/constants/callback';

import { TCallBackButtonComponent } from '../../types';

export const CallbackButtonCollapsed: TCallBackButtonComponent = ({ size, onClick, loading }) => {
  const { buttonText } = DEFAULT_CALLBACK_CONTENT;

  return (
    <Tooltip title={buttonText}>
      <Button
        onClick={onClick}
        beforeIcon={<IconCall16 color="current_color" />}
        theme="fill_secondary"
        size={size}
        loading={loading}
        data-testid="collapsed-callback-button"
      />
    </Tooltip>
  );
};
