import * as React from 'react';

export function usePageLoaded(callback: () => void, deps: React.DependencyList) {
  const handleLoad = React.useCallback(() => {
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  React.useEffect(() => {
    if (window.document.readyState === 'complete') {
      handleLoad();

      return;
    }

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, [handleLoad]);
}
