import { Label } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsBlackFridayExclusive } from 'shared/selectors/offerData/offer/blackFriday';

export const PriceLabelsContainer = () => {
  const isBlackFridayExclusive = useSelector(selectIsBlackFridayExclusive);

  if (isBlackFridayExclusive) {
    return <Label background="linear-gradient(90deg, black 0%, #0766fc 100%)">Эксклюзив</Label>;
  }

  return null;
};

PriceLabelsContainer.displayName = 'PriceLabelsContainer';
