import cx from 'clsx';
import * as React from 'react';

import * as styles from './styles.css';

interface IInfrastructurePlacemarkCircle {
  children: React.ReactNode;
  className?: string;
}

export const InfrastructurePlacemarkCircle = (props: IInfrastructurePlacemarkCircle) => (
  <div {...props} className={cx(styles['placemark'], props.className)} />
);
