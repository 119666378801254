import { EBestPlaceReportStatus } from 'shared/types/bestPlaceAnalytics';

export const STATUS_BY_TYPE = {
  realtyUserId: EBestPlaceReportStatus.UserTypeError,
  integration: EBestPlaceReportStatus.BillingServerError,
  requestUrl: EBestPlaceReportStatus.RequestUrlError,
  email: EBestPlaceReportStatus.EmailError,
};

export const USER_TYPE_ERROR =
  'Оплата пока доступна только физлицам. Попробуйте сменить или создать аккаунт и оплатить как физлицо';
export const PAYMENT_URL_LOADING = 'Загружаем страницу для оплаты отчёта';
export const REQUEST_URL_ERROR = 'Некорректный URL, попробуйте еще раз';
export const EMAIL_ERROR = 'Некорректный email';
export const UNKNOWN_SERVER_ERROR = 'Произошла ошибка, попробуйте ещё раз';
export const REPORT_PAYMENT_ERROR = 'Произошла ошибка оплаты, попробуйте ещё раз';
export const REPORT_PAYMENT_LOADING = 'Ожидаем подтверждение платежа';
export const WRONG_TRANSACTION_ID = 'Заказа на отчёт с переданным ID транзакции не существует';

export const MESSAGE_BY_STATUS = {
  [EBestPlaceReportStatus.UserTypeError]: USER_TYPE_ERROR,
  [EBestPlaceReportStatus.BillingServerError]: UNKNOWN_SERVER_ERROR,
  [EBestPlaceReportStatus.PaymentUrlLoading]: PAYMENT_URL_LOADING,
  [EBestPlaceReportStatus.RequestUrlError]: REQUEST_URL_ERROR,
  [EBestPlaceReportStatus.EmailError]: EMAIL_ERROR,
  [EBestPlaceReportStatus.PaymentUrlLoaded]: null,
  [EBestPlaceReportStatus.UnknownServerError]: UNKNOWN_SERVER_ERROR,
  [EBestPlaceReportStatus.PaymentLoading]: REPORT_PAYMENT_LOADING,
  [EBestPlaceReportStatus.PaymentCreated]: REPORT_PAYMENT_LOADING,
  [EBestPlaceReportStatus.PaymentCancelled]: REPORT_PAYMENT_ERROR,
  [EBestPlaceReportStatus.ReportPaidError]: REPORT_PAYMENT_ERROR,
  [EBestPlaceReportStatus.ReportPaid]: null,
  [EBestPlaceReportStatus.FeedbackSent]: null,
  [EBestPlaceReportStatus.CheckEmail]: null,
  [EBestPlaceReportStatus.WrongTransactionId]: WRONG_TRANSACTION_ID,
};
