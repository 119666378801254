/* eslint-disable max-lines, @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-explicit-any */
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';

import { IAgentSchema, IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getSimilarPin, IconCurrentOffer } from './icons';
import * as styles from './index.css';
import { InfrastructureMapExtension } from './infrastructure-map-extention';
import { LegendContainer } from './legend/container';
import { RulerControls } from './ruler';
import { trackMapInViewportClick } from './tracking';
import { ZoomControls } from './zoom';
import { Link } from '../../common/components/link';
import * as Analytics from '../../ebc/track_similar';
import { IPointData, ISimilarOffersData } from '../../store/map_similar';
import { MapTab, TMapTab } from '../../store/map_tabs';
import { loadYMapsApi } from '../../utils/ymaps';
import { PrintMapContainer } from '../print_map/container';

const debounce = require('lodash.debounce');

export interface IRuler {
  isEnabled(): boolean;
  enable(): void;
  close(): void;
  disable(): void;
}

export interface IOfferMapProps {
  ymapsApiKey: string;
  suggestApiKey: string;
  activeTab: TMapTab;
  offer: IOfferSchema;
  similarObjects?: ISimilarOffersData;
  viewedIds: string[];
  agent: IAgentSchema | null;
  user: Analytics.IUserEBC;
  page: Analytics.IPageEBC;

  changeTab(tab: TMapTab): void;
  fetchSimilar(bounds: [[number, number], [number, number]]): void;
  markViewed(id: string): void;
  getMapInstance?(map: YMaps.Map): void;
}

export interface IOfferMapState {
  isNoPanoramaStubVisible: boolean;
  isMapLoaded: boolean;
  isRuleActive: boolean;
}

export interface IDiagramSegmentDict {
  [key: string]: number; // color: weight
}

const INITIAL_ZOOM = 15;
const OFFER_PIN_ID = 'currentOfferIcon';

export class OfferMap extends React.Component<IOfferMapProps, IOfferMapState> {
  private mapContainer: HTMLDivElement | null;
  private map: YMaps.Map;
  private mapObjectManager: YMaps.ObjectManager;

  private mapSimilarObjects: Object[][] | Object[] | String;
  private initialCenterAndZoom: YMaps.MapCenterAndZoom;

  private handleSimilarObjectClick: (e: { get(optionName: string): string }) => void;

  public state: IOfferMapState = {
    isMapLoaded: false,
    isNoPanoramaStubVisible: false,
    isRuleActive: false,
  };

  public componentDidMount() {
    const {
      ymapsApiKey,
      offer: {
        geo: { coordinates },
      },
      suggestApiKey,
    } = this.props;

    this.initialCenterAndZoom = {
      center: [coordinates.lng, coordinates.lat],
      zoom: INITIAL_ZOOM,
    };

    loadYMapsApi(ymapsApiKey, suggestApiKey, [
      'Map',
      'GeoObject',
      'ObjectManager',
      'templateLayoutFactory',
      'geometry.pixel.Rectangle',
      'util.bounds',
      'shape.Rectangle',
      'control.RulerControl',
      'objectManager.addon.objectsBalloon',
      'geoObject.addon.hint',
      'Placemark',
    ])
      .then(ymaps => {
        this.map = new ymaps.Map(this.mapContainer!, this.initialCenterAndZoom);
        this.props.getMapInstance?.(this.map);
        this.map.behaviors.disable('scrollZoom');

        const rulerControl = new ymaps.control.RulerControl({
          options: {
            scaleLine: false,
            visible: false,
          },
        });

        this.map.controls.add(rulerControl);

        this.mapObjectManager = new ymaps.ObjectManager();
        this.map.geoObjects.add(this.mapObjectManager);

        this.mapObjectManager.add([createIcon([coordinates.lng, coordinates.lat], OFFER_PIN_ID, IconCurrentOffer, 10)]);

        this.setState({ isMapLoaded: true });

        switch (this.props.activeTab) {
          case MapTab.similarOffers: {
            this.map.events.add('boundschange', this.debouncedUpdateSimilarHandler);
            const bounds = this.map.getBounds();
            this.props.fetchSimilar(bounds);
            if (this.props.similarObjects) {
              this.hideSimilar();
              this.showSimilar(this.props.similarObjects.data.points);
            }
            break;
          }
          case MapTab.panorama:
            this.setPanoramaTab(this.props);
            break;
          case MapTab.default:
            break;
        }
      })
      .catch(e => {
        throw e;
      });
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IOfferMapProps) {
    const { isMapLoaded } = this.state;

    // eslint-disable-next-line no-empty
    if (nextProps.activeTab === MapTab.default && isMapLoaded) {
    } else if (this.props.activeTab === MapTab.default && isMapLoaded) {
      this.setCenterAndZoom(this.initialCenterAndZoom);
    }

    if (nextProps.activeTab !== this.props.activeTab) {
      if (nextProps.activeTab === MapTab.panorama && isMapLoaded) {
        this.setPanoramaTab(nextProps);
      } else if (this.props.activeTab === MapTab.panorama && isMapLoaded) {
        this.setState({
          isNoPanoramaStubVisible: false,
        });

        this.map
          .getPanoramaManager()
          .then(panoramaManager => {
            panoramaManager.closePlayer();
          })
          .catch(e => {
            throw e;
          });

        this.setCenterAndZoom(this.initialCenterAndZoom);
      }
    }

    if (nextProps.activeTab !== MapTab.similarOffers && isMapLoaded) {
      this.map.events.remove('boundschange', this.debouncedUpdateSimilarHandler);
    }

    if (nextProps.activeTab === MapTab.similarOffers && isMapLoaded) {
      if (this.props.activeTab !== MapTab.similarOffers) {
        this.map.events.add('boundschange', this.debouncedUpdateSimilarHandler);
        const bounds = this.map.getBounds();
        this.props.fetchSimilar(bounds);
      }
      if (nextProps.similarObjects) {
        this.hideSimilar();
        this.showSimilar(nextProps.similarObjects.data.points);
      }
    } else if (this.props.activeTab === MapTab.similarOffers && isMapLoaded) {
      this.setCenterAndZoom(this.initialCenterAndZoom);

      this.hideSimilar();
    }
  }

  public render() {
    const { activeTab } = this.props;
    const { isMapLoaded, isNoPanoramaStubVisible } = this.state;

    return (
      <ActionAfterViewObserver callback={trackMapInViewportClick} triggerOnce>
        <div id="map">
          <PrintMapContainer />
          <div className={styles['map_container']}>
            {isNoPanoramaStubVisible && (
              <div className={styles['stub']}>
                <div className={styles['stub-content']}>
                  <strong className={styles['stub-title']}>Панорамы по этому адресу нет</strong>
                  <Link href="#" onClick={this.handleClickOnStubLink}>
                    Перейти на карту
                  </Link>
                </div>
              </div>
            )}
            <div ref={ref => (this.mapContainer = ref)} className={styles['map']} />
            {isMapLoaded && (
              <>
                {activeTab !== MapTab.panorama && (
                  <>
                    <ZoomControls onZoomIn={this.onZoomIn} onZoomOut={this.onZoomOut} />
                    <RulerControls onRulerToggle={this.toggleRuler} isSelected={this.state.isRuleActive} />
                  </>
                )}
                {activeTab === MapTab.default && (
                  <InfrastructureMapExtension
                    map={this.map}
                    objectManager={this.mapObjectManager}
                    offer={this.props.offer}
                  />
                )}
                {activeTab === MapTab.similarOffers && <LegendContainer />}
              </>
            )}
          </div>
        </div>
      </ActionAfterViewObserver>
    );
  }

  private handleClickOnStubLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.changeTab(MapTab.default);
  };

  private setPanoramaTab = (props: IOfferMapProps) => {
    const {
      offer: {
        geo: { coordinates },
      },
    } = props;

    this.map
      .getPanoramaManager()
      .then(panoramaManager => {
        panoramaManager
          .openPlayer([coordinates.lng, coordinates.lat])
          .then(() => {
            const player = panoramaManager.getPlayer();

            if (player) {
              player.events.add('destroy', () => {
                if (this.props.activeTab === MapTab.panorama) {
                  this.props.changeTab(MapTab.default);
                }
              });
            }
          })
          .catch(() => {
            if (this.props.activeTab === MapTab.panorama) {
              this.setState({
                isNoPanoramaStubVisible: true,
              });
            }
          });
      })
      .catch(e => {
        throw e;
      });
  };

  private showSimilar(points: { [index: string]: IPointData }) {
    const similarPointsIcons = [];

    for (const point in points) {
      // eslint-disable-next-line no-prototype-builtins
      if (points.hasOwnProperty(point)) {
        if (similarPointsIcons.length < 100) {
          similarPointsIcons.push(
            createIcon(
              point.split(' ').map(Number).reverse(),
              point,
              getSimilarPin(points[point], this.props.viewedIds.includes(point)),
              1,
            ),
          );
        }
      }
    }

    this.mapObjectManager.add(similarPointsIcons);
    this.mapSimilarObjects = similarPointsIcons;

    this.handleSimilarObjectClick = (e: { get(optionName: string): string }) => {
      const id: string = e.get('objectId');
      const { markViewed, offer, agent, user, page } = this.props;

      if (id in points) {
        const item = points[id];

        this.mapObjectManager.objects.setObjectOptions(id, {
          iconLayout: window.ymaps.templateLayoutFactory.createClass(getSimilarPin(points[id], true)),
        });

        if (item.content.offers_count > 1) {
          window.open(item.content.link);
        } else {
          window.open(extractHref(item.offers[0].link_text));
        }

        markViewed(id);

        const curOffder = points[id].offers[0];

        const products: Analytics.TProductPartial[] = [
          {
            id: Number(curOffder.id),
            ownerId: curOffder.published_user_id,
            variant: curOffder.services,
          },
        ];

        Analytics.trackOfferClick({
          action: Analytics.EtrackOfferClicksActions.click_similar_on_map,
          products,
          agent,
          offer,
          modelVersion: '0',
          user,
          page,
        });
      }
    };

    this.mapObjectManager.events.add('click', this.handleSimilarObjectClick);
  }

  private hideSimilar() {
    if (this.mapSimilarObjects) {
      this.mapObjectManager.remove(this.mapSimilarObjects as Object[]);
    }

    if (this.handleSimilarObjectClick) {
      this.mapObjectManager.events.remove('click', this.handleSimilarObjectClick);
    }
  }

  private onZoomIn = () => {
    this.map.setZoom(this.map.getZoom() + 1);
  };

  private onZoomOut = () => {
    this.map.setZoom(this.map.getZoom() - 1);
  };

  private updateSimilarHandler = () => {
    if (this.props.activeTab === MapTab.similarOffers) {
      const bounds = this.map.getBounds();
      this.props.fetchSimilar(bounds);
    }
  };

  private debouncedUpdateSimilarHandler = debounce(this.updateSimilarHandler, 500);

  private toggleRuler = () => {
    const ruler: IRuler = this.map.behaviors.get<IRuler>('ruler');
    const enabled = ruler.isEnabled();

    if (this.map && !enabled) {
      ruler.enable();
      this.setState({ isRuleActive: true });
    } else {
      ruler.close();
      ruler.disable();
      this.setState({ isRuleActive: false });
    }
  };

  private setCenterAndZoom = (сenterAndZoom: YMaps.MapCenterAndZoom) => {
    if (this.map) {
      this.map.setCenter(сenterAndZoom.center, сenterAndZoom.zoom);
    }
  };
}

function createIcon(offerPosition: [number, number] | number[], id: number | string, icon: string, zIndex?: number) {
  return {
    geometry: {
      coordinates: offerPosition,
      type: 'Point',
    },
    id,
    options: {
      iconLayout: window.ymaps.templateLayoutFactory.createClass(icon),
      iconOffset: [-27, -61],
      iconShape: {
        coordinates: [27, 27],
        radius: 26.5,
        type: 'Circle',
      },
      iconSize: [54, 61],
      zIndex,
    },
    type: 'Feature',
  };
}

function extractHref(href: string[]) {
  const link = href[href.length - 1].match(/href="([^"]*)/);
  if (link) {
    return link[1];
  }

  return '';
}
