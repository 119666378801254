import * as React from 'react';

export const PriorityIcon = (props: React.HTMLAttributes<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" {...props}>
    {/* tslint:disable-next-line:max-line-length */}
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M5.035 0C2.23 0 0 2.321 0 5.242c0 1.423.504 2.77 1.51 3.744.072.075 2.95 2.77 3.022 2.846.287.224.719.224.935 0 .072-.075 3.02-2.771 3.02-2.846 1.008-.974 1.511-2.321 1.511-3.744C10.07 2.322 7.84 0 5.035 0z"
    />
  </svg>
);
