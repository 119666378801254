import { IconSuccess16, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

interface IPriceRelevanceProps {
  label: string;
}

export const PriceRelevance = ({ label }: IPriceRelevanceProps) => {
  return (
    <div className={style['container']}>
      <div className={style['icon']}>
        <IconSuccess16 color="current_color" />
      </div>
      <UIText2 color="gray60_100">{label}</UIText2>
    </div>
  );
};
