import {
  IconHighway16,
  IconMetroEkb16,
  IconMetroKzn16,
  IconMetroMsk16,
  IconMetroNN16,
  IconMetroNovosib16,
  IconMetroSam16,
  IconMetroSpb16,
  IconTrain16,
} from '@cian/ui-kit/icons';
import { IIconProps } from '@cian/ui-kit/icons/internal';
import React, { CSSProperties, FC } from 'react';

import { TTransportTypes } from 'shared/types/TransportTypes';

import * as styles from './TransportIcon.css';

type TTransportIconProps = {
  iconType: TTransportTypes;
  iconColor: string | null;
};

const transportIconMap: Record<TTransportTypes, FC<IIconProps>> = {
  ekb: IconMetroEkb16,
  highway: IconHighway16,
  kzn: IconMetroKzn16,
  mow: IconMetroMsk16,
  nn: IconMetroNN16,
  nsk: IconMetroNovosib16,
  railway: IconTrain16,
  smr: IconMetroSam16,
  spb: IconMetroSpb16,
};

export const TransportIcon: FC<TTransportIconProps> = props => {
  const { iconType, iconColor } = props;

  const TransportIcon = transportIconMap[iconType];

  const style: CSSProperties = {};

  if (iconColor) {
    style.color = iconColor;
  }

  return (
    <div data-testid="TransportIcon" className={styles['container']} style={style}>
      <TransportIcon color="current_color" data-testid={`icon-${iconType}`} />
    </div>
  );
};
