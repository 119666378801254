import { TContactType } from '@cian/contact-card-component';
import { Avatar, Overline, UIHeading3 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export interface IAgentInfoLayoutProops {
  avatarType: TContactType;
  avatar: string;
  title: string;
  agentName: string;
  agentLink: string | null;
  agentReviews: React.ReactElement | null;
  agentProofs: React.ReactElement | null;
  superAgentElement: React.ReactElement | null;
}

export const AgentInfoLayout: React.FC<React.PropsWithChildren<IAgentInfoLayoutProops>> = ({
  avatar,
  avatarType,
  title,
  agentLink,
  agentName,
  agentReviews,
  agentProofs,
  superAgentElement,
}) => {
  return (
    <div className={style['wrapper']}>
      {agentLink ? (
        <a href={agentLink} target="_blank" rel="noreferrer">
          <Avatar size={72} src={avatar} type={avatarType} />
        </a>
      ) : (
        <Avatar size={72} src={avatar} type={avatarType} />
      )}

      <div className={style['title']}>
        <Overline color="gray60_100">{title}</Overline>

        {agentLink ? (
          <a className={style['agent-name']} href={agentLink} target="_blank" rel="noreferrer">
            <UIHeading3 color="black_100">{agentName}</UIHeading3>
          </a>
        ) : (
          <div className={style['agent-name']}>
            <UIHeading3 color="black_100">{agentName}</UIHeading3>
          </div>
        )}
        {superAgentElement && <div className={style['super-agent']}>{superAgentElement}</div>}
        <div className={style['proofs']}>
          {agentReviews && agentReviews}
          {agentProofs && agentProofs}
        </div>
      </div>
    </div>
  );
};
