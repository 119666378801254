import { IconHeartOff24, IconHeartOn24 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './FavoriteButtonAside.css';

export interface IFavoriteButtonAside {
  checked: boolean;
  disabled?: boolean;
  onClick(): void;
}

export const FavoriteButtonAside = ({ checked, onClick, disabled }: IFavoriteButtonAside) => {
  if (disabled) {
    return (
      <button className={styles['btn-disabled']}>
        <IconHeartOn24 />
      </button>
    );
  }

  return (
    <button className={styles['btn']} onClick={onClick}>
      {checked ? <IconHeartOn24 color="error_100" /> : <IconHeartOff24 color="error_100" />}
    </button>
  );
};
