import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackTooltipLoginClick, trackTooltipRegisterClick } from './tracking';
import { LoginMotivationTooltip } from '../../components/LoginMotivationTooltip';
import { FavoritesButtonContext } from '../../context';
import { selectFavoriteMotivationShows } from '../../selectors/favoriteMotivation';
import { hideFavoriteMotivation } from '../../store/favoriteMotivation';

export const LoginMotivationTooltipContainer = () => {
  const { placement } = React.useContext(FavoritesButtonContext);
  const dispatch = useDispatch();
  const isMotivationTooltipShows = useSelector(selectFavoriteMotivationShows);

  const onLoginButtonClick = React.useCallback(() => {
    trackTooltipLoginClick();

    dispatch(hideFavoriteMotivation());

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (window.waitForLoginPopup) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.waitForLoginPopup();
    }
  }, []);

  const onRegisterButtonClick = React.useCallback(() => {
    trackTooltipRegisterClick();

    dispatch(hideFavoriteMotivation());

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (window.waitForRegistrationPopup) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.waitForRegistrationPopup();
    }
  }, []);

  const onHideButtonClick = React.useCallback(() => {
    dispatch(hideFavoriteMotivation());
  }, []);

  return (
    <LoginMotivationTooltip
      isFullscreenGallery={placement === 'gallery'}
      isMotivationTooltipShows={isMotivationTooltipShows}
      onLoginButtonClick={onLoginButtonClick}
      onRegisterButtonClick={onRegisterButtonClick}
      onHideMotivationTooltipClick={onHideButtonClick}
    />
  );
};
