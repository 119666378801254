import { Button } from '@cian/ui-kit';
import * as React from 'react';

import { DEFAULT_CALLBACK_CONTENT } from 'shared/constants/callback';

import { TCallBackButtonComponent } from '../../types';

export const CallbackButton: TCallBackButtonComponent = ({ size, onClick, placeType, loading }) => {
  const { buttonText } = DEFAULT_CALLBACK_CONTENT;

  return (
    <Button
      data-testid={`callback-button-${placeType}`}
      onClick={onClick}
      theme="fill_secondary"
      size={size}
      fullWidth
      loading={loading}
    >
      {buttonText}
    </Button>
  );
};
