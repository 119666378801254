import * as React from 'react';

export const GroceryStoreIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
    <path
      fill="currentColor"
      d="M11.278 2.25c.602 0 .827.6.677.975l-1.504 4.5a.722.722 0 0 1-.677.525H3.76c-.376 0-.676-.225-.751-.6L1.654 1.5H0V0h2.256c.376 0 .676.225.752.6l.375 1.65h7.895zM3.76 12c-.83 0-1.503-.672-1.503-1.5S2.929 9 3.759 9c.83 0 1.504.672 1.504 1.5S4.59 12 3.76 12zm6.015 0c-.83 0-1.503-.672-1.503-1.5S8.944 9 9.774 9c.83 0 1.504.672 1.504 1.5S10.605 12 9.774 12z"
    />
  </svg>
);
