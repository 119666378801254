import { Overline, UIHeading3 } from '@cian/ui-kit';
import * as React from 'react';

import { NewbuildingPremiumBuilderLabel } from 'shared/components/AuthorAsideNew';

import * as style from './style.css';

export interface ICompactBuilderLogoProps {
  logoUrl?: string | null;
  builderName: string;
  isReliable: boolean;
  url: string;
  onClick(): void;
  rating?: React.ReactElement | null;
}

export const CompactBuilderLogo: React.FC<ICompactBuilderLogoProps> = ({
  logoUrl,
  builderName,
  url,
  isReliable,
  onClick,
  rating,
}) => {
  return (
    <div className={style['logo']}>
      <a data-testid="builder-logo-link" href={url} target="_blank" rel="noreferrer" onClick={onClick}>
        <div
          className={style['img']}
          style={logoUrl ? { backgroundImage: `url(${logoUrl})` } : { backgroundColor: `#f1f2f4` }}
        />
      </a>

      <div className={style['title']}>
        <Overline>Застройщик</Overline>
        <a className={style['builder']} href={url} target="_blank" rel="noreferrer" onClick={onClick}>
          <UIHeading3 color="black_100" as="h3">
            {builderName}
          </UIHeading3>
        </a>
        {isReliable && (
          <div className={style['label']}>
            <NewbuildingPremiumBuilderLabel />
          </div>
        )}
        {rating && <div className={style['rating']}>{rating}</div>}
      </div>
    </div>
  );
};
