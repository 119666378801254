import * as React from 'react';

import * as styles from './index.css';

export interface IZoomControlsProps {
  onZoomIn(): void;
  onZoomOut(): void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class ZoomControls extends React.Component<IZoomControlsProps, {}> {
  public render() {
    return (
      <div className={styles['container']}>
        <div className={styles['in']} onClick={() => this.props.onZoomIn()}>
          <div className={styles['in-icon']} />
        </div>
        <div className={styles['out']} onClick={() => this.props.onZoomOut()}>
          <div className={styles['out-icon']} />
        </div>
      </div>
    );
  }
}
