import * as React from 'react';
import { useSelector } from 'react-redux';

import { onlineRegistrationAvailableSelector } from './selectors';
import { trackModalOpen } from './tracking';
import { FloatingLabel } from '../../components/FloatingLabel';
import { useApplicationContext } from '../ApplicationContext';

export function DealSaleLabelNewContainer() {
  const { config } = useApplicationContext();
  const isDealSaleAvailable = useSelector(onlineRegistrationAvailableSelector);
  const dealSalePromoLink = config.get<string>('deal-sale-promo-frontend.baseUrl');

  const handleTooltipOpen = React.useCallback(() => trackModalOpen(), []);

  if (!isDealSaleAvailable) {
    return null;
  }

  return (
    <FloatingLabel
      labelText="Онлайн-сделка"
      tooltipTitle="Онлайн-сделка"
      tooltipText="Этот объект подходит для проведения электронной регистрации с помощью сервиса Циан.Сделка"
      color="dark_100"
      backgroundColor="gray10_100"
      link={`${dealSalePromoLink}?cian_source=deal_label`}
      onOpen={handleTooltipOpen}
    />
  );
}
