import { selectIsBrokerAvailable } from './selectIsBrokerAvailable';
import { IState } from '../../store';

export const selectBrokerParams = (state: IState) => {
  if (selectIsBrokerAvailable(state)) {
    const brokerParams = state.offerData.newbuildingBroker;

    if (brokerParams?.accountLegalType) {
      return {
        accountLegalType: brokerParams.accountLegalType,
        hasConfirmedPhone: brokerParams.hasConfirmedPhone,
      };
    }
  }

  return null;
};
