import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export interface IOfferFactItemProps {
  title: string;
  value: string;
}

export const OfferFactItem: React.FC<React.PropsWithChildren<IOfferFactItemProps>> = ({ title, value }) => {
  return (
    <div className={style['item']}>
      <UIText2 whiteSpace="nowrap" color="black_100">
        {title}
      </UIText2>
      <div className={style['divider']} />
      <UIText2 whiteSpace="nowrap" color="black_100">
        {value}
      </UIText2>
    </div>
  );
};
