import * as React from 'react';

import { STAR_FILL_MAP } from '../constants/star';
import { EStarTheme } from '../types/star';

export interface IProps {
  value?: number;
  theme: EStarTheme;
  color?: string;
}

export const useGradient = ({ value = 0, theme, color }: IProps) => {
  const gradientId = `gradient-newbuilding_reviews_rating-${value}`;

  const gradient = React.useMemo(() => {
    const offset = `${value * 100}%`;

    return (
      <defs>
        <linearGradient id={gradientId} y2="0%" x2="100%" y1="0%" x1="0%">
          <stop offset="0%" stopOpacity="1" stopColor="#ff7e00" />
          <stop offset={offset} stopOpacity="1" stopColor="#ff7e00" />
          <stop offset={offset} stopOpacity="0" stopColor="#ff7e00" />
          <stop offset={offset} stopOpacity="1" stopColor="#ced1d7" />
          <stop offset="100%" stopOpacity="1" stopColor="#ced1d7" />
        </linearGradient>
      </defs>
    );
  }, [gradientId, value]);

  const fill = React.useMemo(
    () => (theme === EStarTheme.Fillable ? `url(#${gradientId})` : color || STAR_FILL_MAP[theme]),
    [gradientId, theme, color],
  );

  return {
    gradient,
    fill,
  };
};
