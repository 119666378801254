import { UIHeading5, UIText3 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export interface IDeveloperStats {
  testId: string;
  title: string;
  value: string;
  link?: string;
}

export interface IDeveloperLayoutProps {
  logo: React.ReactElement;
  stats: IDeveloperStats[];
}

export const DeveloperLayout: React.FC<React.PropsWithChildren<IDeveloperLayoutProps>> = ({ logo, stats }) => {
  return (
    <div className={style['container']}>
      <div>{logo}</div>

      <div className={style['wrapper']}>
        {stats.map(({ testId, title, value, link }) => {
          return link ? (
            <a key={value} data-testid={testId} className={style['title']} href={link} target="blank" rel="noreferrer">
              <UIText3 color="gray60_100">{title}</UIText3>
              <UIHeading5 color="black_100">{value}</UIHeading5>
            </a>
          ) : (
            <div key={value} data-testid={testId} className={style['title']}>
              <UIText3 color="gray60_100">{title}</UIText3>
              <UIHeading5 color="black_100">{value}</UIHeading5>
            </div>
          );
        })}
      </div>
    </div>
  );
};
