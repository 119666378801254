import { Button, IconHide16, IconShow16 } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getHiddenOfferStatus, getIsHiddenOfferByUser } from '../../selectors';
import { EHideOfferState, hideOffer, restoreOffer } from '../../store/hide_offer';
import {
  EHideOfferAnalyticsCategory,
  ERestoreEventSource,
  selectProductsParams,
  trackHiddenObjects,
} from '../../tracking/hide_offer';

export function HideButtonNewContainer() {
  const dispatch = useDispatch();
  const isHidden = useSelector(getIsHiddenOfferByUser);
  const status = useSelector(getHiddenOfferStatus);
  const productParams = useSelector(selectProductsParams);
  const isFetching = status === EHideOfferState.FETCHING;

  const handleClick = React.useCallback(() => {
    if (isHidden) {
      dispatch(restoreOffer(ERestoreEventSource.ICON));
    } else {
      trackHiddenObjects({
        action: 'Click_icon',
        category: EHideOfferAnalyticsCategory.HIDE,
        productParams,
      });
      dispatch(hideOffer());
    }
  }, [productParams, isHidden, dispatch]);

  return (
    <Button
      theme="stroke_secondary"
      beforeIcon={isHidden ? <IconShow16 color="gray_icons_100" /> : <IconHide16 color="gray_icons_100" />}
      onClick={handleClick}
      size="XS"
      loading={isFetching}
      disabled={isFetching}
      title={isHidden ? 'Показать объявление' : 'Скрыть объявление'}
    />
  );
}
