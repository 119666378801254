import { ca } from '@cian/analytics';

import { IBrokerAnalyticsUser } from 'shared/types/newbuildingBroker';

export const trackSellApartmentClick = (user: IBrokerAnalyticsUser) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'BrokerDeal',
    action: 'Click',
    label: 'Button',
    page: {
      pageType: 'Card',
      siteType: 'desktop',
    },
    user,
    useLastProducts: true,
  });
};
