import { createSelector } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { InfrastructureMapExtension as InfrastructureMapExtensionComponent } from './infrastructure-map-extention';
import { IInfrastructureItem } from './types';
import { ECategory } from '../../../repositories/infrastructure-caching/entities/infrastructure/CategoryItemsSchema';
import { IGetInfrastructureForMapRequest } from '../../../repositories/infrastructure-caching/v1/get-infrastructure-for-map';
import { setInfrastructureCategories } from '../../../store/infrastructure-categories';
import { fetchInfrastructureMap } from '../../../store/infrastructure-map';

import type { DispatchReduxAction, IState } from '../../../store';

const getMap = (state: IState) => state.infrastructureMap;

const getCategories = (state: IState) => state.infrastructureCategories;

export const mapStateToProps = createSelector([getMap, getCategories], ({ data }, selectedCategories) => {
  return {
    bbox: data && data.bbox,
    items: ((data && data.infrastructure) || []).reduce((result, item): IInfrastructureItem[] => {
      const { category } = item;

      if (!category || !selectedCategories.includes(category)) {
        return result;
      }

      return [
        ...result,
        ...(item.items || []).map(company => ({
          ...company,
          category,
        })),
      ];
    }, [] as IInfrastructureItem[]),
    categories: selectedCategories,
  };
});

export function mapDispatchToProps(dispatch: DispatchReduxAction) {
  return {
    fetchInfrastructureMap: (params: IGetInfrastructureForMapRequest) => {
      dispatch(fetchInfrastructureMap(params));
    },
    setCategories: (categories: ECategory[]) => {
      dispatch(setInfrastructureCategories(categories));
    },
  };
}

export const InfrastructureMapExtension = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InfrastructureMapExtensionComponent);
